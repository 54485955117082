import React from 'react'
import { Grid } from 'semantic-ui-react'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import TextInput from '../../UI/Form/TextInput'
import styles from '../../../assets/styles/modules/orders/OrderDetails.module.scss'
import cx from 'classnames'

const ShoppingListData = ({ setShoppingListError, updateShoppingListName, updateShoppingListEmail }) => {

  const { t } = useTranslation(['orders', 'validationMessages'])

  const initialValues = {
    name: '',
    email: ''
  }

  const hasErrors = (errors) => {
    return Object.keys(errors).length > 0
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validate={(values) => {
        const errors = {}
        if (values.name === '') {
          errors.name = t('name', { ns: 'validationMessages' })
        } else {
          updateShoppingListName(values.name)
        }
        if (values.email === '') {
          errors.email = t('email', { ns: 'validationMessages' })
        } else {
          updateShoppingListEmail(values.email)
        }
        setShoppingListError(hasErrors(errors))
        return errors
      }}
    >
      {({ values, setFieldValue, setFieldError }) => (
        <Form
          name="order-summary-form"
          className="ui form"
        >
          <>
            <div className={styles.OrderDetails}>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={16} className={cx('grid-custom-column', styles.OrderSign)}>
                    <p className={styles.OrderSignTitle}>{t('name', { ns: 'orders'})}</p>
                    <TextInput name="name" placeholder={t('shopping_list_name', { ns: 'orders'})} type="text" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16} className={cx('grid-custom-column', styles.OrderSign)}>
                    <p className={styles.OrderSignTitle}>{t('email', { ns: 'orders'})}</p>
                    <TextInput name="email" placeholder={t('shopping_list_email', { ns: 'orders'})} type="text" />
                  </Grid.Column>
                </Grid.Row>
            </Grid>
            </div>
          </>
        </Form>
      )}
    </Formik>
  )
}

export default ShoppingListData