import * as actionTypes from '../actions/actionTypes'
import { getLocationArray, updateObject } from '../../shared/utility'

const initialState = {
  locations: [],
  location: null,
  selectedLocation: null,
  locationArray: [], //For dropdowns
  locationIds: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_LOCATIONS_SUCCESS:
      const locArr = getLocationArray(action.locations)
      return updateObject(state, { locations: action.locations, locationArray: locArr })

    case actionTypes.GET_LOCATION_SUCCESS:
      return updateObject(state, { location: action.location })

    case actionTypes.GET_LOCATION_IDS:
      return updateObject(state, { locationIds: action.locationIds })

    case actionTypes.SET_SELECTED_LOCATION:
      return updateObject(state, { selectedLocation: action.selectedLocation })

    case actionTypes.SET_LOCATION_ARRAY:
      return updateObject(state, { locationArray: action.locationArray })
    default:
      return state
  }
}

export default reducer
