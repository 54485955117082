import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

const initialState = {
  stock: [],
  lastLocationId: null
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_STOCK_SUCCESS:
      if (action.lastLocationId) {
        return updateObject(state, { stock: action.stock, lastLocationId: action.lastLocationId })
      } else {
        return updateObject(state, { stock: action.stock })
      }
    case actionTypes.FETCH_STOCK:
      return updateObject(state, {
        stock: action.stock,
      })
    default:
      return state
  }
}

export default reducer
