import React from 'react'
import { useHistory } from 'react-router-dom'
import { Grid, Segment, Icon } from 'semantic-ui-react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { getStatusIcon } from '../../../shared/utility'
import styles from '../../../assets/styles/modules/orders/Order.module.scss'
import placeholderSrc from '../../../assets/images/distributors/placeholder.svg'
import placeholderSLSrc from '../../../assets/images/shopping-list/placeholder.svg'

const Order = ({ order, cursor = 0 }) => {
  const history = useHistory()
  const { t } = useTranslation(['orders'])

  return (
    <Segment
      raised
      id={order.id}
      className={styles.Order}
      onClick={() => {
        localStorage.setItem('cursor', cursor)
        localStorage.setItem('target', order.id)
        if (order.isShoppingList) {
          history.push('/shopping-list/view/' + order.id, { cursor, target: order.id })
        } else {
          // this is unreacheable within the new app
          history.push('/orders/view/' + order.id, { cursor, target: order.id })
        }
      }}>
      <Grid>
        <Grid.Row className={styles.CustomDesktopRow} style={{ padding: '0 10px 0 0' }}>
          <Grid.Column stretched width={4} className={styles.Logo} verticalAlign="middle">
          <img src={order.photoUrl ? order.photoUrl : order.isShoppingList ? placeholderSLSrc : placeholderSrc} alt={order.distributorName} />
          </Grid.Column>
          <Grid.Column width={7} verticalAlign="middle" className={styles.OrderDetails}>
            <p className="large">{order.name}</p>
            { order.isShoppingList ?
              <p className="small hint">{order.email}</p>
            :
              <p className="small hint">{t('order_number', { number: order.orderId, ns: 'orders' })}</p>
            }
            { order.deliveryDate ? (
              <p className="small">
                <span>{t('requested_delivery', { ns: 'orders' })}: </span>
                <span className="green-text">{moment(order.deliveryDate).format('DD MMM YYYY')}</span>
              </p>
            ) : (
              <p className="small">
                { order.isShoppingList ?
                  <>
                    <span>{t('shopping_list_sent', { ns: 'orders' })}: </span>
                    <span className="green-text">{moment(order.createdAt).format('DD MMM YYYY')}</span>
                  </>  
                :
                  <>
                    <span>{t('estimated_delivery', { ns: 'orders' })} </span>
                    <span className="green-text">{moment(order.createdAt).add(1, 'days').format('DD MMM YYYY')}</span>
                  </>
                }
              </p>
            )}
          </Grid.Column>
          { !order.isShoppingList &&
            <Grid.Column width={3} style={{ padding: '0px' }} verticalAlign="middle" textAlign="right">
              <p className="orange-text">{moment(order.createdAt).format('DD MMM')}</p>
              <p className="green-text bold">{t(`order_${order.status}`, { ns: 'orders' })}</p>
            </Grid.Column>
          }
          <Grid.Column width={(order.isShoppingList) ? 5 : 2} verticalAlign="middle" textAlign="right" style={{ padding: '0px 10px 0px 0px' }}>
            <div className={styles.StatusIcon}>{getStatusIcon(order.status)}</div>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row className={styles.CustomMobileRow}>
          <Grid.Column>
            <p className={styles.DistributorName}>{order.name}</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className={styles.CustomMobileRow}>
          <Grid.Column className={styles.Logo}>
            <img src={order.photoUrl ? order.photoUrl : order.isShoppingList ? placeholderSLSrc : placeholderSrc} alt={order.distributorName} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className={styles.CustomMobileRow}>
          <Grid.Column className="order-information">
            <div className='order-detail'>
              { order.isShoppingList ?
                <>
                  <Icon color="grey" name="mail" size='large' />
                  <p>{order.email}</p>
                </>
              :
                <>
                  <Icon color="grey" name="box" size='large' />
                  <p>{t('order_number', { number: order.id, ns: 'orders' })}</p>
                </>
              }
            </div>
            <div className='order-detail'>
              <Icon color="grey" name="calendar" size='large' />
              {order.deliveryDate ? (
                <div>
                  <p>{t('requested_delivery', { ns: 'orders' })}:</p>
                  <p>{moment(order.deliveryDate).format('DD MMM YYYY')}</p>
                </div>
              ) : (
                <div>
                  { order.isShoppingList ?
                    <>
                      <p>{t('shopping_list_sent', { ns: 'orders' })}: </p>
                      <p className="green-text">{moment(order.createdAt).format('DD MMM YYYY')}</p>
                    </>
                  :
                    <>
                      <p>{t('estimated_delivery', { ns: 'orders' })} </p>
                      <p className="green-text">{moment(order.createdAt).add(1, 'days').format('DD MMM YYYY')}</p>
                    </>
                  }
                </div>
              )}
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className={styles.CustomMobileRow}>
          { !order.isShoppingList &&
            <Grid.Column className="order-status">
              <p className="green-text bold">{t(`order_${order.status}`)}</p>
              <p className="hint">{moment(order.createdAt).format('DD MMM')}</p>
            </Grid.Column>
          }
          <Grid.Column>
            <div className={styles.StatusIcon}>{getStatusIcon(order.status, 'large')}</div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
}

export default Order
