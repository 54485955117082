import React, { useState, useEffect } from 'react'
import { Table } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import TextInputWithAmountControls from '../../UI/Form/TextInputWithAmountControls'
import CheckboxInput from '../../UI/Form/CheckboxInput'
import { formatNumber } from '../../../shared/utility'
import styles from '../../../assets/styles/modules/orders/OrderItem.module.scss'
import AddInfoModal from './AddInfoModal/AddInfoModal'

const OrderItem = ({ item, index, fieldValueSetter, totalAmount, distributor = null, selectedLocation }) => {
  const [hasEmptyDistributors, setHasEmptyDistributors] = useState(false)
  const [fromDistributor, setFromDistributor] = useState('')
  const [distributorId, setDistributorId] = useState('')
  const [hasMultipleDistributors, setHasMultipleDistributors] = useState(false)
  const { t } = useTranslation(['orders'])
  const [confirmOpen, setConfirmOpen] = useState(false)

  useEffect(() => {
    if (item.distributors) {
      setHasEmptyDistributors(!!(Object.keys(item.distributors).length === 0))
      setDistributorId(Object.keys(item.distributors)[0])

      if (Object.keys(item.distributors).length === 1) {
        setFromDistributor(Object.values(item.distributors)[0].name)
      } else if (Object.keys(item.distributors).length > 1) {
        setHasMultipleDistributors(true)
        setFromDistributor(t('multiple_distributors', { ns: 'orders' }))
      } else {
        setFromDistributor(t('no_distributor_assigned', { ns: 'orders' }))
      }
    } else {
      setFromDistributor(item.distributor)
    }
  }, [item.distributor, item.distributors])

  return (
    <>
      <Table.Row className='order-item-row'>
        <Table.Cell className="order-check-cell-mobile" verticalAlign="middle" textAlign="center">
          <div className='mobile-current-stock'>
            <label className="mobile-order-label">{t('in_stock', { ns: 'orders' })}:</label>
            <p className="large">
              {formatNumber(item.amountInStock, item.unit)} {item.unit}
            </p>
          </div>

          <div className='mobile-checkbox-container'>
            <label className="mobile-order-label">{t('order', { ns: 'orders' })}:</label>
            <CheckboxInput name={`items.${index}.included`} type="checkbox" disabled={hasEmptyDistributors || (!item.orderUnit && !item.physicalUnit && !item.unit)} />
          </div>
        </Table.Cell>
        <Table.Cell width={5} className='order-item-name'>
          <p className="large">{item.name}</p>
          <p className="small">
            <span className="hint">&nbsp;&nbsp;{t('from', { ns: 'orders' })} </span>
            <span>{fromDistributor}</span>
          </p>
          {!!item.orderUnitPcs && (
            <p className="small">
              <span className="hint">&nbsp;&nbsp;{t('pcs_order_unit', { ns: 'orders' })}: </span>
              {item.orderUnitPcs + ' x ' + (item.qtyPiece ? item.qtyPiece : 1) + item.unit}
            </p>
          )}
          {!!item.minOrder && (
            <p className="small">
              <span className="hint">&nbsp;&nbsp;{t('minimum_order', { ns: 'orders' })}: </span>
              {item.minOrder} {item.orderUnit}
            </p>
          )}
        </Table.Cell>
        { ((!item.orderUnit && !item.unit) || (distributor && distributor.email === '')) ?
            <Table.Cell className={'col-span-3'} colSpan={3}>
              <div>
                <p className={styles.IncompleteInfo}>{t('incomplete_information', { ns: 'orders' })}</p>
                <p
                  className={styles.AddUnitsLink}
                  onClick={() => {
                    setConfirmOpen(true)
                  }}
                >
                  {t('complete_info', { ns: 'orders' })} 
                </p>
              </div>
            </Table.Cell>
          :
            <>
              <Table.Cell verticalAlign="middle" textAlign="center">
                <p className="large">
                  {formatNumber(item.amountInStock, item.unit)} {item.unit}
                </p>
              </Table.Cell>
              <Table.Cell className="order-amount-cell-mobile" width={5} verticalAlign="middle" textAlign="center">
                <TextInputWithAmountControls
                  name={`items.${index}.amount`}
                  label={
                    hasMultipleDistributors || !item.distributors[distributorId]?.orderUnit
                      ? item.unit
                      : item.distributors[distributorId].orderUnit
                  }
                  fieldValueSetter={fieldValueSetter}
                  currentFieldName={`items.${index}.amount`}
                  disabled={hasEmptyDistributors}
                />
              </Table.Cell>
              <Table.Cell className="order-amount-cell-mobile" width={2} verticalAlign="middle" textAlign="center">
                <p className="large">
                  <span className="total-amount-label">{t('you_are_ordering', { ns: 'orders' })} </span>
                  { !totalAmount ? 0
                    : totalAmount.orderUnitPcs
                    ? (Number(totalAmount.amount) * Number(totalAmount.orderUnitPcs) * Number(totalAmount.qtyPiece || 1)).toFixed(totalAmount.decimals)
                    : totalAmount.qtyPiece
                    ? (Number(totalAmount.amount) * Number(totalAmount.qtyPiece)).toFixed(totalAmount.decimals)
                    : Number(totalAmount.amount)}{' '}
                  {item.physicalUnit ? item.physicalUnit : item.unit}
                </p>
              </Table.Cell>
            </>
        }
        <Table.Cell className="order-check-cell desktop" verticalAlign="middle" textAlign="center">
          <CheckboxInput name={`items.${index}.included`} type="checkbox" disabled={hasEmptyDistributors || (!item.orderUnit && !item.unit)} />
        </Table.Cell>
      </Table.Row>
      <AddInfoModal
        confirmDeleteOpen={confirmOpen}
        setConfirmDeleteOpen={setConfirmOpen}
        text={t('complete_info_for', { ns: 'orders', name: item.name })}
        distributor={distributor}
        selectedLocation={selectedLocation}
        itemId={item.id}
        missingUnit={!item.orderUnit && !item.unit}
        missingEmail={distributor && distributor.email === ''}
      />
    </>
  )
}

export default OrderItem
