import { AUTH_SUCCESS, AUTH_LOGOUT, AUTH_SET_REDIRECT_PATH } from './actionTypes'
import { auth, cloudFunctions, firestore } from '../../config/firebase'
import { writeLog } from '../../shared/firebaseLogger'
import {
  browserLocalPersistence,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  setPersistence,
  updateProfile,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  getIdToken,
  getIdTokenResult
} from 'firebase/auth'
import { doc, setDoc, Timestamp, updateDoc } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'

export function setRedirectPath(path) {
  return {
    type: AUTH_SET_REDIRECT_PATH,
    path,
  }
}

export async function signInWithEmail(loginData) {
  const saveLogin = !!loginData.saveLogin

  const persistence = saveLogin ? browserLocalPersistence : browserSessionPersistence

  await setPersistence(auth, persistence)
  const result = await signInWithEmailAndPassword(auth, loginData.email, loginData.password)
  return updateDoc(doc(firestore, 'users', result.user.uid), {
    saveLogin: saveLogin,
  })
}

export function signOutFromFirebase() {
  return auth.signOut()
}

export async function createUserInFirebase(formValues) {
  await setPersistence(auth, browserSessionPersistence)
  const userCredential = await createUserWithEmailAndPassword(auth, formValues.email, formValues.password)
  await updateProfile(userCredential.user, { displayName: formValues.companyName })

  await setDoc(doc(firestore, 'users', userCredential.user.uid), {
    uid: userCredential.user.uid,
    companyName: formValues.companyName,
    email: formValues.email,
    hasPOS: formValues.hasPOS,
    hasChannels: formValues.hasChannels,
    hasMenu: formValues.hasMenu,
    createdAt: Timestamp.fromDate(new Date()),
  })

  return userCredential.user
}

export function authSuccess(user) {
  localStorage.setItem('isSignedIn', 'true')
  return {
    type: AUTH_SUCCESS,
    user,
  }
}

export function logout() {
  localStorage.removeItem('isSignedIn')
  auth.signOut()
  return {
    type: AUTH_LOGOUT,
  }
}

/*export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000);
    };
};*/

export function verifyAuth() {
  return function (dispatch) {
    try {
      return auth.onAuthStateChanged((user) => {
        if (user) {
          dispatch(authSuccess(user))
        } else {
          dispatch(logout())
        }
      })
    } catch (error) {
      writeLog('ERROR', error.message)
    }
  }
}

export async function getUserIdToken() {
  return await getIdToken(auth.currentUser, true)
}

export function getUserId() {
  return auth.currentUser?.uid
}

export async function sendForgetPasswordEmail(email) {
  return await sendPasswordResetEmail(auth, email)
}
