import React, { useState } from 'react'
import { FormField, Label, Input, Icon, Popup } from 'semantic-ui-react'
import { useField } from 'formik'
import cx from 'classnames'
import styles from '../../../assets/styles/modules/UI/TextInput.module.scss'

const TextInput = ({ defaultValue, fullHeight = false, shoppingList = false, costingCalculator = false, stockPage = false, ...props }) => {
  const [field, meta, helpers] = useField(props)
  const [clicked, setClicked] = useState(false)

  const cleanProps = { ...props }
  delete cleanProps.style
  delete cleanProps.className
  delete cleanProps.textLabel

  return (
    <FormField error={meta.touched && !!meta.error} style={props.style} className={props.className}>
      {meta.touched && meta.error ? (
        <Label pointing="below" content={meta.error} />
      ) : (
        props.textLabel && (
          <label>
            {props.textLabel}
            {props.hint && (
              <Popup
                position="top center"
                content={props.hint}
                trigger={<Icon color="grey" name="question circle" />}
              />
            )}
          </label>
        )
      )}
      <Input
        type="text"
        size="large"
        className={cx(styles.Input, fullHeight && styles.FullHeight, shoppingList && styles.ShoppingList, costingCalculator && styles.Costing, costingCalculator && styles.Calculator, stockPage && styles.Stock)}
        {...field}
        value={defaultValue || field.value?.text || field.value}
        {...cleanProps}
        disabled={props.disabled}
        onClick={() => {
          if (field.value === 0 && !clicked) {
            setClicked(true)
            helpers.setValue('')
          }
        }}
        onBlur={() => setClicked(false)}
      />
      {props.dropdownChanged && (
        <Icon
          className={cx('pointer', props.firstStockPage? styles.FirstTextInputStock : stockPage ? styles.TextInputStock : styles.TextInput)}
          name="window close outline"
          color="grey"
          size="large"
          alt="Close input"
          title="Close input"
          onClick={() => props.dropdownChanged('close-input')}
        />
      )}
    </FormField>
  )
}

export default TextInput
