import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { setPageTitle } from '../../store/actions'
import OrderPerDistributor from './Order/OrderPerDistributor'
import OrderSign from './Order/OrderSign'
import ShoppingListData from './Order/ShoppingListData'
import { Button } from 'semantic-ui-react'
import { httpsCallable } from 'firebase/functions'
import { cloudFunctions } from '../../config/firebase'
import { orderCreatedSuccess, updateShoppingLists } from '../../store/actions/orderActions'
import { asyncActionFinish, asyncActionStart, asyncActionError } from '../../store/actions/asyncActions'
import { getUserId, getUserIdToken } from '../../store/actions/authActions'
import _ from 'lodash'

const OrderSummary = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation(['orders', 'buttons'])

  const [ordersByDistributor, setOrdersByDistributor] = React.useState([])
  const { orderInProgress, shoppingLists } = useSelector((state) => state.order)
  const { loading } = useSelector((state) => state.async)
  const { locations } = useSelector((state) => state.location)
  const { distributors } = useSelector((state) => state.distributor)
  const { user } = useSelector((state) => state.user)
  const [selectedLocationInfo, setSelectedLocationInfo] = React.useState(null)
  const [isFormErrors, setIsFormErrors] = React.useState(orderInProgress?.isShoppingList ? false : true)
  const [formErrors, setFormErrors] = React.useState({})
  const [signError, setSignError] = React.useState(true)
  const [shoppingListError, setShoppingListError] = React.useState(true)

  React.useEffect(() => {
    dispatch(setPageTitle(orderInProgress?.isShoppingList ? t('shopping_list_details', { ns: 'orders' }) : t('summary', { ns: 'orders' })))
  }, [dispatch, history])

  if (!orderInProgress) history.goBack()

  React.useEffect(() => {
    let fullLocationInfo = locations.find((loc) => loc.id === orderInProgress.location.id)
    setSelectedLocationInfo(fullLocationInfo)
  }, [orderInProgress, locations])

  const getItemsPerDistributor = (stock) => {
    const groupedOrders = _.groupBy(stock, 'distributorId')
    const distributorIds = Object.keys(groupedOrders)

    const ordersPerDistributor = distributorIds.map((distributorId) => {
      const items = groupedOrders[distributorId]
      let distributorsList = Array.from(distributors)
      distributorsList.push({ id: 'none', name: 'No distributor selected '})
      return {
        id: distributorId,
        distributorName: distributorsList.filter((distributor) => distributor.id === distributorId)[0]?.name,
        items,
      }
    })

    return ordersPerDistributor
  }

  React.useEffect(() => {
    const orders = getItemsPerDistributor(orderInProgress?.orderItems)
    setOrdersByDistributor(
      orders.map((order) => {
        return { ...order }
      })
    )

    // Update order type
    orderInProgress.delivery = orders.map((order) => order.id)

    // Update Order in progress notes
    orderInProgress.notes = orders.map((order) => {
      return {
        id: order.id,
        note: '',
      }
    })

    // Update Order in progress delivery dates
    orderInProgress.deliveryDate = orders.map((order) => {
      return {
        id: order.id,
        date: '',
      }
    })
  }, [])

  const updateOrderType = (distributorId, orderType) => {
    if (orderType === 'delivery' && !orderInProgress.delivery.includes(distributorId)) {
      orderInProgress.delivery.push(distributorId)
      orderInProgress.pickUp = orderInProgress.pickUp.filter((order) => order !== distributorId)
    } else if (orderType === 'pickup' && !orderInProgress.pickUp.includes(distributorId)) {
      orderInProgress.pickUp.push(distributorId)
      orderInProgress.delivery = orderInProgress.delivery.filter((order) => order !== distributorId)
    }
  }

  const updateOrderNotes = (distributorId, note) => {
    const index = orderInProgress.notes.findIndex((note) => note.id === distributorId)
    orderInProgress.notes[index].note = note
  }

  const updateOrderDeliveryDate = (distributorId, date) => {
    const index = orderInProgress.deliveryDate.findIndex((order) => order.id === distributorId)
    orderInProgress.deliveryDate[index].date = date
  }

  const updateOrderSign = (sign) => {
    orderInProgress.sign = sign
  }

  const updateShoppingListName = (name) => {
    orderInProgress.shoppingList_name = name
  }

  const updateShoppingListEmail = (email) => {
    orderInProgress.shoppingList_email = email
  }

  const validateErrors = (hasError, orderId) => {
    setFormErrors({ ...formErrors, [orderId]: hasError })
    isValidForm({ ...formErrors, [orderId]: hasError })
  }

  const isValidForm = (errors) => {
    const values = Object.values(errors)
    const hasSameLength = orderInProgress?.isShoppingList || values.length === ordersByDistributor.length
    const formHasErrors = !!values.find((value) => value === true) || !hasSameLength
    setIsFormErrors(formHasErrors)
  }

  const addIdProductOnboarding = (orderItems) => {
    return orderItems.map(item => ({...item, id_product_onboarding: item.productIds ? item.productIds[item.distributorId] : null}))
  }

  const updateItems = (orderItems) => {
    return orderItems.map(item => ({...item, orderUnit: item.unit || item.unitForAmount, totalAmount: Number(item.totalAmount) }))
  }

  const createOrders = async () => {
    orderInProgress.orderItems = addIdProductOnboarding(orderInProgress.orderItems)
    try {
      dispatch(asyncActionStart())
      const res = await httpsCallable(
        cloudFunctions,
        'placeOrders'
      )({ ...orderInProgress, location: selectedLocationInfo })
      if (res.data.length) {
        dispatch(orderCreatedSuccess(res.data))
        history.replace('/orders/success')
      }
      dispatch(asyncActionFinish())
    } catch (error) {
      dispatch(asyncActionError(error, ''))
    }
  }

  const parseShoppingListData = (data) => {
    return data.map((item) => ({
      isShoppingList: true,
      createdAt: item.createdAt,
      signer: item.signer,
      name: item.assigned,
      orderId: null,
      photoUrl: '',
      status: item.status,
      id: item.id,
      deliveryDate: null,
      email: item.assignedEmail
    }))
  }

  const sendShoppingList = async () => {
    orderInProgress.orderItems = addIdProductOnboarding(orderInProgress.orderItems)
    orderInProgress.orderItems = updateItems(orderInProgress.orderItems)
    const user_token = await getUserIdToken()
    const userId = await getUserId()
    try {
      dispatch(asyncActionStart())
      const res = await httpsCallable(
        cloudFunctions,
        'flaskAPIPostRequest'
      )({ user_token, endpoint: '/v2/shopping-lists/create', payload: { userId, ...orderInProgress, location: selectedLocationInfo, companyName: user?.companyName }})
      if (res.data) {
        let newShoppingList = []
        newShoppingList.push(res.data)
        dispatch(updateShoppingLists(parseShoppingListData(newShoppingList).concat(shoppingLists)))
        history.push('/shopping-list/success', { data: res.data })
      }
      dispatch(asyncActionFinish())
    } catch (error) {
      dispatch(asyncActionError(error, ''))
    }
  }

  return (
    <>
      { orderInProgress?.isShoppingList &&
        <ShoppingListData
          updateShoppingListName={updateShoppingListName}
          setShoppingListError={setShoppingListError}
          updateShoppingListEmail={updateShoppingListEmail}
        />
      }
      {ordersByDistributor &&
        ordersByDistributor.map((order) => (
          <div key={order.id}>
            <OrderPerDistributor
              order={order}
              showTypeSection={!orderInProgress?.isShoppingList}
              isShoppingList={orderInProgress?.isShoppingList}
              selectedLocationInfo={selectedLocationInfo}
              updateOrderType={updateOrderType}
              updateOrderNotes={updateOrderNotes}
              updateOrderDeliveryDate={updateOrderDeliveryDate}
              validateErrors={validateErrors}
              setFormErrors={(orderId) => {
                if (!formErrors.includes(orderId)) setFormErrors([...formErrors, orderId])
              }}
            />
          </div>
        ))}
      <OrderSign
        updateOrderSign={updateOrderSign}
        setSignError={setSignError}
      />
      <div className="flex space-between gap-16">
        <Button
          type="button"
          basic
          color="red"
          onClick={() => history.goBack()}
          content={t('back', { ns: 'buttons' })}
          disabled={loading}
        />
        <Button
          content={orderInProgress?.isShoppingList ? t('send', { ns: 'buttons' }) : t('confirm', { ns: 'buttons' })}
          size="big"
          color="green"
          onClick={orderInProgress?.isShoppingList ? sendShoppingList : createOrders}
          disabled={loading || isFormErrors || signError || (orderInProgress?.isShoppingList && shoppingListError)}
          loading={loading}
        />
      </div>
    </>
  )
}

export default OrderSummary
