import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Grid, Icon, Message } from 'semantic-ui-react'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import RadioInput from '../../UI/Form/RadioInput'
import TextAreaInput from '../../UI/Form/TextAreaInput'
import DateInput from '../../UI/Form/DateInput'
import styles from '../../../assets/styles/modules/orders/OrderDetails.module.scss'
import cx from 'classnames'

const OrderPerDistributor = ({ order, updateOrderType, validateErrors, updateOrderDeliveryDate, selectedLocationInfo, updateOrderNotes, showTypeSection = true, isShoppingList = false }) => { 
  const [distributorInfo, setDistributorInfo] = useState(null)
  const { distributors } = useSelector((state) => state.distributor)
  const { t } = useTranslation(['orders', 'validationMessages', 'stock'])

  useEffect(() => {
    let distributorFound = distributors.find((dist) => dist.id === order.id)
    setDistributorInfo({ ...distributorFound })
  }, [])

  const hasErrors = (errors) => {
    return Object.keys(errors).length > 0
  }

  const date = new Date()

  const minDate = date.setDate(date.getDate() - 1)
  const initialValues = {
    type: 'delivery',
    deliveryDate: '',
    notes: '',
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      initialTouched={{ deliveryDate: true, notes: true }}
      validate={(values) => {
        const errors = {}

        if (showTypeSection) {
          if (values.type === 'pickup' && values.notes.length === 0) {
            errors.notes = t('notes', { ns: 'validationMessages' })
          }

          if (values.type === 'delivery' && !values.deliveryDate) {
            errors.deliveryDate = t('delivery_date', { ns: 'validationMessages' })
          }
        }

        validateErrors(hasErrors(errors), order.id)

        return errors
      }}
    >
      {({ values, setFieldValue, setFieldError }) => (
        <Form
          name="order-summary-form"
          className="ui form"
          onChange={(e) => {
            updateOrderNotes(order.id, e.target.value)
          }}
        >
          <div className={styles.OrderDetails}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={16} className={styles.Logo} textAlign="center">
                  <h2>{order.distributorName}</h2>
                  {distributorInfo && distributorInfo.email && (
                    <p><Icon color="grey" name="envelope" /> {distributorInfo.email}</p>
                  )}
                </Grid.Column>
              </Grid.Row>

              <Grid.Row className='grid-headers'>
                { isShoppingList ?
                  <>
                    <Grid.Column>
                      <strong>{t('item', { ns: 'orders' })}</strong>
                    </Grid.Column>
                    <Grid.Column></Grid.Column>
                    <Grid.Column textAlign="center" className={cx(styles.TotalAmount, styles.Column)}>
                      <strong>{t('total_amount', { ns: 'orders' })}</strong>
                    </Grid.Column>
                  </>
                :
                  <>
                    <Grid.Column>
                      <strong>{t('item', { ns: 'orders' })}</strong>
                    </Grid.Column>
                    <Grid.Column>
                      <strong>{t('product_number', { ns: 'orders' })}</strong>
                    </Grid.Column>
                    <Grid.Column textAlign="center">
                      <strong>{t('amount', { ns: 'orders' })}</strong>
                    </Grid.Column>
                    <Grid.Column textAlign="center">
                      <strong>{t('total_amount', { ns: 'orders' })}</strong>
                    </Grid.Column>
                  </>
                } 
                
              </Grid.Row>

              {!isShoppingList && order.items &&
                order.items.map(item => (
                  <>
                    <Grid.Row key={item.itemId} className={cx('grid-item', item.desiredAmount && 'no-padding-bottom')}>
                      <Grid.Column>{item.name}</Grid.Column>
                      <Grid.Column>{item.itemNumber}</Grid.Column>
                      <Grid.Column textAlign="center">
                        {item.amount} {t(`unit_${item.unitForAmount}`)}
                      </Grid.Column>
                      <Grid.Column textAlign="center">
                        {item.totalAmount}{' '}
                        {t(`unit_${item.unit}`, { ns: 'orders' })}
                      </Grid.Column>
                      { item.desiredAmount && !isShoppingList &&
                        <Message warning style={{ textAlign: 'center', width: '100%' }}>
                          <Message.Content>
                            <p>
                              {t('minimum_quantity', { ns: 'orders', amount: item.orderUnitPcs ? (Number(item.amount) * Number(item.orderUnitPcs) * Number(item.qtyPiece || 1)).toFixed(item.decimals) : item.qtyPiece ? (Number(item.amount) * Number(item.qtyPiece)).toFixed(item.decimals) : Number(item.amount) })}
                              {' '}
                              {t(`unit_${item.unitForAmount}`, { ns: 'orders' })}
                            </p>
                          </Message.Content>
                        </Message>
                      }
                    </Grid.Row>
                    <Grid.Row key={item.itemId + ".itemNumber"} className='mobile-item-number'>
                      <p>{t('item_no', { ns: 'stock' })} {item.itemNumber}</p>
                    </Grid.Row>
                  </>
                ))}

              {isShoppingList && order.items &&
                order.items.map(item => (
                  <>
                    <Grid.Row key={item.itemId} className={cx('grid-item', styles.BorderBottom)}>
                      <Grid.Column>{item.name}</Grid.Column>
                      <Grid.Column></Grid.Column>
                      <Grid.Column textAlign="center">
                        {item.totalAmount}{' '}
                        {t(`unit_${item.unit}`, { ns: 'orders' })}
                      </Grid.Column>
                    </Grid.Row>
                  </>
                ))}
            </Grid>
            <hr className={styles.Divider} />
            <Grid className='inputs-grid'>
              { showTypeSection &&
                <>
                  <Grid.Row className='checkbox-row'>
                    <Grid.Column>
                      <RadioInput
                        name="type"
                        value="delivery"
                        label={t('delivery', { ns: 'orders' })}
                        onClick={() => {
                          setFieldValue('type', 'delivery')
                          updateOrderType(order.id, 'delivery')
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <RadioInput
                        name="type"
                        value="pickup"
                        label={t('pickup', { ns: 'orders' })}
                        onClick={() => {
                          setFieldValue('type', 'pickup')
                          setFieldError('notes', t('notes', { ns: 'validationMessages' }))
                          updateOrderType(order.id, 'pickup')
                        }}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    {values.type === 'delivery' ? (
                      <Grid.Column width={16}>
                        <Grid stackable>
                          <Grid.Row>
                            <Grid.Column width={7} className='grid-custom-column'>
                              <DateInput
                                name="deliveryDate"
                                label={`${t('requested_delivery_date', { ns: 'orders' })}:`}
                                minDate={minDate}
                                onChange={(e, data) => {
                                  setFieldValue('deliveryDate', data.value)
                                  updateOrderDeliveryDate(order.id, data.value)
                                }}
                              />
                            </Grid.Column>
                            <Grid.Column width={7} className='grid-custom-column-padding-left'>
                              <p className="hint">{t('delivery_location', { ns: 'orders' })}</p>
                              {selectedLocationInfo && (
                                <p>
                                  {selectedLocationInfo.street},&nbsp;
                                  {selectedLocationInfo.postcode} {selectedLocationInfo.place}
                                </p>
                              )}
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Grid.Column>
                    ) : (
                      <Grid.Column width={16} className='grid-custom-column'>
                        <p className="align-center">
                          {t('indicate_notes', { ns: 'orders' })}
                        </p>
                      </Grid.Column>
                    )}
                  </Grid.Row>
                </>
              }
              <Grid.Row>
                <Grid.Column width={16} className='grid-custom-column'>
                  <TextAreaInput
                    name="notes"
                    placeholder={t('notes', { ns: 'orders' })}
                    onClick={(ev) => {
                      ev.target.scrollIntoView({});
                      ev.target.scrollIntoView({
                        behavior: "smooth",
                        block: "end"
                      })
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default OrderPerDistributor
