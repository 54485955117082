import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from '../../assets/styles/modules/shoppingList/loading.module.scss'
import calculator from '../../assets/images/calculator.svg'
import cx from 'classnames'

const Loading = () => {
  const { t } = useTranslation(['common']);

  return (
    <div className={styles.LoadingContainer}>
        <p>{t('shopping_list_loading', { ns: 'common' })}</p>
        <img className={cx(styles.CalculatorLogo, styles.Calculator)} src={calculator} alt="Calculator" />
    </div>
  )
}

export default Loading