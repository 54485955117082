import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Redirect } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import { getUserProfileFromFirestore, listenToUserProfile } from '../../store/actions/userActions'
import useFirestoreDoc from '../../hooks/useFirestoreDoc'
import useSessionStorage from '../../hooks/useSessionStorage'
import { orderInProgressAction } from '../../store/actions/orderActions'
import OrderDistributorItem from './OrderDistributorItem/OrderDistributorItem'
import { Button, Label, Message, Table, Search } from 'semantic-ui-react'
import Spinner from '../UI/Spinner/Spinner'
import { Form, Formik, FieldArray } from 'formik'
import { httpsCallable } from 'firebase/functions'
import { cloudFunctions } from '../../config/firebase'
import { getUserId, getUserIdToken } from '../../store/actions/authActions'
import * as Yup from 'yup'
import styles from '../../assets/styles/modules/orders/OrderDistributorsForm.module.scss'
import btnStyles from '../../assets/styles/modules/UI/Button.module.scss'
import useSearchCallback from '../../hooks/useSearchCallback'
import cx from 'classnames'

const OrderDistributorsForm = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation(['validationMessages', 'common', 'orders', 'buttons'])
  const { user } = useSelector((state) => state.user)
  const { error } = useSelector((state) => state.async)
  const { selectedLocation } = useSelector((state) => state.location)
  const { orderInProgress } = useSelector((state) => state.order)
  const { value, searchResults } = useSelector((state) => state.search)
  const { distributors } = useSelector((state) => state.distributor)
  const { stock } = useSelector((state) => state.stock)
  const [currentOrder, setCurrentOrder] = useSessionStorage('currentOrder', null)
  const [prefill, setPrefill] = React.useState({})
  const [loading, setLoading] = React.useState(true)
  const [initialValues, setInitialValues] = React.useState({})

  useFirestoreDoc({
    query: () => getUserProfileFromFirestore(),
    data: (user) => dispatch(listenToUserProfile(user)),
    deps: [dispatch],
  })

  React.useEffect(() => {
    const loadPrefillInfo = async () => {
      const user_token = await getUserIdToken()
      const userId = await getUserId()

      try {
        const res = await httpsCallable(
          cloudFunctions,
          'flaskAPIGetRequest'
        )({ user_token, endpoint: `/v2/shopping-lists/user/${userId}/location/${selectedLocation.id}/prefill` })
  
        setPrefill({ ...res.data })
      } catch {
        setLoading(false)
      }
      
      setLoading(false)
    }
    
    if(orderInProgress?.isShoppingList && selectedLocation) {
      loadPrefillInfo()
    } else {
      setLoading(false)
    }
  }, [])

  React.useEffect(() => {
    let initialNoDistributor = []
    orderInProgress?.orderItems.forEach((item) => {
      if (prefill && ((prefill[item.itemId] === 'none') || (item?.distributor === 'No distributor selected'))) {
        item.distributor = 'No distributor selected'
        item.distributorId = 'none'
      }
    })

    const initial = Object.keys(prefill).map((itemId) => {
      const item = orderInProgress?.orderItems.filter(item => item.itemId === itemId)
      return item[0] ? { ingredient: item[0].name, selectedDistributor: prefill[itemId], selectedProduct: itemId } : null
    }).filter(el => el !== null)

    // If there are items within the order that are not inside the prefill object,
    // they should be on the list with no distributor selected

    if (Object.keys(prefill).length) {
      initialNoDistributor = orderInProgress?.orderItems.map((item) => {
        if (!Object.keys(prefill).includes(item.itemId)) {
          return { ingredient: item.name, selectedDistributor: 'none', selectedProduct: item.itemId }
        }
        return null
      }).filter(el => el !== null)
    }

    setInitialValues({ distributors: initial.concat(initialNoDistributor) })
  }, [prefill])

  const handleSearchChange = useSearchCallback(orderInProgress.orderItems)

  if (loading || !user) return <Spinner content={t('please_wait', { ns: 'common' })} />

  if (
    (!user.isPremium) || (user.hasLocations && props.match.params.location && !selectedLocation)
  ) {
    return <Redirect to="/orders" />
  }

  const updateOrderItemWithSingleDistributorOption = (orderItem, updatedOrderSummary) => {
    const selectedDistributorId = orderItem.distributors
      ? Object.keys(orderItem.distributors)[0]
      : orderItem.distributorId
    const selectedDistributorName = orderItem.distributors
      ? Object.values(orderItem.distributors)[0]
      : orderItem.distributor
    const updatedOrderItem = {
      ...orderItem,
      desiredAmount: !orderItem.desiredAmount ? orderItem.totalAmount : orderItem.desiredAmount,
      selectedDistributor: {
        name: selectedDistributorName,
        selectedDistributorId: selectedDistributorId,
      },
    }
    updatedOrderSummary.push(updatedOrderItem)
  }

  // Returns selected distributor
  // In case it's a shopping list, searches for the distributor within distributors' list
  // In case the order item has multiple distributors, searches for the distributor within multiDistributors' list 
  const getDistributor = (isShoppingList = false, distributors, distributorId = 'none', multiDistributors, selectedProduct) => {
    if (isShoppingList || !multiDistributors) {
      return distributors[distributorId]
    } else {
      return multiDistributors[distributorId].filter(el => el.generic_name === selectedProduct)[0]
    }
  }

  const updateOrderInProgress = (values) => {
    const updatedOrderSummary = []
    const orderAmountSummary = []
    // Delete empty values from distributors array
    const distributors = values.distributors.filter((distributor) => distributor)
    orderInProgress.orderItems.forEach((orderItem) => {
      //Order Items list has items with multiple distributor list for selection
      if (distributors.length > 0 && orderItem.distributors) {
        const orderItemWithSelectedDistributor = distributors.find((ingrDist) => ingrDist.ingredient === orderItem.name)
        if (orderItemWithSelectedDistributor) {
          const selectedDistributorId = Object.keys(orderItem.distributors).find(
            (key) => key === orderItemWithSelectedDistributor.selectedDistributor
          )
          const selectedDistributor = getDistributor(orderInProgress?.isShoppingList, orderItem.distributors, selectedDistributorId, orderItem.multiDistributors, orderItemWithSelectedDistributor.selectedProduct)
          const amountPerOrder = (selectedDistributor.orderUnitPcs || 1) * (selectedDistributor.qtyPiece || 1)
          let desiredAmount = orderItem.desiredAmount ? orderItem.desiredAmount : orderItem.amount
          let newAmount = Math.ceil(orderItem.amount / amountPerOrder)
          if (currentOrder) {
            const currentItem = currentOrder.find((el) => el.itemId === orderItem.itemId)
            if (currentItem) {
              newAmount = Math.ceil(currentItem.amount / amountPerOrder)
            }
          } else {
            orderAmountSummary.push({ itemId: orderItem.itemId, amount: Number(orderItem.amount) })
          }
          const updatedOrderItem = {
            ...orderItem,
            amount: newAmount,
            itemNumber: selectedDistributor.itemNumber,
            distributor: selectedDistributor.name,
            distributorId: selectedDistributorId,
            qtyPiece: selectedDistributor.qtyPiece,
            orderUnit: selectedDistributor.orderUnit,
            orderUnitPcs: selectedDistributor.orderUnitPcs,
            selectedDistributor: {
              name: selectedDistributor.name,
              selectedDistributorId,
            },
            desiredAmount,
          }
          updatedOrderSummary.push(updatedOrderItem)
        } else {
          // this is one of the orders items which has no multiple distributor list for selection
          updateOrderItemWithSingleDistributorOption(orderItem, updatedOrderSummary)
        }
      } else {
        // None of the order list items has multiple distributor list for selection
        updateOrderItemWithSingleDistributorOption(orderItem, updatedOrderSummary)
      }
    })
    if (orderAmountSummary !== [] && currentOrder === null) {
      setCurrentOrder(orderAmountSummary)
    }
    return updatedOrderSummary
  }

  const getSelectedDistributor = (distributorId, distributors) => {
    if ((!distributorId) || (!Object.keys(distributors).includes(distributorId))) {
      return 'none'
    } else {
      return distributorId
    }
  }

  const updateDistributors = (values, orderItems) => {
    let result = {}

    if (!values.distributors) {
      result.distributors = []
      orderItems.forEach((item) => {
        result.distributors.push({ ingredient: item.name, selectedDistributor: getSelectedDistributor(item.distributorId, item.distributors), selectedProduct: item.itemId })
      })
    } else {
      let itemsWithNoDistributor = []
      orderItems.forEach((item) => {
        if (!values.distributors.some(dist => dist && dist.selectedProduct === item.itemId)) {
          itemsWithNoDistributor.push({ ingredient: item.name, selectedDistributor: getSelectedDistributor(item.distributorId, item.distributors), selectedProduct: item.itemId })
        }
      })
      result.distributors = itemsWithNoDistributor.concat(values.distributors.filter(dist => dist))
    }

    return result
  }

  const filterSelectedDistributor = (items) => {
    return items.map((item) => ({ ...item, selectedDistributor: item.selectedDistributor.name.name ? { ...item.selectedDistributor, name: item.selectedDistributor.name.name } : {  ...item.selectedDistributor, name: item.selectedDistributor.name }}))
  }

  const saveOrderInProgress = (values) => {
    if (orderInProgress?.isShoppingList) {
      orderInProgress.orderItems.forEach((item) => item.distributors = {...item.distributors})
    }
    const updatedOrderItems = filterSelectedDistributor(updateOrderInProgress(updateDistributors(values, orderInProgress.orderItems)))
    dispatch(
      orderInProgressAction({
        orderItems: updatedOrderItems,
        suggestionsUsed: orderInProgress.suggestionsUsed,
        dateRange: orderInProgress.dateRange,
        deliveryDate: orderInProgress.deliveryDate,
        location: orderInProgress.location,
        delivery: [],
        pickUp: [],
        notes: [],
        isShoppingList: !!orderInProgress.isShoppingList
      })
    )

    history.push('/order-summary')
  }

  const multiDistributorIngredients = orderInProgress.orderItems.filter(
    (item) => (item.distributors && Object.values(item.distributors).length > 1) || (item.multiDistributors && Object.keys(item.multiDistributors).some(distId => item.multiDistributors[distId].length > 1))
  )
  const distributorsDropdownOptions = []

  multiDistributorIngredients.forEach((ingredient) => {
    Object.keys(ingredient.distributors).forEach((key) => {
      distributorsDropdownOptions.push({
        text: ingredient.distributors[key].name,
        value: key,
        key,
      })
    })
  })

  const getDropdownOptions = (item, isShoppingList = false) => {
    const distributorsDropdownOptions = []
    if (isShoppingList) {
      distributors.forEach((distributor) => {
        if (distributor.name !== '') {
          distributorsDropdownOptions.push({
            text: distributor.name,
            value: { distributor: distributor.id, product: item.itemId },
            key: distributor.id,
            description: Object.keys(item.originalDistributors).includes(distributor.id) ? '[Linked]' : '',
          })
        }
      })
    } else {
      item.onboarding.forEach((item) => {
        distributorsDropdownOptions.push({
          text: item.distributor_name,
          value: { distributor: item.id_distributor, product: item.id_product_onboarding ? item.id_product_onboarding : item.typed_product },
          key: item.id_distributor,
          disabled: !item.id_product_onboarding && !item.typed_product,
          description: !item.id_product_onboarding && !item.typed_product ? t('item_being_verified', { ns: 'orders' }) : item.id_product_onboarding ? item.id_product_onboarding : item.typed_product
        })
      })
    }
    return distributorsDropdownOptions
  }

  const getMultiDistributorItemsQuantity = (items) => {
    const test = items.filter((item) => Object.keys(item.distributors || {}).length > 1)
    return test.length
  }

  const getMultiStockPerDistributorItemsQuantity = (items) => {
    const test = items.filter((item) => Object.keys(item.multiDistributors || {}).some(distId => item.multiDistributors[distId].length > 1))
    return test.length
  }

  const getIsDisabled = (values, items, isShoppingList = false) => {
    return isShoppingList ? false : ((values.distributors?.filter((value) => value).length !== getMultiDistributorItemsQuantity(items)) && (values.distributors?.filter((value) => value).length !== getMultiStockPerDistributorItemsQuantity(items)))
  }

  const findItemIndex = (distributors = [], itemName) => {
    let result = 0
    for (let index = 0; index < distributors.length; index++) {
      if (distributors[index].ingredient === itemName) {
        result = index
      }
    }
    return result
  }

  return (
    <div className={styles.OrderFormContainer}>
      <div className="btn-container top fixed center-content search">
        <Search
          id="ingredientSearch"
          loading={loading}
          onSearchChange={handleSearchChange}
          results={[]}
          showNoResults={false}
          value={value}
          placeholder={t('search_for_ingredient', { ns: 'orders' })}
        />
      </div>
      <div>
        { orderInProgress?.isShoppingList ?
            <div className={styles.DistributorsTitle}>
              <p className="large" style={{ marginTop: '20px' }}>
                Some items have multiple distributors attached to them! <br /> Select who you want to shop from. &nbsp;
              </p>
            </div>
          :
            <div className={styles.DistributorsTitle}>
              <Trans
                t={t}
                ns={'orders'}
                i18nKey={'distributors_attached'}
              >
                <p className="large" style={{ marginTop: '20px' }}>
                  Some items have multiple distributors attached to them! <br /> Select who you want to order from. &nbsp;
                </p>
              </Trans>
            </div>
        }
        {error && <Message warning>{t('something_wrong', { ns: 'common' , error })}</Message>}
        {props.match.params.location && selectedLocation && (
          <p className="x-large align-center">
            {t('new_order_for', { ns: 'orders' })} <strong>{selectedLocation.name}</strong>
          </p>
        )}
        <Formik
          onSubmit={(values) => saveOrderInProgress(values)}
          validationSchema={Yup.object().shape({
            items: Yup.array().of(
              Yup.object().shape({
                amount: Yup.number().typeError(t('number', { ns: 'validationMessages' })).min(0, t('below_zero', { ns: 'validationMessages' })),
              })
            ),
          })}
          enableReinitialize={true}
          initialValues={initialValues}
        >
          {({ isSubmitting, errors, setFieldValue, values }) => (
            <Form autocomplete="off" className="ui form">
              {errors.distr && <Label basic color="red" content={errors.stock} style={{ marginBottom: 10 }} />}
              <FieldArray
                name="ingredients"
                render={() => (
                  <Table striped compact className="order-form-table order-distributors-table">
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>{t('ingredient', { ns: 'orders' })}:</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">{t('from', { ns: 'orders' })} </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">{t('ordering', { ns: 'orders' })}</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {orderInProgress?.orderItems.length > 0 &&
                        orderInProgress.orderItems.map((item, index) => {
                          if (!searchResults.length || searchResults.find((ing) => ing.itemId === item.itemId)) {
                            return (
                              <OrderDistributorItem
                                key={item.itemId}
                                values={values}
                                index={index}
                                item={item}
                                currentOrder={currentOrder}
                                fieldValueSetter={setFieldValue}
                                distributorsOptions={
                                  multiDistributorIngredients.find((ingr) => ingr === item) || (orderInProgress?.isShoppingList)
                                    ? getDropdownOptions(item, orderInProgress?.isShoppingList)
                                    : null
                                }
                                currentFieldName={`distributors.${findItemIndex(values?.distributors, item.name)}`}
                                defaultValue={((prefill && prefill[item.itemId]) || ((item.distributorId !== 'none') && (item.distributor !== 'No distributor selected'))) ? { distributor: prefill[item.itemId] || item.distributorId, product: item.itemId } : null}
                              />
                            )
                          } else {
                            return <></>
                          }
                        })}
                    </Table.Body>
                  </Table>
                )}
              />
              <div className={cx(styles.ButtonsContainer, 'btn-container bottom fixed floating full-width')}>
                <span className={styles.ButtonWrapper}>
                  <Button
                    className={cx(styles.Button, styles.Orange)}
                    floated="left"
                    type="button"
                    basic
                    color="orange"
                    size="big"
                    onClick={() => history.push('/orders')}
                    content={t('cancel', { ns: 'buttons' })}
                  />
                  <Button
                    className={cx(styles.Button, styles.Green, btnStyles.Secondary)}
                    floated="right"
                    loading={isSubmitting}
                    disabled={getIsDisabled(values, orderInProgress.orderItems, orderInProgress?.isShoppingList)}
                    type="submit"
                    content={t('next', { ns: 'buttons' })}
                    size="big"
                  />
                </span>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default OrderDistributorsForm
