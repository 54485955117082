import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  getUserProfileFromFirestore,
  updateMenuItem,
  listenToUserProfile,
} from '../../../store/actions'
import useFirestoreDoc from '../../../hooks/useFirestoreDoc'
import { Button, Grid, Icon, Segment, Popup } from 'semantic-ui-react'
import { setPageTitle } from '../../../store/actions'
import styles from '../../../assets/styles/modules/menu/MenuItem.module.scss'
import Spinner from '../../UI/Spinner/Spinner'
import { flaskAPIGet, filterAllergens  } from '../../../shared/utility'
import { getUserId } from '../../../store/actions/authActions'
import AccordionViewMenuItem from '../MenuItem/ViewMenuItem/AccordionViewMenuItem'
import cx from 'classnames'

const ViewCombo = ({ match }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { menuItem } = useSelector((state) => state.menu)
  const { user } = useSelector((state) => state.user)
  const { selectedLocation } = useSelector((state) => state.location)
  const { t } = useTranslation(['common', 'menu', 'buttons'])
  const [loading, setLoading] = React.useState(true)

  const { location, id } = match.params

  useFirestoreDoc({
    query: () => getUserProfileFromFirestore(),
    data: (user) => dispatch(listenToUserProfile(user)),
    deps: [dispatch],
  })

  React.useEffect(() => {
    const loadMenuItem = async () => {
      const userId = await getUserId()
      const recipeData = await flaskAPIGet({ endpoint: `/v2/menu/user/${userId}/location/${location}/recipes/${id}` })
      dispatch(updateMenuItem(recipeData))
      dispatch(setPageTitle(recipeData?.name))
      setLoading(false)
    } 
    loadMenuItem()
  }, [])

  if (!id || (!!location !== false && (!selectedLocation || selectedLocation.id !== location))) {
    return <Redirect to="/menu" />
  }

  if (loading || !menuItem) return <Spinner content={t('please_wait', { ns: 'common' })} />

  const notes = !!menuItem.notes?.length && (
    <Grid.Row style={{ paddingTop: 20 }}>
      <Grid.Column style={{ padding: '0px' }} width={16}>
        <hr style={{ margin: '0px -25px 17px -25px' }} />
        <span className="hint small">{t('notes', { ns: 'menu' })}</span>
        { menuItem.notes.split('\n').map((note) => 
            <p className={styles.NoteItem}>{note}</p>
          )
        }
      </Grid.Column>
    </Grid.Row>
  )

  return (
    <Segment raised className={styles.RecipeDetail}>
      <Grid relaxed stackable={false} style={{ maxWidth: '85%' }}>
        {!!location && (
          <Grid.Row>
            <Grid.Column width={16} textAlign="center">
              <strong>{t('location', { ns: 'menu' })}: </strong>
              {selectedLocation.name}
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row className={styles.Heading} style={{ padding: '11px 0px 2px' }}>
          <Grid.Column width={10} style={{ paddingLeft: '0px' }}>
            {t('included_menu_items', { ns: 'menu' })}
          </Grid.Column>
          <Grid.Column width={6} textAlign="right" style={{ padding: '0px' }}>
            {t('servings', { ns: 'menu' })}
          </Grid.Column>
        </Grid.Row>
        {Object.values(menuItem.subrecipes).map(({ id, name, servings }) => (
          <Grid.Row key={id} style={{ padding: '6px 0px 0px' }}>
            <Grid.Column width={10} style={{ paddingLeft: '0px' }}>
              {name}
            </Grid.Column>
            <Grid.Column width={6} textAlign="right" style={{ padding: '0px' }}>
              {servings} {t('srv', { ns: 'menu' })}
            </Grid.Column>
          </Grid.Row>
        ))}
        <Grid.Row style={{ paddingBottom: '0px' }}>
            <Grid.Column style={{ padding: '0px' }} width={16}>
              <hr style={{ margin: '0px -25px 17px -25px' }} />
              { menuItem?.co2Info &&
                <Grid.Row>
                  <Grid.Column width={16} style={{ padding: 0 }}>
                    <AccordionViewMenuItem {...menuItem.co2Info} openable={menuItem?.co2Info?.items.length} />
                  </Grid.Column>
                </Grid.Row>
              }
            </Grid.Column>  
          </Grid.Row>
        { menuItem?.allergens && filterAllergens(menuItem?.allergens).length > 0 &&
          <Grid.Row>
            <Grid.Column width={16} style={{ padding: 0 }}>
              <p className={styles.AllergensTitle}>Allergens</p>
              <div className={styles.PillsContainer}>
                { filterAllergens(menuItem.allergens).map((allergen) => <span className={styles.Pill}>{allergen}</span> ) }
                <Popup
                  className={styles.AllergensPopup}
                  position="bottom right"
                  trigger={ <Icon className={styles.AllergensTrigger} color="grey" name="info circle" /> }
                >
                  <p style={{ marginBottom: '15px' }}>The ingredients listed in this dish may contain common allergens. Please familiarize yourself with potential allergens and consider cross-contamination with other allergens.</p>
                  <p>This allergen information was generated using AI technology and is intended to provide a general guideline. However, the results may not be 100% accurate.</p>
                </Popup>
              </div>
            </Grid.Column>
          </Grid.Row>
        } 
        {notes}
        { menuItem?.nutritionalInfo &&
          <Grid.Row>
            <Grid.Column width={16} style={{ padding: 0 }}>
              <AccordionViewMenuItem {...menuItem.nutritionalInfo} openable={menuItem?.nutritionalInfo?.items.length} showHint={true} rearrange={true} />
            </Grid.Column>
          </Grid.Row>
        }
        { menuItem?.costing &&
          <Grid.Row>
            <Grid.Column width={16} style={{ padding: 0 }}>
              <AccordionViewMenuItem {...menuItem.costing} openable={menuItem?.costing?.items.length} currencySign={user?.preferredCurrency} />
            </Grid.Column>
          </Grid.Row>
        }
      </Grid>
      <div className={styles.ButtonsContainer}>
        <Button
          className={cx(styles.Button, styles.Green, styles.ViewCombo)}
          content={t('edit', { ns: 'buttons' })}
          color="green"
          size="large"
          basic
          onClick={() => history.push(`/menu/combo/edit/${id}/location/${location}`)}
        />
        <Button
            className={cx(styles.Button, styles.Green, styles.CostingButton)}
            content="Costing Calculator"
            color="green"
            size="large"
            basic
            onClick={() => {
              history.push(`/costing-calculator/${id}/location/${location}`)
            }}
          />
      </div>
    </Segment>
  )
}

export default ViewCombo
