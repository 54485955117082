import * as actionTypes from './actionTypes'
import { firestore } from '../../config/firebase'
import { query, collection, orderBy, doc, where } from 'firebase/firestore'
import { getUserId } from './authActions'

export const updateMenuItems = (menuItems, locationId = null) => {
  return {
    type: actionTypes.ADD_MENU_ITEMS_SUCCESS,
    menuItems: menuItems,
    lastLocationId: locationId,
  }
}

export const updateMenuItem = (menuItem) => {
  return {
    type: actionTypes.UPDATE_MENU_ITEM_SUCCESS,
    menuItem,
  }
}

export function getMenuItemsFromFirestore(locationId = null) {
  const menuCollection = !locationId
    ? `users/${getUserId()}/recipes`
    : `users/${getUserId()}/locations/${locationId}/recipes`

  return query(
    collection(firestore, menuCollection),
    where('deleted', '!=', true),
    orderBy('deleted', 'asc'),
    orderBy('name', 'asc')
  )
}

export function getMenuItemFromFirestore(itemId, locationId = null) {
  if (!locationId) {
    return doc(firestore, `users/${getUserId()}/recipes`, itemId)
  } else {
    return doc(firestore, `users/${getUserId()}/locations/${locationId}/recipes`, itemId)
  }
}
