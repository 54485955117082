import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Redirect, useLocation } from 'react-router-dom'
import { PersistFormikValues } from 'formik-persist-values'
import { useTranslation } from 'react-i18next'
import {
  getUserProfileFromFirestore,
  listenToUserProfile,
  updateUseSuggestionsSetting,
} from '../../store/actions/userActions'
import useFirestoreDoc from '../../hooks/useFirestoreDoc'
import useSessionStorage from '../../hooks/useSessionStorage'
import { setPageTitle } from '../../store/actions'
import { orderInProgressAction } from '../../store/actions/orderActions'
import OrderItem from './OrderItem/OrderItem'
import { Button, Checkbox, FormField, Grid, Label, Message, Search, Table, Dropdown } from 'semantic-ui-react'
import Spinner from '../UI/Spinner/Spinner'
import Accordion from '../UI/Accordion/Accordion'
import { Form, Formik, FieldArray } from 'formik'
import useSearchCallback from '../../hooks/useSearchCallback'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import moment from 'moment'
import * as Yup from 'yup'
import { getDistributorsFromStock } from '../../shared/utility'
import styles from '../../assets/styles/modules/orders/OrderForm.module.scss'
import cx from 'classnames'

const OrderForm = (props) => {
  const FORM_NAME = 'order-form'
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const recipesData = location.state?.recipesData
  const calculatedIngredients = location.state?.calculatedIngredients
  const { t } = useTranslation(['orders', 'validationMessages', 'buttons', 'common', 'distributor'])

  const { stock } = useSelector((state) => state.stock)
  const { user } = useSelector((state) => state.user)
  const { loading, error } = useSelector((state) => state.async)
  const { value, searchResults } = useSelector((state) => state.search)
  const { selectedLocation } = useSelector((state) => state.location)
  const { distributors } = useSelector((state) => state.distributor)

  const [dateRange, setDateRange] = React.useState([])
  const [suggestionsLoading, setSuggestionsLoading] = React.useState(false)
  const [orderSuggestions, setOrderSuggestions] = React.useState(null) // All suggestions (7 days)
  const [suggestionsPerItem, setSuggestionsPerItem] = React.useState({}) // Suggestions for date filter
  const [currentOrder, setCurrentOrder] = useSessionStorage('currentOrder', null)
  const [currentResults, setCurrentResults] = React.useState(searchResults)
  const [btnClicked, setBtnClicked] = React.useState('')

  React.useEffect(() => {
    dispatch(setPageTitle(t('shopping_list', { ns: 'orders' })))
  }, [])

  React.useEffect(() => {
    if (searchResults.length !== 0) {
      setCurrentResults(searchResults)
    }
  }, [searchResults])

  React.useEffect(() => {
    if (history.action !== 'POP') {
      localStorage.removeItem(FORM_NAME)
    }
  }, [history])

  useFirestoreDoc({
    query: () => getUserProfileFromFirestore(),
    data: (user) => dispatch(listenToUserProfile(user)),
    deps: [dispatch],
  })

  // Filter out archived items
  const activeStock = stock ? stock.filter((item) => item.isArchived !== true) : []
  const distributorsFromStock = getDistributorsFromStock(activeStock, distributors, t)
  const handleSearchChange = useSearchCallback(activeStock)
  let initialAmounts = new Array(activeStock.length); for (let i=0; i<activeStock.length; ++i) initialAmounts[i] = 0;
  const totalAmounts = React.useRef(initialAmounts)

  if (loading || !user) return <Spinner content={t('please_wait', { ns: 'common' })} />

  if (
    (!user.isPremium) || (user.hasLocations && props.match.params.location && !selectedLocation)
  ) {
    return <Redirect to="/orders" />
  }

  if (!stock || !activeStock.length) return <Message content={t('add_some_stock', { ns: 'orders' }) }/>

  /**
   * Sum of order suggestions per Ingredient for given number of days.
   *
   * @param Object items
   * @param array daysRange
   * @returns Object
   */
  const getTotalSuggestionsPerItem = (dateRange = null) => {
    let totalSuggestionsPerItem = {}
    if (orderSuggestions && orderSuggestions.length) {
      let filteredItems = [...orderSuggestions]

      // Filter by selected date range
      if (dateRange && dateRange.length === 2) {
        filteredItems = orderSuggestions.filter((item) => {
          return Date.parse(item.Date) >= Date.parse(dateRange[0]) && Date.parse(item.Date) <= Date.parse(dateRange[1])
        })
      }

      for (let i in filteredItems) {
        let stockItems = Object.keys(filteredItems[i]) //orderSuggestions[i];
        stockItems.forEach(function (key) {
          if (key !== 'Date') {
            if (totalSuggestionsPerItem[key]) {
              totalSuggestionsPerItem[key]['qty'] = totalSuggestionsPerItem[key]['qty'] + filteredItems[i][key].quantity
            } else {
              totalSuggestionsPerItem[key] = {
                qty: filteredItems[i][key].quantity,
                unit: filteredItems[i][key].unit,
              }
            }
          }
        })
      }
    }
    return totalSuggestionsPerItem
  }

  const getSuggestionForItem = (item) => {
    let suggestion = 0
    if (user.useSuggestions) {
      if (suggestionsPerItem[`${item.id}`]) {
        let divider = 1
        if (item.unit !== suggestionsPerItem[`${item.id}`].unit) {
          if (item.unit === 'kg' || item.unit === 'l') {
            divider = 1000
          }
        }
        suggestion = Math.ceil(suggestionsPerItem[`${item.id}`].qty / divider)
      }
    }
    return suggestion
  }

  // Map stock items with order suggestion values
  const items = activeStock
    .filter((item) => item.isArchived !== true)
    .map((item) => ({
      itemId: item.id,
      name: item.name,
      itemNumber: item.itemNumber ? item.itemNumber : '',
      distributors: item.distributors,
      distributorId: item.distributorId,
      distributor: item.distributor,
      amountInStock: item.amount,
      amount: calculatedIngredients && Object.keys(calculatedIngredients).includes(item.id) ? calculatedIngredients[item.id] : getSuggestionForItem(item),
      qtyPiece: item.qtyPiece,
      unit: item.unit,
      orderUnit: item.orderUnit ? item.orderUnit : item.unit,
      orderUnitPcs: item.orderUnitPcs,
      minOrder: item.minOrder,
      included: calculatedIngredients && Object.keys(calculatedIngredients).includes(item.id),
      productIds: item.onboarding ? Object.assign({}, ...item.onboarding.map((el) => ({[el.id_distributor]: el.id_product_onboarding}))) : null,
      onboarding: item.onboarding ? item.onboarding : null,
      multiDistributors: item.multiDistributors ? item.multiDistributors : null 
    }))

  const sortItems = (items) => items.sort((a, b) => {
    if (a.included > b.included) {
      return -1;
    }
    if (a.included < b.included) {
      return 1;
    }
    return 0;
  })

  // For the order date picker
  const currentDate = new Date()
  const minDate = new Date()
  minDate.setDate(currentDate.getDate() - 1)
  const maxDate = new Date()
  maxDate.setDate(maxDate.getDate() + 6)
  const initialValues = { items: sortItems(items) }

  async function onSuggestionsToggle() {
    await updateUseSuggestionsSetting(!user.useSuggestions)
  }

  const onSelectAll = (elm, formValues, fieldValueSetter) => {
    for (let i in formValues) {
      fieldValueSetter(`items.${i}.included`, elm.checked)
    }
  }

  const useSuggestionsToggle =
    user.useSuggestions && user.useSuggestions === true ? (
      <Checkbox toggle defaultChecked onChange={onSuggestionsToggle} />
    ) : (
      <Checkbox toggle onChange={onSuggestionsToggle} />
    )

  const saveOrderInProgress = (values) => {
    let orderItems = values.items.filter((item) => item.included && Number(item.amount) > 0)
    const hasMultipleDistributors =
      orderItems.filter((item) => item.distributors && Object.keys(item.distributors).length > 1).length > 0
    const hasMultipleStockPerDistributor =
      orderItems.filter((item) => item.multiDistributors && Object.keys(item.multiDistributors).some(distId => item.multiDistributors[distId].length > 1)).length > 0
    let stockItem = null
    orderItems = orderItems.map((item, index) => {
      const itemDecimals = totalAmounts.current && totalAmounts.current.length > 0 ? totalAmounts.current.filter(el => el.itemNumber === item.itemNumber && el.amount === item.amount) : null
      if (item.distributors && Object.keys(item.distributors).length === 1) {
        const distributorId = Object.keys(item.distributors)[0]
        item.distributor = item.distributors[distributorId].name
        item.distributorId = distributorId
        item.itemNumber = item.distributors[distributorId].itemNumber
        item.qtyPiece = item.distributors[distributorId].qtyPiece
        item.orderUnit = item.distributors[distributorId].customDistributor ? item.unit : item.distributors[distributorId].orderUnit
        item.orderUnitPcs = item.distributors[distributorId].orderUnitPcs
        item.unitForAmount = hasMultipleDistributors || !item.distributors[distributorId]?.orderUnit
          ? item.unit
          : item.distributors[distributorId].orderUnit
      }
      item.originalDistributors = item.distributors
      let allDistributors = []
      distributors.forEach((distributor) => {
        if (Object.keys(item.distributors).includes(distributor.id)) {
          allDistributors[distributor.id] = item.distributors[distributor.id]
        } else {
          allDistributors[distributor.id] = { customDistributor: distributor.customDistributor, itemNumber: '', name: distributor.name, orderUnit: '', orderUnitPcs: '', qtyPiece: '' }
        }
      })
      item.distributors = allDistributors
      item.decimals = itemDecimals.length > 0 ? itemDecimals[0].decimals : 0
      stockItem = activeStock.find((el) => el.id === item.itemId)
      item.previousTotalAmount = stockItem ? stockItem.amount : 0
      item.totalAmount = totalAmounts.current[index].orderUnitPcs ?
        (Number(totalAmounts.current[index].amount) * Number(totalAmounts.current[index].orderUnitPcs) * Number(totalAmounts.current[index].qtyPiece || 1)).toFixed(totalAmounts.current[index].decimals)
        : totalAmounts.current[index].qtyPiece
        ? (Number(totalAmounts.current[index].amount) * Number(totalAmounts.current[index].qtyPiece)).toFixed(totalAmounts.current[index].decimals)
        : Number(totalAmounts.current[index].amount)
      return item
    })
    dispatch(
      orderInProgressAction({
        orderItems,
        suggestionsUsed: !!user.useSuggestions,
        dateRange: dateRange,
        deliveryDate: [],
        location: selectedLocation,
        delivery: [],
        pickUp: [],
        notes: [],
        isShoppingList: btnClicked === 'send'
      })
    )
    setCurrentOrder(null)
    history.push(hasMultipleDistributors || hasMultipleStockPerDistributor || btnClicked === 'send' ? '/order-distributors' : '/order-summary')
  }

  const getIsDisabled = (values) => {
    const itemsToOrder = values.items.filter((item) => item.amount > 0 && item.included)
    return itemsToOrder.length === 0
  }

  const updateTotalAmounts = (values) => {
    const result = [];
    for (let i = 0; i < values.items.length; i++) {
      const { orderUnitPcs, amount, qtyPiece, distributors, itemNumber, ...rest } = values.items[i]
      const quantityPiece = Object.keys(distributors).length > 1 ? 0 : Number(qtyPiece)
      if ((amount === '') || (parseInt(amount) <= 0)) {
        result.push({ amount: 0, orderUnitPcs, qtyPiece: quantityPiece, decimals: 0, itemNumber })
      } else {
        result.push({ amount: parseInt(amount), orderUnitPcs, qtyPiece: quantityPiece, decimals: quantityPiece.toString().split('.')[1] ? 2 : 0, itemNumber })
      }
    }
    totalAmounts.current = result
  }

  const actionOptions = [
    { key: 1, text: t('send', { ns: 'buttons' }), value: 'send' },
    { key: 2, text: t('create_order', { ns: 'buttons' }), value: 'create order' },
  ]

  return (
    <div className={styles.OrderFormContainer}>
      <div className="btn-container top fixed center-content search">
        <Search
          id="ingredientSearch"
          loading={loading}
          onSearchChange={handleSearchChange}
          results={[]}
          showNoResults={false}
          value={value}
          placeholder={t('search_for_ingredient', { ns: 'orders' })}
        />
      </div>
      <div>
        {error && <Message warning>{t('something_wrong', { ns: 'common' , error })}</Message>}
        { recipesData && recipesData.length && 
          <Accordion info={recipesData} customTitle={true} />
        }
        { !calculatedIngredients && 
          <div className={styles.OrderSuggestions}>
            <div className={styles.SuggestionsToggle}>
              <p className="large">{t('suggestions', { ns: 'orders' })} &nbsp;</p>
              {useSuggestionsToggle}
            </div>
            {user.useSuggestions === true && orderSuggestions && orderSuggestions.length === 0 && (
              <p className={styles.SuggestionsMessage}>{t('suggestions_not_activated', { ns: 'orders' })}</p>
            )}
          </div>
        }
        { props.match.params.location && selectedLocation && (
          calculatedIngredients ?
            <p className="x-large align-center" style={{ margin: '0 auto 12px', maxWidth: '600px' }}>
              {t('based_on_dishes', { ns: 'orders' })}
            </p>
            :
            <p className="x-large align-center" style={{ marginBottom: 12 }}>
              {t('new_order_for', { ns: 'orders' })} <strong>{selectedLocation.name}</strong>
            </p>
        )}
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => saveOrderInProgress(values)}
          validationSchema={Yup.object().shape({
            items: Yup.array().of(
              Yup.object().shape({
                amount: Yup.number().typeError(t('number', { ns: 'validationMessages' })).min(0, t('below_zero', { ns: 'validationMessages' })),
              })
            ),
          })}
          enableReinitialize={true}
        >
          {({ isSubmitting, errors, setFieldValue, values, handleReset, resetForm, submitForm }) => {

            updateTotalAmounts(values)
            
            return (
            <Form autocomplete="off" className="ui form" name={FORM_NAME}>
              {errors.distr && <Label basic color="red" content={errors.stock} style={{ marginBottom: 10 }} />}
              {user.useSuggestions && orderSuggestions && orderSuggestions.length > 0 && (
                <Grid className={styles.OrderDays} stackable={true} textAlign="center">
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <p>{t('show_suggestions', { ns: 'orders' })}</p>
                      <SemanticDatepicker
                        className={styles.DateRange}
                        name="dateRange"
                        placeholder={
                          moment(currentDate).format('DD.MM.YYYY') + '-' + moment(maxDate).format('DD.MM.YYYY')
                        }
                        onChange={async (e, { name, value }) => {
                          if (value && value.length === 2) {
                            setDateRange(value)
                            const suggestionsFilteredByDate = getTotalSuggestionsPerItem(value)
                            setSuggestionsPerItem(suggestionsFilteredByDate)
                            handleReset({ values: initialValues })
                          } else if (!value) {
                            setDateRange([])
                            setSuggestionsPerItem(getTotalSuggestionsPerItem())
                            handleReset({ values: initialValues })
                          }
                        }}
                        minDate={minDate}
                        maxDate={maxDate}
                        format="DD.MM.YYYY"
                        clearOnSameDateClick={false}
                        type="range"
                        value={dateRange.length > 0 ? dateRange : null}
                        clearable={dateRange.length > 0 ? true : false}
                        datePickerOnly={true}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}

              <Grid stackable>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <FormField style={{ paddingLeft: '10px' }}>
                      <Checkbox
                        id="selectAll"
                        label={t('add_all_ingredients', { ns: 'orders' })}
                        onClick={(e) => onSelectAll(e.target, values.items, setFieldValue)}
                      />
                    </FormField>
                  </Grid.Column>
                  <Grid.Column width={8} textAlign="right">
                    <Dropdown
                      placeholder={t('filter_by_distributor', { ns: 'orders' })}
                      clearable
                      id="distributorFilter"
                      selection
                      search
                      options={distributorsFromStock}
                      onChange={handleSearchChange}
                      fluid
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              <FieldArray
                name="items"
                render={() => (
                  <Table striped compact className="order-form-table">
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>{t('ingredient', { ns: 'orders' })}</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">{t('in_stock', { ns: 'orders' })}</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" style={{ paddingLeft: '30px' }}>{t('amount_to_order', { ns: 'orders' })}</Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">{t('you_are_ordering', { ns: 'orders' })}</Table.HeaderCell>
                        <Table.HeaderCell width="1" textAlign="center">
                          {t('order', { ns: 'orders' })}
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {values.items.map((item, index) => {
                        if (!currentResults.length || currentResults.find((ing) => ing.id === item.id)) {
                          return (
                            <OrderItem
                              key={item.id}
                              index={index}
                              item={item}
                              fieldValueSetter={setFieldValue}
                              currentValues={values}
                              totalAmount={totalAmounts.current[index]}
                              selectedLocation={selectedLocation}
                              distributor={distributors.filter(dist => dist.id === item.distributorId)[0]}
                            />
                          )
                        } else {
                          return null
                        }
                      })}
                    </Table.Body>
                  </Table>
                )}
              />
              <div className={cx(styles.ButtonsContainer, 'btn-container bottom fixed floating full-width')}>
                <span className={styles.ButtonWrapper}>
                  <Button
                    className={cx(styles.Button, styles.Orange)}
                    floated="left"
                    type="button"
                    basic
                    color="orange"
                    onClick={() => history.push('/orders')}
                    content={t('cancel', { ns: 'buttons' })}
                  />
                  <Button
                    className={cx(styles.Button, styles.DarkGreen)}
                    loading={isSubmitting}
                    disabled={getIsDisabled(values)}
                    type="submit"
                    color="green"
                    onClick={() => setBtnClicked('create order')}
                    content={t('create_order', { ns: 'buttons' })}
                  />
                </span>
              </div>
              <PersistFormikValues name={FORM_NAME} />
            </Form>
          )}}
        </Formik>
      </div>
    </div>
  )
}

export default OrderForm