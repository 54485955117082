import * as React from 'react'
import { Redirect } from 'react-router-dom'
import { Button, Grid, Segment, Message, Modal, Label } from 'semantic-ui-react'
import styles from '../../assets/styles/modules/costing-calculator/CostingCalculator.module.scss'
import cx from 'classnames'
import { Formik, Form, FieldArray } from 'formik'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Spinner from '../UI/Spinner/Spinner'
import IngredientCostingCalculator from './IngredientCostingCalculator' 
import { getPurchasePriceUnits, getUnitArray, getPurchaseUnits } from '../../shared/utility'
import { setPageTitle, openModal } from '../../store/actions'
import { getUserId } from '../../store/actions/authActions'
import { currencySignsBeforeAmount, flaskAPIGet, flaskAPIPost, calculateUnitCost, calculateCeil } from '../../shared/utility'
import * as Yup from 'yup'
import moment from 'moment'

const CostingCalculator = ({ match }) => {
  const history = useHistory()
  const [initialValues, setInitialValues] = React.useState({})
  const [loading, setLoading] = React.useState(true)
  const [openCancelModal, setOpenCancelModal] = React.useState(false)
  const { user } = useSelector((state) => state.user)
  const { selectedLocation } = useSelector((state) => state.location)
  const { t } = useTranslation(['buttons', 'validationMessages', 'common'])
  const { location, id } = match.params
  const totalCosts = React.useRef([])
  const recipeCost = React.useRef('')
  const servingCost = React.useRef('')
  const [hasError, setHasError] = React.useState(false)
  const dispatch = useDispatch()
  const percent30 = 0.3
  const percent25 = 0.25

  React.useEffect(() => {
    dispatch(setPageTitle('Costing Calculator'))
    const loadData = async () => {
      const userId = await getUserId()
      const costingData = await flaskAPIGet({ endpoint: `/v2/menu/user/${userId}/location/${location}/menu/${id}/cost` })
      const sub_ingredients = costingData.subrecipes ? costingData.subrecipes : []
      const subrecipes = sub_ingredients.map((item) => ({...item, isSubrecipe: true, currency_field: item.currency_field ? item.currency_field : ' ', purchase_unit_field: ' ', purchase_price: -1, purchase_unit_amount: -1 }))
      setInitialValues({...costingData, ingredients: costingData.ingredients.concat(subrecipes)})
      setLoading(false)
    }

    if (!user?.preferredCurrency) {
      dispatch(openModal({ modalType: 'ChangePreferredCurrency', modalProps: {} }))
    } else {
      loadData()
    }    
  }, [dispatch, user])

  const updateTotalCosts = (values) => {
    const result = []
    let recipe_cost = 0
    for (let i = 0; i < values.ingredients?.length; i++) {
      const { purchase_price, purchase_unit_amount, amount, recipe_cost, isSubrecipe, unit_field = null, purchase_unit_field = null, currency_field = null, servings = 1 } = values.ingredients[i]
      if (recipe_cost || isSubrecipe) {
        result.push({ unitCost: '', recipeCost: recipe_cost * servings })
      } else {
        if (purchase_price && purchase_unit_amount && purchase_unit_field) {
          const value = calculateUnitCost(unit_field, purchase_unit_field, purchase_price, purchase_unit_amount)
          result.push({ unitCost: value, recipeCost: value * amount * servings })
        } else {
          result.push({})
        }
      }
    }

    for (let i = 0; i < result.length; i++) {
      recipe_cost += result[i].recipeCost ? result[i].recipeCost : 0
    }

    totalCosts.current = result
    recipeCost.current = recipe_cost
    servingCost.current = initialValues.isBatchItem ? (recipe_cost/initialValues.servings) : recipe_cost
  }

  const validationSchema = Yup.object({
    ingredients: Yup.array().of(
      Yup.object().shape({
        currency_field: Yup.string().required(t('missing_info', { ns: 'validationMessages' })),
        purchase_unit_field: Yup.string().required(t('missing_info', { ns: 'validationMessages' })),
        purchase_price: Yup.number().required(t('missing_info', { ns: 'validationMessages' })),
        purchase_unit_amount: Yup.number().required(t('missing_info', { ns: 'validationMessages' })),
      })
    ),
  })

  const checkDecimals = (num) => (num % 1 != 0) ? 2 : 0

  if (!selectedLocation) return <Redirect to="/locations" />

  if (loading) return <Spinner content={t('please_wait', { ns: 'common' })} />

  return (
    <>
      <Message className={styles.WarningMessage} warning content={'If you want to edit some information, please rotate this device to landscape'} style={{ marginBottom: 10 }} />
      <div className={styles.CostingCalculator}>
        { hasError && <Message error content={'All values need to be filled out to save'} style={{ marginBottom: 10 }} /> }
        <Segment raised className={styles.CostingCalculatorContainer}>
          <Formik
            initialValues={initialValues}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
              try {
                const userId = await getUserId()
                const { isBatchItem, servings, ingredients, ...rest } = values
                // filter subrecipes from ingredients
                const filteredIngredients = ingredients.map((item) => ((!item.isSubrecipe) ? { ...item } : null)).filter(el => el !== null)
                await flaskAPIPost({ endpoint: `/v2/menu/user/${userId}/location/${location}/menu/${id}/cost`, payload: { ingredients: filteredIngredients, ...rest } })
                setSubmitting(false)
                history.push('/menu')
              } catch (error) {
                setErrors({ cost: error.message })
                setSubmitting(false)
              }
            }}
            validationSchema={validationSchema}
          >
          {({ isSubmitting, values, errors, setFieldValue }) => {

            if (!hasError) {
              setHasError(Object.keys(errors).length !== 0 && isSubmitting && !openCancelModal) 
            }
            updateTotalCosts(values)
            
            return (
              <>
                { errors.cost && <Label basic color="red" content={errors.cost} style={{ marginBottom: 10 }} /> }
                <Form autocomplete="off" className="ui form">
                  <Grid>
                    { values.isBatchItem && 
                      <Grid.Row className={styles.BatchItem}>
                        <Grid.Column width={8} textAlign="left">This is a batch menu item!</Grid.Column>
                        <Grid.Column width={8} textAlign="right">{`It makes ${values.servings} ${values.servings === 1 ? 'serving' : 'servings'}`}</Grid.Column>
                      </Grid.Row>
                    }
                    <Grid.Row className={styles.TableHeader}>
                      <Grid.Column width={2} className={styles.LeftAligned}>Ingredient</Grid.Column>
                      <Grid.Column width={4}>Amount for Recipe</Grid.Column>
                      <Grid.Column width={3}>Purchase Price</Grid.Column>
                      <Grid.Column width={3}>Purchase Unit</Grid.Column>
                      <Grid.Column width={2}>Unit Cost</Grid.Column>
                      <Grid.Column width={2}>Recipe Cost</Grid.Column>
                    </Grid.Row>
                    { values.ingredients &&
                      <FieldArray
                        name="ingredients"
                        render={(arrayHelpers) => (
                          <>
                            {Object.values(values.ingredients).map((ingredient, index) => {
                              const purchasePriceUnitsArr = getUnitArray(getPurchasePriceUnits(ingredient))
                              const purchaseUnitsArr = getUnitArray(getPurchaseUnits(ingredient))
                              return (
                                <IngredientCostingCalculator
                                  key={`Ingredient-${index}`}
                                  index={index}
                                  fieldArrayHelper={arrayHelpers}
                                  fieldValueSetter={setFieldValue}
                                  ingredient={ingredient}
                                  ingredients={values.ingredients}
                                  purchasePriceUnitsArr={purchasePriceUnitsArr}
                                  purchaseUnitsArr={purchaseUnitsArr}
                                  totalCosts={totalCosts.current.length && totalCosts.current[index]}
                                  preferredCurrency={user.preferredCurrency}
                                />
                              )
                            })}
                          </>
                        )}
                      />
                    }
                    <Grid.Row>
                      <Grid.Column width={16}><span className={styles.Separator}></span></Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={14} textAlign="right"><strong className={styles.Text}>Recipe Cost</strong></Grid.Column>
                      <Grid.Column width={2} textAlign="center">
                        <span className={currencySignsBeforeAmount.includes(user.preferredCurrency) ? styles.ChangeSignOrder : ''}>
                          <span>{recipeCost.current && calculateCeil(recipeCost.current, user.preferredCurrency).toFixed(checkDecimals(calculateCeil(recipeCost.current, user.preferredCurrency)))}</span>&nbsp;
                          <span>{user.preferredCurrency}</span>
                        </span>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={14} textAlign="right"><strong className={styles.Text}>Serving Cost</strong></Grid.Column>
                      <Grid.Column width={2} textAlign="center">
                        <span className={currencySignsBeforeAmount.includes(user.preferredCurrency) ? styles.ChangeSignOrder : ''}>
                          <span>{servingCost.current && calculateCeil(servingCost.current, user.preferredCurrency).toFixed(checkDecimals(calculateCeil(servingCost.current, user.preferredCurrency)))}</span>&nbsp;
                          <span>{user.preferredCurrency}</span>
                        </span>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={6} textAlign="left" style={{ alignSelf: 'center' }}><p className={styles.Text}>Date Calculated: <span>{moment(values.dateCalculated).format('MMMM DD, YYYY')}</span></p></Grid.Column>
                      <Grid.Column width={6} textAlign="right"><strong className={styles.Text}>Suggested Menu Cost</strong><p className={styles.Subtitle}>Ingredient Cost (30% to 25%)</p></Grid.Column>
                      <Grid.Column width={4} textAlign="center" style={{ alignSelf: 'center', justifyContent: 'space-between', display: 'flex', margin: '0 auto', padding: '0 25px' }}>
                        <span className={currencySignsBeforeAmount.includes(user.preferredCurrency) ? styles.ChangeSignOrder : ''}>
                          <span>{(calculateCeil(servingCost.current/percent30, user.preferredCurrency)).toFixed(checkDecimals(calculateCeil(servingCost.current/percent30, user.preferredCurrency)))}</span>&nbsp;
                          <span>{user.preferredCurrency}</span>
                        </span>
                        <span>to</span>
                        <span className={currencySignsBeforeAmount.includes(user.preferredCurrency) ? styles.ChangeSignOrder : ''}>
                          <span>{(calculateCeil(servingCost.current/percent25, user.preferredCurrency)).toFixed(checkDecimals(calculateCeil(servingCost.current/percent25, user.preferredCurrency)))}</span>&nbsp;
                          <span>{user.preferredCurrency}</span>
                        </span>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <div className={cx(styles.ButtonsContainer)}>
                    <span className={styles.ButtonWrapper}>
                      <Button
                        className={cx(styles.Button, styles.Orange)}
                        basic
                        color="orange"
                        content={t('cancel', { ns: 'buttons' })}
                        onClick={() => setOpenCancelModal(true)}
                      />
                      <Button
                        className={cx(styles.Button, styles.Green)}
                        loading={isSubmitting}
                        disabled={isSubmitting}
                        type="submit"
                        color="green"
                        content={t('save', { ns: 'buttons' })}
                      />
                    </span>
                  </div>
                </Form>
              </>
            )
          }}
          </Formik>
        </Segment>
        <Modal
          open={openCancelModal}
          onClose={() => {
            setOpenCancelModal(false)
          }}
          className={cx(styles.Modal, styles.Container)}
        >
          <Modal.Content>
            <Modal.Description>
              <p className={cx(styles.Modal, styles.Description)}>
                {t('sure_to_cancel', { ns: 'common' })}
              </p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button 
              className={cx(styles.Button, styles.Basic, styles.Modal)}
              onClick={() => { setOpenCancelModal(false) }}
              basic
              type="button"
            >
              {t('back', { ns: 'buttons' })}
            </Button>
            <Button 
              onClick={() => {
                setOpenCancelModal(false)
                history.push(`/menu/view/${id}/location/${location}`)
              }}
              className={cx(styles.Button, styles.Orange, styles.Modal)}
              basic
              color="orange"
              type="button"
            >
              {t('cancel', { ns: 'buttons' })}
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    </>
  )
}

export default CostingCalculator
