import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Prompt } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import StockItem from './StockItem'
import { asyncActionError, setPageTitle, updateStock } from '../../../store/actions'
import { httpsCallable } from 'firebase/functions'
import { cloudFunctions } from '../../../config/firebase'
import { changeSorting } from '../../../store/actions/sortingActions'
import { Button, Message, Search, Table, Modal } from 'semantic-ui-react'
import Spinner from '../../UI/Spinner/Spinner'
import SortingField from '../../UI/Sorting/SortingField'
import { Form, Formik, FieldArray } from 'formik'
import useSearchCallback from '../../../hooks/useSearchCallback'
import * as Yup from 'yup'
import styles from '../../../assets/styles/modules/stock/EditStock.module.scss'
import GreenbytesModal from '../../UI/GreenbytesModal/GreenbytesModal'
import cx from 'classnames'

// This component is unreacheable now
const EditStock = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation(['validationMessages', 'common', 'stock'])
  const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false)
  const [openCancelModal, setOpenCancelModal] = React.useState(false)

  const { stock } = useSelector((state) => state.stock)
  const { loading, error } = useSelector((state) => state.async)
  const { value, searchResults } = useSelector((state) => state.search)
  const { data, sortByColumn, direction } = useSelector((state) => state.sorting)
  const { selectedLocation } = useSelector((state) => state.location)

  React.useEffect(() => {
    dispatch(setPageTitle(t('update', { ns: 'stock' })))
  }, [dispatch])

  const handleSearchChange = useSearchCallback(stock)

  if (loading) return <Spinner content={t('please_wait', { ns: 'common' })} />

  const initialValues = {
    items: stock.map((item) => ({
      id: item.id,
      name: item.name,
      itemNumber: item.itemNumber ? item.itemNumber : '',
      amount: item.amount,
      isArchived: item.isArchived ? item.isArchived : false,
      hasMultipleDistributors: !!item.distributors,
    })),
  }

  Yup.addMethod(Yup.array, 'unique', function (message) {
    return this.test('unique', message, function (list) {
      const mapper = (x) => x.name
      const set = [...new Set(list.map(mapper))]
      const isUnique = list.length === set.length
      if (isUnique) {
        return true
      }
      const idx = list.findIndex((l, i) => mapper(l) !== set[i])
      return this.createError({
        path: `items.[${idx}].name`,
        message: message,
      })
    })
  })

  const handleSortBy = (columnName) => {
    const newDirection = sortByColumn === columnName ? (direction && direction === 'asc' ? 'desc' : 'asc') : 'asc'
    dispatch(changeSorting(columnName, newDirection))
  }

  return (
    <>
      <div>
        <Search
          id="ingredientSearch"
          loading={loading}
          onSearchChange={handleSearchChange}
          results={[]}
          showNoResults={false}
          value={value}
          placeholder={t('search_for_ingredient', { ns: 'stock' })}
          className={styles.IngredientSearch}
        />
      </div>
      {error && <Message>{t('something_wrong', { ns: 'common' , error })}</Message>}
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          setSubmitting(true)
          try {
            const res = await httpsCallable(
              cloudFunctions,
              'updateStockItems'
            )({
              values,
              initialValues,
              locationId: props.match.params.location,
            })
            dispatch(updateStock(res.data, selectedLocation.id))
            history.push('/stock')
          } catch (error) {
            setErrors({ stock: error.message })
            setSubmitting(false)
            dispatch(asyncActionError(error, 'Stock items could not be updated.', `updateStockItems: ${error.message}`))
          }
        }}
        validationSchema={Yup.object().shape({
          items: Yup.array()
            .of(
              Yup.object().shape({
                name: Yup.string().required(t('item_name', { ns: 'validationMessages' })),
                amount: Yup.number()
                  .typeError(t('invalid_number', { ns: 'validationMessages' }))
                  .min(0, t('below_zero', { ns: 'validationMessages' }))
                  .required(t('amount', { ns: 'validationMessages' })),
              })
            )
            .unique(t('item_name_exists', { ns: 'validationMessages' })),
        })}
        enableReinitialize={true}
      >
        {({ isSubmitting, errors, dirty, setFieldValue, values, isValid, handleReset }) => (
          <>
            {errors.stock && <Message error content={errors.stock} style={{ marginBottom: 10 }} />}
            <span className={styles.Items}>{t('items', { ns: 'stock', amount: stock.length })}</span>
            <Form autocomplete="off" className={cx(styles.EditStockContainer, 'ui form')}>
              <FieldArray name="items">
                <table>
                  <th
                    sorted={sortByColumn === 'name' ? (direction === 'asc' ? 'ascending' : 'descending') : null}
                    onClick={() => handleSortBy('name')}
                  >
                    <SortingField
                      title={t('item', { ns: 'stock' })}
                      fieldName="name"
                      direction={direction === 'asc' ? 'ascending' : 'descending'}
                      active={sortByColumn === 'name' ? true : false}
                    />
                  </th>
                  <th className={styles.FixedWidth}>{t('amount_in_stock', { ns: 'stock' })}</th>
                  {data.map((item, index) => {
                    if (
                      stock[index].isArchived ||
                      (searchResults.length && !searchResults.find((ing) => ing.id === item.id))
                    ) {
                      return null
                    } else {
                      return (
                        <StockItem
                          key={index}
                          index={index}
                          item={item}
                          unit={stock[index].unit}
                          fieldValueSetter={setFieldValue}
                          currentValues={values}
                        />
                      )
                    }
                  })}
                </table>
              </FieldArray>
              <div className={cx(styles.ButtonsContainer, 'btn-container bottom fixed floating full-width')}>
                <span className={styles.ButtonWrapper}>
                  <Button
                    className={cx(styles.Button, styles.Orange)}
                    disabled={isSubmitting}
                    type="button"
                    basic
                    color="orange"
                    content={t('cancel', { ns: 'buttons' })}
                    size="big"
                    onClick={() => setOpenCancelModal(true)}
                  />
                  <Button
                    className={cx(styles.Button, styles.Green)}
                    loading={isSubmitting}
                    onClick={() => {
                      setConfirmDeleteOpen(!isValid)
                    }}
                    type="submit"
                    color="green"
                    content={t('save', { ns: 'buttons' })}
                    size="big"
                  />
                </span>
              </div>
              <Prompt
                when={dirty && !isSubmitting}
                message={t('leave_without_saving', { ns: 'stock' })}
              />
            </Form>
            <Modal
              open={openCancelModal}
              onClose={() => {
                setOpenCancelModal(false)
              }}
              className={cx(styles.Modal, styles.Container)}
            >
              <Modal.Content>
                <Modal.Description>
                  <p className={cx(styles.Modal, styles.Description)}>
                    {t('sure_to_cancel', { ns: 'common' })}
                  </p>
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                <Button 
                  className={cx(styles.Button, styles.Basic, styles.Modal)}
                  onClick={() => { setOpenCancelModal(false) }}
                  basic
                  type="button"
                >
                  {t('back', { ns: 'buttons' })}
                </Button>
                <Button 
                  onClick={async () => {
                      await handleReset()
                      history.push('/stock')
                      setOpenCancelModal(false)
                  }}
                  className={cx(styles.Button, styles.Orange, styles.Modal)}
                  basic
                  color="orange"
                  type="button"
                >
                  {t('cancel', { ns: 'buttons' })}
                </Button>
              </Modal.Actions>
            </Modal>
          </>
        )}
      </Formik>
      <GreenbytesModal
        confirmDeleteOpen={confirmDeleteOpen}
        setConfirmDeleteOpen={setConfirmDeleteOpen}
        text={t('invalid_items', { ns: 'stock' })}
        confirmButtonText={'ok'}
      />
    </>
  )
}

export default EditStock
