import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styles from '../../assets/styles/modules/Home.module.scss'
import { setPageTitle } from '../../store/actions'
import useFetchLocation from '../../hooks/useFetchLocation'
import Distributors from './Task/Distributors'
import StockItems from './Task/StockItems'
import Menu from './Task/Menu'
import Orders from './Task/Orders'
import Modal from './Modal/Modal'
import { Grid, Button } from 'semantic-ui-react'
import OpenOrders from './Notifications/OpenOrders'
import { configurePushNotifications } from '../../config/firebase'
import cx from 'classnames'

const Home = () => {
  const dispatch = useDispatch()
  const { currentUser } = useSelector((state) => state.auth)
  const { loading } = useSelector((state) => state.async)
  const { fetchLocations } = useFetchLocation()
  const { t } = useTranslation(['home'])
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const unsubscribe = () => dispatch(setPageTitle(''))
    return () => {
      unsubscribe()
    }
  })

  useEffect(() => {
    fetchLocations(dispatch)
  }, [dispatch])

  useEffect(() => {
    configurePushNotifications()
  }, [])

  return (
    <>
      <Modal open={open} setOpen={setOpen} viewportWidth={window.innerWidth} />
      <div className={styles.Home}>
        <h2 className={styles.Greeting}>{t('title', { name: currentUser.displayName, ns: 'home' })}</h2>

        <OpenOrders />

        <Grid className={styles.TaskList} stackable={true}>
          <Grid.Row>
            <Grid.Column width={3} className={styles.TaskContainer}>
              <Distributors />
            </Grid.Column>
            <Grid.Column width={1} className={styles.VerticalMobileLine}>
              <hr />
              <div className={styles.VL} />
            </Grid.Column>
            <Grid.Column width={3} className={styles.TaskContainer}>
              <StockItems />
            </Grid.Column>
            <Grid.Column width={1} className={styles.VerticalMobileLine}>
              <hr />
              <div className={styles.VL} />
            </Grid.Column>
            <Grid.Column width={3} className={styles.TaskContainer}>
              <Menu />
            </Grid.Column>
            <Grid.Column width={1} className={styles.VerticalMobileLine}>
              <hr />
              <div className={styles.VL} />
            </Grid.Column>
            <Grid.Column width={3} className={styles.TaskContainer}>
              <Orders />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Button
          className={cx(styles.Button, styles.RequestDemo, styles.Help)}
          onClick={() => {
            setOpen(true)
          }}
        >
          Click here for tutorials
        </Button>
      </div>
    </>
  )
}

export default Home
