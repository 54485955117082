import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Button, Grid, Modal, Label } from 'semantic-ui-react'
import styles from '../../../assets/styles/modules/stock/StockItemDetails.module.scss'
import { asyncActionStart, asyncActionError, asyncActionFinish } from '../../../store/actions'
import { httpsCallable } from 'firebase/functions'
import { updateStock } from '../../../store/actions/stockActions'
import { cloudFunctions } from '../../../config/firebase'
import Spinner from '../../UI/Spinner/Spinner'
import cx from 'classnames'

const StockItemDetails = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { stock } = useSelector((state) => state.stock)
  const { t } = useTranslation(['stock', 'buttons'])
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)
  const [detailError, setDetailError] = useState({})
  const [itemDetail, setItemDetail] = useState({})
  const [loading, setLoading] = useState(false)
  const [item, setItem] = useState(history?.location?.state?.item)
  const selectedLocation = history?.location?.state?.selectedLocation

  const archiveStockItemHandler = async (currentItem, selectedLocation) => {
    setConfirmDeleteOpen(false)
    setLoading(true)
    try {
      dispatch(asyncActionStart())
      const res = await httpsCallable(
        cloudFunctions,
        'archiveStockItem'
      )({
        id: currentItem.id,
        locationId: selectedLocation ? selectedLocation.id : null,
      })
      let archivedStockItem = []
      archivedStockItem.push(res.data)
      const newStock = stock.map(obj => archivedStockItem.find(o => o.id === obj.id) || obj)
      setItem(res.data)
      dispatch(updateStock(newStock, selectedLocation.id))
      dispatch(asyncActionFinish())
      setItemDetail({ message: `"${currentItem.name}" was archived.` })
      setLoading(false)
    } catch (error) {
      dispatch(
        asyncActionError(
          error,
          `"${currentItem.name}" could not be archived.`,
          `archiveStockItemHandler: ${error.message}`
        )
      )
      setDetailError({ message: error.message })
      setLoading(false)
    }
  }

  const restoreStockItemHandler = async (currentItem, selectedLocation) => {
    setConfirmDeleteOpen(false)
    setLoading(true)
    try {
      dispatch(asyncActionStart())
      const res = await httpsCallable(
        cloudFunctions,
        'restoreStockItem'
      )({
        id: currentItem.id,
        locationId: selectedLocation ? selectedLocation.id : null,
      })
      let restoredStockItem = []
      restoredStockItem.push(res.data)
      const newStock = stock.map(obj => restoredStockItem.find(o => o.id === obj.id) || obj)
      setItem(res.data)
      dispatch(updateStock(newStock, selectedLocation.id))
      dispatch(asyncActionFinish())
      setItemDetail({ message: `"${currentItem.name}" was restored.` })
      setLoading(false)
    } catch (error) {
      dispatch(
        asyncActionError(error, t('could_not_be_restored', { ns: 'stock' }), `restoreStockItemHandler: ${error.message}`)
      )
      setDetailError({ message: error.message })
      setLoading(false)
    }
  }

  const deleteStockItemHandler = async (currentItem, selectedLocation) => {
    setConfirmDeleteOpen(false)
    setLoading(true)
    try {
      dispatch(asyncActionStart())
      const res = await httpsCallable(
        cloudFunctions,
        'deleteStockItem'
      )({
        id: currentItem.id,
        locationId: selectedLocation ? selectedLocation.id : null,
      })
      let deletedStockItem = []
      deletedStockItem.push(res.data)
      const newStock = stock.map(obj => deletedStockItem.find(o => o.id === obj.id) || obj)
      setItem(res.data)
      dispatch(updateStock(newStock, selectedLocation.id))
      dispatch(asyncActionFinish(t('was_deleted', { ns: 'stock', item: currentItem.name })))
      setItemDetail({ message: t('was_deleted', { ns: 'stock', item: currentItem.name }) })
      setLoading(false)
    } catch (error) {
      dispatch(
        asyncActionError(error, `"${currentItem.name}" could not be deleted.`, `deleteStockItem: ${error.message}`)
      )
      setDetailError({ message: error.message })
      setLoading(false)
    }
  }

  if (loading) return <Spinner content={t('please_wait', { ns: 'common' })} />

  return (
    <>
      { itemDetail.message && <Label basic color="orange" content={itemDetail.message} style={{ marginBottom: 10, width: '100%', fontSize: '14px' }} /> }
      { detailError.message && <Label basic color="red" content={detailError.message} style={{ marginBottom: 10, width: '100%', fontSize: '14px' }} /> }
      <Grid className={styles.StockItemDetails}>
        <Grid.Row>
            <Grid.Column width={16} className={styles.Title}>
              Stock Item
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column width={5} className={styles.Tag}>
              Item Name
            </Grid.Column>
            <Grid.Column width={11}>
              {item?.name}
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column width={5} className={styles.Tag}>
              Amount last recorded
            </Grid.Column>
            <Grid.Column width={11}>
              {item?.amount}
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column width={5} className={styles.Tag}>
              Recorded Unit
            </Grid.Column>
            <Grid.Column width={11}>
              {item.unit}
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column width={16} className={styles.Title}>
              Distributors
            </Grid.Column>
        </Grid.Row>
        {item?.onboarding?.map((distributor, i) => (
          <>
            <Grid.Row className={styles.DistributorsTitle}>
                <Grid.Column width={5}>
                  Distributor
                </Grid.Column>
                <Grid.Column width={11}>
                  Product Name
                </Grid.Column>
            </Grid.Row>
            <Grid.Row className={styles.DistributorsDescription}>
                <Grid.Column width={5}>
                  {distributor.distributor_name}
                </Grid.Column>
                <Grid.Column width={11}>
                  {distributor.id_product_onboarding ? distributor.id_product_onboarding : distributor.typed_product ? distributor.typed_product : distributor.generic_name}
                </Grid.Column>
            </Grid.Row>
          </>
        ))}
        <div className={cx(styles.ButtonsContainer, 'btn-container bottom fixed floating full-width')}>
          <span className={styles.ButtonWrapper}>
            <Button
              className={cx(styles.Button, styles.Orange)}
              type="button"
              basic
              color="orange"
              content={ item?.isArchived ? t('permanently_delete', { ns: 'buttons' }) : t('delete', { ns: 'buttons' }) }
              size="big"
              onClick={() => setConfirmDeleteOpen(true)}
            />
            { item?.isArchived ?
              <Button
                className={cx(styles.Button, styles.Green)}
                onClick={async () => {
                  restoreStockItemHandler(item, selectedLocation)
                }}
                type="submit"
                color="green"
                content={t('restore', { ns: 'buttons' })}
                size="big"
              />
              :
              <Button
                className={cx(styles.Button, styles.Green)}
                onClick={() => { history.push('/stock/edit/item', { item, selectedLocation }) }}
                type="submit"
                color="green"
                content={t('edit', { ns: 'buttons' })}
                size="big"
              />
            }
          </span>
        </div>
      </Grid>
      <Modal
        open={confirmDeleteOpen}
        onClose={() => {
          setConfirmDeleteOpen(false)
        }}
        className={cx(styles.Modal, styles.Container)}
      >
        <Modal.Content>
          <Modal.Description>
            <p className={cx(styles.Modal, styles.Description)}>
              { item?.isArchived ?
                `Are you sure you want to delete this item?`
                :
                `Do you want to delete or archive this item?`
              }
            </p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button 
            className={cx(styles.Button, styles.Basic, styles.Modal)}
            onClick={() => { setConfirmDeleteOpen(false) }}
            basic
            type="button"
          >
            {t('cancel', { ns: 'buttons' })}
          </Button>
          { !item?.isArchived &&
            <Button 
              onClick={async () => {
                archiveStockItemHandler(item, selectedLocation)
              }}
              className={cx(styles.Button, styles.Orange, styles.Modal)}
              basic
              color="orange"
              type="button"
            >
              {t('archive', { ns: 'buttons' })}
            </Button>
          }
          <Button 
            onClick={async () => {
              deleteStockItemHandler(item, selectedLocation)
            }}
            className={cx(styles.Button, styles.Orange, styles.Modal)}
            basic
            color="orange"
            type="button"
          >
            {t('delete', { ns: 'buttons' })}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default StockItemDetails
