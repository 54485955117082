import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Link from '../UI/Link/Link'
import { setPageTitle } from '../../store/actions'
import styles from '../../assets/styles/modules/shoppingList/shoppingList.module.scss'

const ShoppingListFirstStep = () => {
  const dispatch = useDispatch()
  const { selectedLocation } = useSelector((state) => state.location)
  const { t } = useTranslation(['orders'])
  
  useEffect(() => {
    dispatch(setPageTitle(t('shopping_list', { ns: 'orders' })))
  }, [])

  if (!selectedLocation) {
    return <Redirect to="/locations" />
  }

  return (
    <>
      <Link text={t('build_your_own_shopping_list', { ns: 'orders' })} url={`/shopping-list/create/location/${selectedLocation.id}`} />
      <p className={styles.Text}>{t('what_you_should_order', { ns: 'orders' })}</p>
      <Link text={t('smart_shopping_list_generator', { ns: 'orders' })}  url={'/shopping-list'} />
    </>
  )
}

export default ShoppingListFirstStep