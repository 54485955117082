import React from 'react'
import { useTranslation } from 'react-i18next'
import TextInput from '../../UI/Form/TextInput'
import styles from '../../../assets/styles/modules/stock/StockItem.module.scss'
import cx from 'classnames'

const StockItem = ({ item, index }) => {
  const { t } = useTranslation(['stock'])

  return (
    <tr>
      <td>
        {item.name}
      </td>
      <td className={styles.FixedWidth}>
        <TextInput className={cx(styles.Input, styles.Amount)} name={`ingredients.${index}.amount`} placeholder={t('amount', { ns: 'menu' })} type="number" inputmode="decimal" />
        <span className={styles.Unit}>{item.unit}</span>
      </td>
    </tr>
  )
}

export default StockItem
