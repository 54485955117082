import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Redirect } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import { updateStock } from '../../store/actions/stockActions'
import { getUserProfileFromFirestore, listenToUserProfile } from '../../store/actions/userActions'
import useFirestoreDoc from '../../hooks/useFirestoreDoc'
import useFetchLocation from '../../hooks/useFetchLocation'
import { setPageTitle, asyncActionStart, asyncActionError, asyncActionFinish, updateDistributors } from '../../store/actions'
import { setSortingData, changeSorting } from '../../store/actions/sortingActions'
import useSearchCallback from '../../hooks/useSearchCallback'
import { Button, Checkbox, Message, Search, Table, Grid } from 'semantic-ui-react'
import Spinner from '../UI/Spinner/Spinner'
import SortingField from '../UI/Sorting/SortingField'
import GreenbytesModal from '../UI/GreenbytesModal/GreenbytesModal'
import StockItem from './StockItem/StockItem'
import PortalPopup from '../UI/Portal/PortalPopup'
import { cloudFunctions } from '../../config/firebase'
import { getUserId } from '../../store/actions/authActions'
import { httpsCallable } from 'firebase/functions'
import styles from '../../assets/styles/modules/stock/Stock.module.scss'
import cx from 'classnames'
import { flaskAPIGet } from '../../shared/utility'
import moment from 'moment'

const Stock = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation(['stock', 'buttons', 'common'])
  const { fetchLocations } = useFetchLocation()
  const target = history.location?.state?.target || localStorage.getItem('target')

  const { user } = useSelector((state) => state.user)
  const { stock, lastLocationId } = useSelector((state) => state.stock)
  const { selectedLocation, locationArray, locations } = useSelector((state) => state.location)
  const { loading, error, success } = useSelector((state) => state.async)
  const { value, searchResults } = useSelector((state) => state.search)
  const { data, sortByColumn, direction } = useSelector((state) => state.sorting)
  const { distributors } = useSelector((state) => state.distributor)

  const [confirmArchiveOpen, setConfirmArchiveOpen] = React.useState(null)
  const [itemForAction, setItemForAction] = React.useState({})
  const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(null)
  const [showArchived, setShowArchived] = React.useState(false)
  const [portalOpen, setPortalOpen] = React.useState(false)
  const [portalMessage, setPortalMessage] = React.useState('')
  const [firstLoad, setFirstLoad] = React.useState(true)
  const [firstLoadDistributors, setFirstLoadDistributors] = React.useState(true)
  

  React.useEffect(() => {
    dispatch(setPageTitle(t('stock')))
    fetchLocations(dispatch)
  }, [dispatch])

  React.useEffect(() => {
    const timer = setTimeout(() => {
      const element = document.getElementById(target)
      if (!element) { return }
      element.scrollIntoView({ block: 'center', behavior: 'smooth' })
      history.replace({state: {}})
      localStorage.removeItem('target')
    }, 500);

    return () => clearTimeout(timer)
  }, [history, target])

  React.useEffect(() => {
    const loadStock = async () => {
      const userId = await getUserId()
      try {
        dispatch(asyncActionStart())
        const stock = await flaskAPIGet({ endpoint: `/v2/stock/user/${userId}/location/${selectedLocation.id}/stock` })
        dispatch(updateStock(stock, selectedLocation.id))
      } catch (error) {
        dispatch(asyncActionError(error, ''))
      }
      setFirstLoad(false)
    }

    const loadDistributors = async () => {
      const userId = await getUserId()
      const distributorsData = await flaskAPIGet({ endpoint: `/v2/distributors/user/${userId}?mode=user` })
      dispatch(updateDistributors(distributorsData))
      setFirstLoadDistributors(false)
    }

    if (selectedLocation && firstLoad && (stock?.length === 0 || lastLocationId !== selectedLocation.id)) {
      loadStock()
    }

    if (firstLoadDistributors && !distributors.length) {
      loadDistributors()
    }
    
    if (stock?.length) {
      dispatch(setSortingData([...stock], 'name'))
      dispatch(changeSorting('name', 'asc'))
    }
  }, [dispatch, selectedLocation, stock])

  useFirestoreDoc({
    query: () => getUserProfileFromFirestore(),
    data: (user) => dispatch(listenToUserProfile(user)),
    deps: [dispatch],
  })

  const handleSearchChange = useSearchCallback(stock)

  if (loading || !user) return <Spinner content={t('please_wait', { ns: 'common' })} />

  const handleSortBy = (columnName) => {
    const newDirection = sortByColumn === columnName ? (direction && direction === 'asc' ? 'desc' : 'asc') : 'asc'
    dispatch(changeSorting(columnName, newDirection))
  }

  const handleArchiveClick = (itemId, itemName) => {
    setItemForAction({ id: itemId, name: itemName })
    setConfirmArchiveOpen(true)
  }

  const handleDeleteClick = (itemId, itemName) => {
    setItemForAction({ id: itemId, name: itemName })
    setConfirmDeleteOpen(true)
  }

  const archiveStockItemHandler = async () => {
    setConfirmArchiveOpen(false)
    try {
      dispatch(asyncActionStart())
      const res = await httpsCallable(
        cloudFunctions,
        'archiveStockItem'
      )({
        id: itemForAction.id,
        locationId: selectedLocation ? selectedLocation.id : null,
      })
      let archivedStockItem = []
      archivedStockItem.push(res.data)
      const newStock = stock.map(obj => archivedStockItem.find(o => o.id === obj.id) || obj)
      dispatch(updateStock(newStock, selectedLocation.id))
      dispatch(asyncActionFinish())
      setPortalMessage(`"${itemForAction.name}" was archived.`)
      setPortalOpen(true)
    } catch (error) {
      dispatch(
        asyncActionError(
          error,
          `"${itemForAction.name}" could not be archived.`,
          `archiveStockItemHandler: ${error.message}`
        )
      )
      setPortalMessage(error.message)
      setPortalOpen(true)
    }
  }

  const restoreStockItemHandler = async (itemId) => {
    try {
      dispatch(asyncActionStart())
      const res = await httpsCallable(
        cloudFunctions,
        'restoreStockItem'
      )({
        id: itemId,
        locationId: selectedLocation ? selectedLocation.id : null,
      })
      let restoredStockItem = []
      restoredStockItem.push(res.data)
      const newStock = stock.map(obj => restoredStockItem.find(o => o.id === obj.id) || obj)
      dispatch(updateStock(newStock, selectedLocation.id))
      dispatch(asyncActionFinish())
      setPortalMessage(t('item_was_restored', { ns: 'stock' }))
      setPortalOpen(true)
    } catch (error) {
      dispatch(
        asyncActionError(error, t('could_not_be_restored', { ns: 'stock' }), `restoreStockItemHandler: ${error.message}`)
      )
      setPortalMessage(error.message)
      setPortalOpen(true)
    }
  }

  const deleteStockItemHandler = async () => {
    setConfirmDeleteOpen(false)
    try {
      dispatch(asyncActionStart())
      const res = await httpsCallable(
        cloudFunctions,
        'deleteStockItem'
      )({
        id: itemForAction.id,
        locationId: selectedLocation ? selectedLocation.id : null,
      })
      let deletedStockItem = []
      deletedStockItem.push(res.data)
      const newStock = stock.map(obj => deletedStockItem.find(o => o.id === obj.id) || obj)
      dispatch(updateStock(newStock, selectedLocation.id))
      dispatch(asyncActionFinish(t('was_deleted', { ns: 'stock', item: itemForAction.name })))
      setPortalMessage(t('was_deleted', { ns: 'stock', item: itemForAction.name }))
      setPortalOpen(true)
    } catch (error) {
      dispatch(
        asyncActionError(error, `"${itemForAction.name}" could not be deleted.`, `archiveStockItem: ${error.message}`)
      )
      setPortalMessage(error.message)
      setPortalOpen(true)
    }
  }

  const handleCancelAction = () => {
    setItemForAction({})
    setConfirmArchiveOpen(false)
    setConfirmDeleteOpen(false)
  }

  const handleClosePortal = () => {
    setPortalOpen(false)
  }

  const activeLocations = () => (
    locationArray.map(loc => {
      const found = locations.find(element => element.id === loc.id && element.isActive);
      return found ? {...loc} : null
    }).filter(el => el !== null)
  )

  if (!selectedLocation) {
    return <Redirect to={{ pathname: "/locations", state: { previousPage: 'stock' }}} />
  }

  return (
    <>
      <div>
        <Search
          id="ingredientSearch"
          loading={loading}
          onSearchChange={handleSearchChange}
          results={[]}
          showNoResults={false}
          value={value}
          placeholder={t('search_for_ingredient')}
          className={styles.IngredientSearch}
        />
      </div>
      {error && <Message error>{error}</Message>}
      {success && <Message success content={success} />}
      <div className={styles.StockList}>
        <span className={styles.Items}>{t('items', { amount: stock.filter((item) => (!showArchived ? ((item.isArchived !== true) && (item.deleted !== true)) : true)).length })}</span>
        <Grid className={styles.TopInputsGrid}>
          <Grid.Row className={styles.TopInputsRow}>
            <Grid.Column verticalAlign="middle" className={styles.TopInputsColumn}>
              <Checkbox
                label={t('show_archived')}
                onChange={() => setShowArchived(!showArchived)}
                style={{ marginRight: '10px' }}
                checked={showArchived}
                className={styles.ArchivedCheckbox}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {stock.length === 0 ? (
          <Message>
            <Trans
                t={t}
                ns={'stock'}
                i18nKey="add_new_item_to_get_started"
            >
              <Message.Content className="align-center">
                Hmm... looks like nothing's here.
                <br />
                <Button
                  style={{ margin: '10px 0' }}
                  color="green"
                  basic
                  onClick={() => history.push('/stock/add', { selectedLocation, locations })}>
                    Add New Item
                </Button>
                <br />
                to get started!
              </Message.Content>
            </Trans>
          </Message>
        ) : (
          selectedLocation == null && (
            <Message>
              <Message.Content className="align-center">{t('select_a_location', { ns: 'stock' })}</Message.Content>
            </Message>
          )
        )}

        {selectedLocation && data.length > 0 && (
          <table>
            <tr>
              <th
                sorted={sortByColumn === 'name' ? (direction === 'asc' ? 'ascending' : 'descending') : null}
                onClick={() => handleSortBy('name')}
              >
                <SortingField
                  title={t('item')}
                  fieldName="name"
                  direction={direction === 'asc' ? 'ascending' : 'descending'}
                  active={sortByColumn === 'name' ? true : false}
                />
              </th>
              <th className={styles.Center}><span>{locations.length > 0 && !selectedLocation ? t('locations') : t('amount_in_stock')}</span></th>
              <th className={cx(styles.Right, styles.SmallerFontSize)}><span>{`Last Updated: ${moment(new Date()).format('MMMM DD, YYYY')}`}</span></th>
            </tr>           
            {data.map((item) => {
              if ((searchResults.length || value !== '') && !searchResults.find((ing) => ing.id === item.id)) {
                return null
              } else {
                if ((!item.isArchived || (item.isArchived && showArchived)) && (!item.deleted)) {
                  return (
                    <StockItem
                      key={item.id}
                      item={item}
                      selectedLocation={selectedLocation}
                      hasLocations={locations.length > 0}
                      locations={locations.length > 0 ? locationArray.filter((i) => i.id !== 0) : []}
                      onArchiveClick={handleArchiveClick}
                      onDeleteClick={handleDeleteClick}
                      onRestoreClick={restoreStockItemHandler}
                      locationsUnfiltered={locations}
                    />
                  )
                }
                return null
              }
            })}
            <div className={cx(styles.ButtonsContainer, 'btn-container bottom fixed floating full-width')}>
              <span className={stock.length > 0 && (!locations.length > 0 || selectedLocation) ? styles.ButtonWrapper : styles.ButtonWrapperCentered}>
              <Button
                className={cx(styles.Button, styles.Green)}
                color="green"
                basic
                size="big"
                onClick={() => history.push('/stock/add', { selectedLocation, locations })}
              >
                {t('new_stock_item', { ns: 'buttons' })}
              </Button>
              {stock.length > 0 && (!locations.length > 0 || selectedLocation) && (
                <Button
                  className={cx(styles.Button, styles.Green)}
                  color="green"
                  size="big"
                  onClick={() => {
                    if (locations.length > 0) {
                      history.push('stock/edit/location/' + selectedLocation.id)
                    } else {
                      history.push('stock/edit')
                    }
                  }}
                >
                  {t('update', { ns: 'buttons' })}
                </Button>
              )}
              </span>
            </div>
          </table>
        )}
        <GreenbytesModal
          confirmDeleteOpen={confirmArchiveOpen}
          setConfirmDeleteOpen={setConfirmArchiveOpen}
          text={
            locations.length > 0 && selectedLocation
              ? t('want_to_archive_location', { ns: 'stock', item_name: itemForAction.name, location_name: selectedLocation.name })
              : t('want_to_archive', { ns: 'stock', item_name: itemForAction.name })
          }
          confirmButtonText={'archive'}
          cancelButtonText={'cancel'}
          handleConfirmClick={archiveStockItemHandler}
          cancelButtonAction={handleCancelAction}
        />
        <GreenbytesModal
          confirmDeleteOpen={confirmDeleteOpen}
          setConfirmDeleteOpen={setConfirmDeleteOpen}
          text={
            locations.length > 0 && selectedLocation
              ? t('want_to_delete_location', { ns: 'stock', item_name: itemForAction.name, location_name: selectedLocation.name })
              : t('want_to_delete', { ns: 'stock', item_name: itemForAction.name })
          }
          confirmButtonText={'delete'}
          cancelButtonText={'cancel'}
          handleConfirmClick={deleteStockItemHandler}
          cancelButtonAction={handleCancelAction}
        />
        <PortalPopup isOpen={portalOpen} message={portalMessage} onCloseClick={handleClosePortal} />
      </div>
    </>
  )
}

export default Stock
