import React, { useEffect, useState } from 'react'
import { Dropdown, FormField, Icon, Label, Popup } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import { useField } from 'formik'
import _ from 'lodash'

const SelectInput = ({
  disabled,
  dropdownChanged,
  fieldValueSetter,
  fieldValueToSet,
  hint,
  label,
  onChangeCb,
  setDistributor,
  onlyValue = false,
  additionsAllowed = false,
  alterText = false,
  placeholder,
  resetValue,
  currentStep = '',
  buttonRef = null,
  toggleEnterRef = null,
  buttonRefKind = '',
  ...rest
}) => {
  const [field, meta, helpers] = useField(rest)
  const cleanProps = { ...rest }
  delete cleanProps.fieldValueSetter
  delete cleanProps.fieldValueToSet
  const history = useHistory()
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (field.value && setDistributor) setDistributor(field.value)
  }, [field.value, setDistributor])

  useEffect(() => {
    if (resetValue && currentStep === 'second-step' && history.action !== 'POP') {
      helpers.setValue(null)
    }
  }, [resetValue, currentStep])

  useEffect(() => {
    const mobileMediaQuery = window.matchMedia('(max-width: 500px)')
    const handleMobileChange = (event) => {
      setIsMobile(event.matches)
    };
    mobileMediaQuery.addEventListener('change', handleMobileChange)
    setIsMobile(mobileMediaQuery.matches)

    return () => {
      mobileMediaQuery.removeEventListener('change', handleMobileChange)
    };
  }, []);

  return (
    <FormField error={meta.touched && !!meta.error}>
      {meta.touched && meta.error ? (
        <Label pointing="below" content={meta.error} />
      ) : (
        label && (
          <label>
            {label}
            {hint && (
              <Popup position="top center" content={hint} trigger={<Icon color="grey" name="question circle" />} />
            )}
          </label>
        )
      )}
      <Dropdown
        className="large"
        value={field.value || null}
        disabled={disabled}
        placeholder={placeholder}
        onChange={(e, { value }) => {
          helpers.setValue(value)
          if (onChangeCb) onChangeCb({ event: e, value: value })

          if (fieldValueSetter && value !== '') {
            // IT: this is to fix the bug with setting the text value when using tab and arrow keys
            if (onlyValue) {
              fieldValueSetter(fieldValueToSet, value)
            } else {
              const matchingOption = _.find(cleanProps.options, { value })
              fieldValueSetter(fieldValueToSet, matchingOption)
            }
          }
          if (dropdownChanged) {
            dropdownChanged(value)
          }
          if (buttonRef && toggleEnterRef) {
            buttonRef.current = buttonRefKind
            toggleEnterRef.current = e.type === 'click'
          }
        }}
        onBlur={() => helpers.setTouched(true)}
        search={!isMobile}
        text={alterText && field.value ? field.value : null}
        selection
        allowAdditions={additionsAllowed}
        onAddItem={(_, data) => dropdownChanged('custom-value', data.value)}
        {...cleanProps}
      />
    </FormField>
  )
}

export default SelectInput
