import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { updateStock } from '../../../store/actions'
import styles from '../../../assets/styles/modules/Home.module.scss'
import { Link } from 'react-router-dom'
import stockIconGreenSrc from '../../../assets/images/home/stock-green.svg'
import stockIconDarkGreenSrc from '../../../assets/images/home/stock-dark-green.svg'
import stockIconGreySrc from '../../../assets/images/navigation/stock.svg'
import Spinner from '../../UI/Spinner/Spinner'
import { getUserId } from '../../../store/actions/authActions'
import { asyncActionStart, asyncActionError } from '../../../store/actions'
import { flaskAPIGet } from '../../../shared/utility'

const StockItems = () => {
  const dispatch = useDispatch()
  const { stock, lastLocationId } = useSelector((state) => state.stock)
  const { loading } = useSelector((state) => state.async)
  const { selectedLocation } = useSelector((state) => state.location)
  const { t } = useTranslation(['home', 'stock'])
  const [firstLoad, setFirstLoad] = React.useState(true)

  React.useEffect(() => {
    const loadStock = async () => {
      const userId = await getUserId()
      try {
        dispatch(asyncActionStart())
        const stock = await flaskAPIGet({ endpoint: `/v2/stock/user/${userId}/location/${selectedLocation.id}/stock` })
        dispatch(updateStock(stock, selectedLocation.id))
      } catch (error) {
        dispatch(asyncActionError(error, ''))
      }
      setFirstLoad(false)
    }

    if (selectedLocation && firstLoad && (stock?.length === 0 || lastLocationId !== selectedLocation.id)) {
      loadStock()
    }
  }, [dispatch, selectedLocation])
  const classes = [styles.Task]
  if (stock.length) classes.push(styles.Done)

  if (loading) return <div className={classes.join(' ')}><Spinner content={t('loading', { ns: 'home' })} /></div>

  return (
    <div className={classes.join(' ')}>
      <Link className={styles.TaskLink} to="/stock">
        <div className={styles.TaskIcon}>
          {!stock.length ? (
            <img src={stockIconGreySrc} alt="" border="0" />
          ) : (
            <>
              <img className={styles.TaskIconImg} src={stockIconGreenSrc} alt="" border="0" />
              <img className={styles.TaskIconImgHover} src={stockIconDarkGreenSrc} alt="" border="0" />
            </>
          )}
        </div>
        {stock.length === 0 ? (
          <>
            <strong className="xx-large">2.</strong> <span>{t('add_stock_items', { ns: 'stock' })}</span>
          </>
        ) : (
          t('manage_stock', { ns: 'home' })
        )}
      </Link>
    </div>
  )
}

export default StockItems
