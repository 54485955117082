import React, { useState } from 'react'
import { Icon } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import styles from '../../../assets/styles/modules/UI/Accordion.module.scss'

const Accordion = ({ customTitle = false, items = null, info = null }) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation(['distributor', 'orders'])
  
  return (
    <div className={cx(customTitle && info ? styles.CustomAccordionContainer : styles.AccordionContainer)}>
      <span className={styles.AccordionTitle} onClick={() => setOpen(!open)}>
        { customTitle && info ? 
          <h3>{t('dishes', { ns: 'orders', amount: info.length })}</h3>
          :
          <h3>{t('representative_details', { ns: 'distributor' })}</h3>
        }
        { open ?
          <Icon name="angle up" className={styles.Icon} />
          :
          <Icon name="angle down" className={styles.Icon} />
        }
      </span>
      { open && items && items.map((text) => <p className={cx(styles.Accordion, styles.Item)}>{text}</p>)}
      { open && info && info.map((item) => 
        <div className={cx(styles.Accordion, styles.Item)}>
          <span>
            <h3>{item.name}</h3>
            <p>{item.description}</p>
          </span>
          <span className={styles.Amount}>{item.amount}</span>
        </div>
      )}
    </div>
  )
}

export default Accordion