import * as React from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { cloudFunctions } from '../../../config/firebase'
import { httpsCallable } from 'firebase/functions'
import { Message } from 'semantic-ui-react'
import { Formik } from 'formik'
import Spinner from '../../UI/Spinner/Spinner'
import { updateStock, updateDistributors } from '../../../store/actions'
import { getUserId } from '../../../store/actions/authActions'
import { flaskAPIGet } from '../../../shared/utility'
import { ShowFormEdit } from './ShowFormEdit'
import { getPayload, stockItemSchema } from './utils'

const EditStockItem = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['validationMessages', 'greenbytes', 'distributor'])

  const history = useHistory()
  let locations = null,
    selectedLocation = null,
    item = null
  if (history.location.state) {
    ;({ locations, selectedLocation, item } = history.location.state)
  }
  const { distributors } = useSelector((state) => state.distributor)
  const { loading } = useSelector((state) => state.async)
  const { stock } = useSelector((state) => state.stock)
  const [firstLoad, setFirstLoad] = React.useState(true)

  React.useEffect(() => {
    if (!selectedLocation) {
      return <Redirect to="/locations" />
    }

    const loadDistributors = async () => {
      const userId = await getUserId()
      const distributorsData = await flaskAPIGet({ endpoint: `/v2/distributors/user/${userId}?mode=user` })
      dispatch(updateDistributors(distributorsData))
      setFirstLoad(false)
    }

    if (firstLoad && !distributors.length) {
      loadDistributors()
    }
  }, [])

  if (loading) return <Spinner content={t('loading', { ns: 'greenbytes' })} />

  const initialValues = item
    ? {
        name: item.name,
        amount: item.amount,
        onboarding: item.onboarding,
        unit: item.unit,
        locationIds: selectedLocation ? [selectedLocation.id] : [],
        locations: selectedLocation ? { [selectedLocation.id]: selectedLocation.name } : {}
      }
    : {}

  if (!distributors.length) {
    return (
      <Message style={{ textAlign: 'center' }}>
        <Message.Content>
          <p className="large">{t('add_distributor_first', { ns: 'distributor' })}</p>
          <Link className="link" to="/distributors">
            {t('go_to_distributors', { ns: 'distributor' })}
          </Link>
        </Message.Content>
      </Message>
    )
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            const userId = await getUserId()
            const res = await httpsCallable(
              cloudFunctions,
              'updateStockItem'
            )({
              id: item.id,
              values: getPayload(values),
              initialValues,
              locationId: selectedLocation ? selectedLocation.id : null,
            })
            let updatedStockItem = []
            updatedStockItem.push(res.data)
            const newStock = stock.map(obj => updatedStockItem.find(o => o.id === obj.id) || obj)
            dispatch(updateStock(newStock, selectedLocation.id))
            const distributorsData = await flaskAPIGet({ endpoint: `/v2/distributors/user/${userId}?mode=user` })
            dispatch(updateDistributors(distributorsData))
            setSubmitting(false)
            history.push('/stockList')
          } catch (error) {
            setErrors({ stock: error.message })
            setSubmitting(false)
          }
        }}
        validationSchema={stockItemSchema(t)}
      >
        {({ isSubmitting, errors, setFieldValue, values, handleReset }) => (
          <ShowFormEdit
            values={values}
            errors={errors}
            setFieldValue={setFieldValue}
            isSubmitting={isSubmitting}
            locations={locations}
            handleReset={handleReset}
            locationName={selectedLocation && selectedLocation.name ? selectedLocation.name : ''}
            selectedLocation={selectedLocation}
          />
        )}
      </Formik>
    </div>
  )
}

export default EditStockItem
