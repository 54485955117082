import orderIconSentSrc from '../assets/images/orders/order-sent.svg'
import orderIconConfirmedSrc from '../assets/images/orders/order-confirmed.svg'
import orderIconDeliveredSrc from '../assets/images/orders/order-delivered.svg'
import orderIconProcessingSrc from '../assets/images/orders/order-processing.svg'
import { Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { httpsCallable } from 'firebase/functions'
import { cloudFunctions } from '../config/firebase'
import { TYPE_YOUR_OWN_INGREDIENT, TYPE_YOUR_OWN_PRODUCT } from '../store/wordings'
import { getUserId, getUserIdToken } from '../store/actions/authActions'
import axios from 'axios'

const defaultUnits = [
  { key: 'g', value: 'g', text: 'g' },
  { key: 'kg', value: 'kg', text: 'kg' },
  { key: 'L', value: 'L', text: 'L' },
  { key: 'mL', value: 'mL', text: 'mL' },
  { key: 'pcs', value: 'pcs', text: 'pcs' },
]

const invalidAllergens = [ 'none', 'null', 'empty' ]

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  }
}

export const getFileExtension = (filename) => {
  return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2)
}

const getIdProductOnboarding = (item) => {
  let result = ''
  if (item.onboarding) {
    result = item.onboarding[0].id_product_onboarding
      ? item.onboarding[0].id_product_onboarding
      : item.onboarding[0].typed_product
  } else {
    if (item.id_product_onboarding) {
      result = item.id_product_onboarding
    } else {
      result = item.name
    }
  }
  return result
}

/**
 * Filters archived items
 * @param Array stock
 * @return Array
 */
export const getStockArray = (stock, t, locationIds = []) => {
  let result = [TYPE_YOUR_OWN_INGREDIENT(t)]
    .concat(
      stock
        .filter((item) => !item?.isArchived)
        .map((item) => {
          const firstDistributor = Object.values(item.distributors)[0]
          // Next line is meant to be backward compatible with products that were added to stock before the new onboarding
          const distributor = item.id_distributor_onboarding
            ? item.id_distributor_onboarding
            : firstDistributor.name.text
            ? firstDistributor.name.text
            : firstDistributor.name
          return includesAll(locationIds, item.locationIds)
            ? {
                id: item.id,
                value: item.id,
                text: item.name,
                id_distributor_onboarding: distributor,
                availableUnits: item.availableUnits,
                id_product_onboarding: getIdProductOnboarding(item),
                has_multiple_distributors: Object.values(item.distributors).length > 1,
              }
            : null
        })
    )
    .filter((el) => el !== null)
  return result
}

/**
 * Filters archived items
 * @param Array stock
 * @return Array
 */
export const makeStockArray = (stock = [], t, locationIds = []) => {
  let result = stock
    .filter((item) => !item?.isArchived)
    .map((item) => {
      const firstDistributor = Object.values(item.distributors)[0]
      // Next line is meant to be backward compatible with products that were added to stock before the new onboarding
      const distributor = item.id_distributor_onboarding
        ? item.id_distributor_onboarding
        : firstDistributor.name.text
        ? firstDistributor.name.text
        : firstDistributor.name
      return {
        id: item.id,
        value: item.id,
        text: item.name,
        id_distributor_onboarding: distributor,
        availableUnits: item.availableUnits,
        id_product_onboarding: getIdProductOnboarding(item),
        has_multiple_distributors: Object.values(item.distributors).length > 1,
      }
    })
    .concat([TYPE_YOUR_OWN_INGREDIENT(t)])
  return result
}

const sortByLength = (arr) => {
  if (arr && arr.length > 0) return arr.slice().sort((a, b) => a.length - b.length)
  else return null
}

export const getAvailableUnits = (ingredient) => {
  if (ingredient?.fromStock) {
    return ingredient?.id_product_onboarding?.availableUnits || ingredient?.generic_name?.availableUnits
  }
  return null
}

export const getPurchasePriceUnits = (ingredient) => {
  if (ingredient) {
    return ingredient?.purchasePriceUnits || ingredient?.generic_name?.purchasePriceUnits
  }
  return null
}

export const getPurchaseUnits = (ingredient) => {
  if (ingredient) {
    return ingredient?.purchaseUnits || ingredient?.generic_name?.purchaseUnits
  }
  return null
}

export const getUnitArray = (availableUnits) => {
  return sortByLength(availableUnits)?.map((unit) => ({ key: unit, value: unit, text: unit })) || defaultUnits
}

export const getLocationId = (selectedLocation) => {
  return selectedLocation.id ? selectedLocation.id : selectedLocation
}

export const getOrderUnitArray = () => {
  return [
    { key: 'pcs', value: 'pcs', text: 'pcs' },
    { key: 'pkg', value: 'pkg', text: 'pkg' },
    { key: 'box', value: 'box', text: 'box' },
    { key: 'bunch', value: 'bunch', text: 'bunch' },
  ]
}

export const getStatusArray = () => {
  return [
    { key: 'active', value: true, text: 'active' },
    { key: 'inactive', value: false, text: 'inactive' },
  ]
}

export async function getDistributorProductsOnboarding(distributorId, t) {
  try {
    const response = distributorId
      ? await httpsCallable(cloudFunctions, 'getDistributorProductsOnboarding')({ distributorId })
      : { data: [] }
    return response.data
      .map((el) => {
        return {
          ...el,
          value: el.description,
          text: el.search + '(' + el.itemNumber + ')',
          content: el.description,
          description: '',
        }
      })
      .concat([TYPE_YOUR_OWN_PRODUCT(t)])
  } catch (error) {
    return null
  }
}

export async function getCustomDistributorProducts(distributorId, locationId, t) {
  try {
    const response = await httpsCallable(cloudFunctions, 'getDistributorProducts')({ distributorId, locationId })
    return response.data
      .map((el) => {
        return {
          ...el,
          value: el.description,
          text: el.search + '(' + el.itemNumber + ')',
          content: el.description,
          description: '',
        }
      })
      .concat([TYPE_YOUR_OWN_PRODUCT(t)])
  } catch (error) {
    return null
  }
}

export async function getCurrentDistributorProducts(distributorId, locationId, t) {
  if (locationId) {
    return getCustomDistributorProducts(distributorId, locationId, t)
  } else {
    return getDistributorProductsOnboarding(distributorId, t)
  }
}

export async function getDistributorsOnboarding() {
  try {
    const userId = await getUserId()
    const response = await flaskAPIGet({ endpoint: `/v2/distributors/user/${userId}` })
    let distributor = {}
    return response.map((el) => {
      const { email, id, name, phone, website, distrPhotoURL } = el
      if (el.customDistributor) {
        distributor = { email, id, name, phone, website, distrPhotoURL, value: name, text: name }
      } else {
        distributor = { email, id: name, name, phone, website, distrPhotoURL, value: name, text: name }
      }
      return distributor
    })
  } catch (error) {
    return null
  }
}

export const getFirestoreCollectionArray = (collection) => {
  return collection.map((item) => ({
    id: item.id,
    value: item.id,
    text: item.name,
  }))
}

export const getSubMenuItemArray = (items = [], filterByType = null) => {
  // Filter out archived items and combos, sort alphabetically
  let menuItems = _.sortBy(
    items.filter((item) => !item?.isArchived && !item?.isCombo),
    'name'
  )

  if (filterByType) {
    menuItems = menuItems.filter((item) => item[filterByType] === true)
  }

  return menuItems.map((item) => ({
    id: item.id,
    value: item.id,
    text: item.name,
  }))
}

export const getLocationArray = (locations) => {
  const locationArr = []
  if (!locations) return locationArr
  locations.map((loc) =>
    locationArr.push({
      id: loc.id,
      value: loc.id,
      text: loc.name,
    })
  )
  return _.sortBy(locationArr, 'text')
}

export const addToNumber = (n, decimals = 1) => {
  return isFloat(n) ? (n + 0.1).toFixed(decimals) : n + 1
}

export const subtractFromNumber = (n, decimals = 1) => {
  if (n <= 0) return 0
  return isFloat(n) ? (n - 0.1).toFixed(decimals) : n - 1
}

export const isInt = (n) => {
  return Number(n) === n && n % 1 === 0
}

export const isFloat = (n) => {
  return Number(n) === n && n % 1 !== 0
}

export const getWeekdaysArray = () => {
  const days = []
  for (let i = 1; i <= 7; i++) {
    days.push({ key: i, value: i, text: i })
  }
  return days
}

export const getOrderStatus = (status) => {
  switch (status) {
    case 'processing': {
      return 'Processing'
    }
    case 'sent': {
      return 'Order Sent'
    }
    case 'confirmed': {
      return 'Confirmed'
    }
    case 'delivered': {
      return 'Delivered'
    }
    case 'error': {
      return 'Error'
    }
    default:
      return 'n/a'
  }
}

export const getStatusIcon = (status, iconSize = '') => {
  switch (status) {
    case 'processing':
      return <img src={orderIconProcessingSrc} alt={status} />
    case 'sent':
    case 'open': {
      return <img src={orderIconSentSrc} alt={status} />
    }
    case 'confirmed':
    case 'new': {
      return <img src={orderIconConfirmedSrc} alt={status} />
    }
    case 'delivered':
    case 'done': {
      return <img src={orderIconDeliveredSrc} alt={status} />
    }
    case 'error': {
      return iconSize ? (
        <Icon name="exclamation" circular color="yellow" size={iconSize} style={{ backgroundColor: 'white' }} />
      ) : (
        <Icon name="exclamation" circular inverted color="yellow" />
      )
    }
    default:
      return null
  }
}

const sortOpeningHours = (hours) => {
  if (!hours.extraInfo) {
    return hours
  } else {
    let unsortedHours = [...hours.extraInfo]
    unsortedHours.push(`${hours.openFrom} - ${hours.openTo}`)
    unsortedHours.sort()
    const firstHours = unsortedHours.shift().split(' - ')
    return { openFrom: firstHours[0], openTo: firstHours[1], extraInfo: unsortedHours }
  }
}

export const sortOpeningHoursByDay = (hours) => {
  if (!hours) return []
  const weekDays = ['Su', 'M', 'Tu', 'W', 'Th', 'F', 'Sa']
  const sortedOpeningDays = {}
  _.each(weekDays, function (i) {
    if (hours[i]) {
      sortedOpeningDays[i] = sortOpeningHours(hours[i])
    }
  })
  return sortedOpeningDays
}

export const formatNumber = (n, unit = null) => {
  if (parseInt(n) === n) {
    return parseInt(n)
  } else if (n === '') {
    return 0
  } else {
    if (unit === 'kg') return parseFloat(n).toFixed(1)
    else return parseFloat(n).toFixed(2)
  }
}

export const getDistributorsFromStock = (stock, allDistributors, t) => {
  const distributors = []

  stock.map((item) => {
    if (!distributors.find((distr) => distr.id === item.distributorId)) {
      const distributor = allDistributors.filter((dist) => dist.id === item.distributorId)
      distributors.push({
        id: item.distributorId,
        value: item.distributorId,
        text: item.distributor,
        disabled: distributor.length && distributor[0].email === '',
        description:
          distributor.length && distributor[0].email === '' ? t('incomplete_info', { ns: 'distributor' }) : '',
      })
    }
    return null
  })

  return distributors
}

export const getLocationsFromItem = (locations) => {
  let locationStr = ''
  if (typeof locations !== undefined && Object.prototype.toString.call(locations) === '[object Object]') {
    return Object.entries(locations).map(([id, loc], index) => (
      <span key={id}>
        {index > 0 ? ', ' : ''}
        {loc}
      </span>
    ))
  }

  return locationStr
}

export const getDistributorArray = (defaultDistributor, distributors) => {
  return defaultDistributor.name && defaultDistributor.distributorId
    ? [
        {
          id: defaultDistributor.distributorId,
          itemNumber: defaultDistributor.itemNumber || '',
          name: defaultDistributor.name,
          qtyPiece: defaultDistributor.qtyPiece,
          orderUnit: defaultDistributor.orderUnit,
          orderUnitPcs: defaultDistributor.orderUnitPcs,
          customDistributor: defaultDistributor.customDistributor || null,
        },
      ]
    : Object.keys(distributors).map((key) => {
        const distributor = distributors[key]
        return {
          id: distributor.id || key,
          itemNumber: distributor.itemNumber,
          name: distributor.name,
          qtyPiece: distributor.qtyPiece,
          orderUnit: distributor.orderUnit,
          orderUnitPcs: distributor.orderUnitPcs,
          customDistributor: distributor.customDistributor || null,
        }
      })
}

export const getDistributorsObject = (distributors) => {
  const distributorsObj = {}
  distributors.forEach((distributor) => {
    distributorsObj[distributor.id] = {
      itemNumber: distributor.itemNumber,
      name: distributor.name,
      qtyPiece: distributor.qtyPiece || '',
      orderUnit: distributor.orderUnit || '',
      orderUnitPcs: distributor.orderUnitPcs || '',
      customDistributor: distributor.customDistributor || null,
    }
  })
  return distributorsObj
}

export const getAvailableProviders = (providers = {}) => {
  return Object.keys(providers['available_pos']).map((pos) => {
    const option = {
      key: pos,
      value: pos,
      text: '',
    }
    switch (pos) {
      case 'SALESCLOUD':
        option.text = 'SalesCloud'
        break
      case 'REGLA':
        option.text = 'Regla'
        break
      case 'DK':
        option.text = 'DK Hugbúnaður'
        break
      default:
        option.text = pos
        break
    }
    return option
  })
}

export const getSettingsMessage = (user, t) => {
  if (!user) return null

  switch (true) {
    case !user.isComplete:
      return {
        header: t('thanks_for_registering', { companyName: user.companyName }),
        body: 'Please complete your company details, below.',
      }
    case !user.hasMenu:
      return {
        header: 'Review your info!',
        body: (
          <>
            Now that we're all connected lets get to building your{' '}
            <Link to="/menu" style={{ fontWeight: 'bold' }}>
              Menu
            </Link>
            !!
          </>
        ),
      }
    default:
      return null
  }
}

export const getLocationsMessage = (user, t) => {
  if (!user) return null

  if (!user.hasEditedLocation) {
    return {
      header: t('has_not_edited_location'),
      body: (
        <>
          {t('fill_in_information')}
          <br />
          <br />
          Hint: build your stock and menu for one location before making additional locations so you can duplicate the
          existing data - less work!!
        </>
      ),
    }
  } else if (!user.hasCheckedSettings) {
    return {
      header: 'Nice!',
      body: (
        <>
          Lets go back to{' '}
          <Link to="/settings" style={{ fontWeight: 'bold' }}>
            Settings
          </Link>{' '}
          to review your profile information!
        </>
      ),
    }
  } else {
    return null
  }
}

export async function updateUserFlags(userFlags) {
  try {
    return await httpsCallable(cloudFunctions, 'updateUserFlags')(userFlags)
  } catch (error) {
    return { error }
  }
}

/**
 * Compares locationIds from form values with initial locationIds stored in Firestore.
 * Returns information about given values.
 * @param locations
 * @param initialLocations
 * @returns boolean
 */
export const compareLocations = (locations, initialLocations) => {
  let locationsMatch = true
  if (_.isArray(locations) && _.isArray(initialLocations)) {
    if (locations.length !== initialLocations.length) {
      locationsMatch = false
    } else {
      if (!_.isEqual(_.sortBy(locations), _.sortBy(initialLocations))) {
        locationsMatch = false
      }
    }
  } else {
    locationsMatch = false
  }
  return locationsMatch
}

/**
 * Format the price from a string and returns an Int
 * @param priceStr
 * @returns int
 */
export const formatPrice = (priceStr) => {
  if (priceStr) {
    return priceStr === '0' ? 0 : parseInt(priceStr.slice(0, -2)).toLocaleString()
  }
}

/**
 * Returns true if 'array' contains at least one item from 'items'
 * @param items
 * @param array
 * @returns boolean
 */
export const includesAtLeastOne = (items, array) => {
  if (array.length === 0 || !items) {
    return false
  } else {
    let result = false
    for (let i = 0; i < items.length; i++) {
      result |= array.includes(items[i])
    }
    return result
  }
}

/**
 * Returns true if 'array' contains all items from 'items'
 * @param items
 * @param array
 * @returns boolean
 */
export const includesAll = (items, array) => {
  if (!array || !items || array.length === 0 || items.length === 0) {
    return false
  } else {
    let result = true
    for (let i = 0; i < items.length; i++) {
      result &= array.includes(items[i])
    }
    return result
  }
}

export const MAIN_PAGES = ['stock', 'orders', 'menu', 'distributors']

// Pages where the distributors' toaster should not be displayed
export const DISTRIBUTOR_TOASTER_PAGES = ['distributors', 'login']

export const EDIT_DISTRIBUTOR_PAGE = 'distributors/edit'

export const flaskAPIGet = async ({ endpoint, params }) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_URL}${endpoint}`
    const response = await axios.get(url, {
      params: params,
      headers: {
        Authorization: 'Bearer ' + await getUserIdToken(),
      },
      validateStatus: function (status) {
        return status < 500 // Resolve only if the status code is less than 500
      },
    })
    if (response.status < 400) return response.data
    else return { error: response?.data?.error || 'An error ocurred. Please, contact support team.' }
  } catch (err) {
    console.log(err)
  }
}

export const flaskAPIPost = async ({ endpoint, payload }) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_URL}${endpoint}`
    const response = await axios.post(url, payload, {
      headers: {
        Authorization: 'Bearer ' + await getUserIdToken(),
      },

      validateStatus: function (status) {
        return status < 500 // Resolve only if the status code is less than 500
      },
    })
    if (response.status < 400) return response.data
    else return { error: response?.data?.error || 'An error ocurred. Please, contact support team.' }
  } catch (err) {
    console.log(err)
  }
}

export const kitchenTypeOptions = [
  {key: 'canteen', value: 'canteen', text: 'Canteen'},
  {key: 'catering', value: 'catering', text: 'Catering'},
  {key: 'individual', value: 'individual', text: 'Individual'},
  {key: 'restaurant', value: 'restaurant', text: 'Restaurant'},
  {key: 'other', value: 'other', text: 'Other'}
]

export const currencySignsBeforeAmount = ['$', '€', '£']

export const calculateUnitCost = (unit, purchaseUnit, purchasePrice, purchaseUnitAmount) => {
  if (purchaseUnit) {
    switch (unit) {
      case 'pcs':
      case 'pkg': {
        return purchasePrice/purchaseUnitAmount
      }
      case 'g': {
        const pu = purchaseUnit === 'kg' ?  (purchaseUnitAmount*1000) : purchaseUnitAmount
        return purchasePrice/pu
      }
      case 'kg': {
        const pu = purchaseUnit === 'g' ?  (purchaseUnitAmount/1000) : purchaseUnitAmount
        return purchasePrice/pu
      }
      case 'mL': {
        const pu = purchaseUnit === 'L' ?  (purchaseUnitAmount*1000) : purchaseUnitAmount
        return purchasePrice/pu
      }
      case 'L': {
        const pu = purchaseUnit === 'mL' ?  (purchaseUnitAmount/1000) : purchaseUnitAmount
        return purchasePrice/pu
      }
      default:
        return purchasePrice/purchaseUnitAmount
    }
  } else {
    return purchasePrice/purchaseUnitAmount
  }
}

export const calculateCeil = (value, currencySign) => {
  if (currencySignsBeforeAmount.includes(currencySign)) {
    return value
  } else {
    return Math.ceil(value)
  }
}

export const filterAllergens = (items) => {
  let values = []
  for (let index = 0; index < items.length; index++) {
    values = values.concat(items[index].allergens)
  }
  values = values.map((allergen) => invalidAllergens.includes(allergen.toLowerCase()) ? '' : allergen)
  return Array.from(new Set(values))
}

export const getLabelPosition = (items, label) => {
  let pos = -1
  for (let i = 0; i < items.length; i++) {
    if (items[i].label.toUpperCase() === label) {
      pos = i
    }
  }
  return pos
}

export const swapElements = (arr, x, y, rearrange) => {
  if ((rearrange) && (x !== -1) && (y !== -1)) {
    let result = [...arr]
    const temp = result[x]
    result[x] = arr[y]
    result[y] = temp
    return result
  } else {
    return arr
  }
}