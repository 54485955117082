import * as React from 'react'
import styles from '../../../assets/styles/modules/Home.module.scss'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import menuIconGreenSrc from '../../../assets/images/home/menu-green.svg'
import menuIconDarkGreenSrc from '../../../assets/images/home/menu-dark-green.svg'
import menuIconGreySrc from '../../../assets/images/navigation/menu.svg'
import Spinner from '../../UI/Spinner/Spinner'
import { useDispatch, useSelector } from 'react-redux'
import { getUserId } from '../../../store/actions/authActions'
import { asyncActionStart, asyncActionError } from '../../../store/actions/asyncActions'
import { updateMenuItems } from '../../../store/actions'
import { flaskAPIGet } from '../../../shared/utility'

const Menu = () => {
  const dispatch = useDispatch()
  const { menuItems, lastLocationId } = useSelector((state) => state.menu)
  const { loading } = useSelector((state) => state.async)
  const { selectedLocation } = useSelector((state) => state.location)
  const { t } = useTranslation(['home', 'menu'])
  const [firstLoad, setFirstLoad] = React.useState(true)

  React.useEffect(() => {
    const loadMenuItems = async () => {
      const userId = await getUserId()
      try {
        dispatch(asyncActionStart())
        const menuItems = await flaskAPIGet({ endpoint: `/v2/menu/user/${userId}/location/${selectedLocation.id}/recipes` })
        dispatch(updateMenuItems(menuItems, selectedLocation.id))
      } catch (error) {
        dispatch(asyncActionError(error, ''))
      }
      setFirstLoad(false)
    }

    if (selectedLocation && firstLoad && (menuItems?.length === 0 || lastLocationId !== selectedLocation.id)) {
      loadMenuItems()
    }
  }, [dispatch, selectedLocation])

  const classes = [styles.Task]
  if (menuItems?.length) classes.push(styles.Done)

  if (loading) return <div className={classes.join(' ')}><Spinner content={t('loading', { ns: 'home' })} /></div>

  return (
    <div className={classes.join(' ')}>
      <Link className={styles.TaskLink} to="/menu">
        <div className={styles.TaskIcon}>
          {!menuItems?.length ? (
            <img src={menuIconGreySrc} alt="" border="0" />
          ) : (
            <>
              <img className={styles.TaskIconImg} src={menuIconGreenSrc} alt="" border="0" />
              <img className={styles.TaskIconImgHover} src={menuIconDarkGreenSrc} alt="" border="0" />
            </>
          )}
        </div>
        {!menuItems?.length ? (
          <>
            <strong className="xx-large">3.</strong> <span>{t('build_your_menu', { ns: 'menu' })}</span>
          </>
        ) : (
          t('manage_menu', { ns: 'home' })
        )}
      </Link>
    </div>
  )
}

export default Menu