import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Redirect } from 'react-router-dom'
import { Button, Message, Grid, Icon } from 'semantic-ui-react'
import { Form, Formik, FieldArray } from 'formik'
import { useTranslation } from 'react-i18next'
import useFirestoreDoc from '../../../hooks/useFirestoreDoc'
import { getDistributorFromFirestore, updateDistributor, setPageTitle, updateDistributors } from '../../../store/actions'
import TextInput from '../../UI/Form/TextInput'
import * as Yup from 'yup'
import ProfileImage from '../../ProfileImage/ProfileImage'
import placeholderSrc from '../../../assets/images/distributors/placeholder.svg'
import CheckboxInputButton from '../../UI/Form/CheckboxInputButton'
import { cloudFunctions } from '../../../config/firebase'
import { adaptOpeningHours, parseOpeningHours, distributorSchema, refreshValues } from './utils'
import { httpsCallable } from 'firebase/functions'
import cx from 'classnames'
import styles from '../../../assets/styles/modules/distributors/AddDistributor.module.scss'
import Spinner from '../../UI/Spinner/Spinner'
import TimePicker from '../../UI/Form/TimePicker'
import GreenbytesModal from '../../UI/GreenbytesModal/GreenbytesModal'
import { getUserId } from '../../../store/actions/authActions'
import { TYPE_YOUR_OWN_DISTRIBUTOR } from '../../../store/wordings'
import CustomDropdown from '../../UI/Form/CustomDropdown'
import { flaskAPIGet } from '../../../shared/utility'

const EditDistributor = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const target = history.location?.state?.target
  const { t } = useTranslation(['distributor', 'buttons', 'validationMessages', 'common'])

  const { distributor } = useSelector((state) => state.distributor)
  const { distributors } = useSelector((state) => state.distributor)
  const [additionalContact, setAdditionalContact] = React.useState(false)
  const [allDistributors, setAllDistributors] = React.useState([])
  const [isLoadingDistributors, setIsLoadingDistributors] = React.useState(true)
  const [distributorsNames, setDistributorsNames] = React.useState([])
  const [repeatedDistributor, setRepeatedDistributor] = React.useState(false)
  const [isDeleting, setIsDeleting] = React.useState(false)
  const [deleteError, setDeleteError] = React.useState(false)
  const [confirmOpen, setConfirmOpen] = React.useState(false)
  const toggleEnterRef = React.useRef(false)
  const distributorName = React.useRef(distributor ? distributor.name : '')
  const [firstLoad, setFirstLoad] = React.useState(true)
  const [firstLoadDistributor, setFirstLoadDistributor] = React.useState(true)
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    if (distributor) {
      setAdditionalContact(distributor.representativeEmail && distributor.representativeName && distributor.representativePhone)
    }
  }, [distributor])

  React.useEffect(() => {
    dispatch(setPageTitle(t('edit_distributor', { ns: 'distributor' })))
    const getDistributorsOnboarding = async () => {
      const userId = await getUserId()
      const response = await flaskAPIGet({ endpoint: `/v2/distributors/user/${userId}` })
      const data = response.map((el) => {
        let distributor = {}
        const { email, id, name, phone, website, distrPhotoURL } = el
        if (distributors.some(distributor => distributor.name === el.name)) {
          distributor = { email, id, name, phone, website, distrPhotoURL, value: el.id, text: el.name, description: 'already on your list', disabled: true }
        } else {
          distributor = { email, id, name, phone, website, distrPhotoURL, value: el.id, text: el.name }
        }
        return distributor
      })
      setAllDistributors(data.concat([TYPE_YOUR_OWN_DISTRIBUTOR(t)]))
      setIsLoadingDistributors(false)
    }

    const loadDistributors = async () => {
      const userId = await getUserId()
      const distributorsData = await flaskAPIGet({ endpoint: `/v2/distributors/user/${userId}?mode=user` })
      dispatch(updateDistributors(distributorsData))
      setFirstLoad(false)
    }

    const loadDistributor = async () => {
      const userId = await getUserId()
      const distributorData = await flaskAPIGet({ endpoint: `/v2/distributors/user/${userId}/distributor/${props.match.params.id}` })
      dispatch(updateDistributor(distributorData))
      setFirstLoadDistributor(false)
      setLoading(false)
    }

    // Should be able to type your own distributor
    getDistributorsOnboarding()
    setDistributorsNames(distributors.map((dist) => dist?.name?.toUpperCase()))

    if (firstLoad && !distributors.length) {
      loadDistributors()
    }

    if (firstLoadDistributor && (!distributor || distributor.id !== props.match.params.id)) {
      loadDistributor()
    } else {
      setLoading(false)
    }
  }, [dispatch])

  if (!props.match.params.id) {
    return <Redirect to="/distributors" />
  }

  if (loading || !distributor) {
    return <Spinner content={t('please_wait', { ns: 'common' })} />
  }

  const initialValues = {
    distrPhotoURL: distributor.distrPhotoURL,
    name: distributor.name,
    email: distributor.email,
    phone: distributor.phone && distributor.phone.replace(new RegExp('-', 'g'),''),
    website: distributor.website ? distributor.website : '',
    openingHours: adaptOpeningHours(distributor.openingHours),
    representativeName: distributor.representativeName,
    representativePhone: distributor.representativePhone && distributor.representativePhone.replace(new RegExp('-', 'g'),''),
    representativeEmail: distributor.representativeEmail,
    customDistributor: distributor.customDistributor ? distributor.customDistributor : null,
  }

  async function handleDelete() {
    setIsDeleting(true)
    try {
      await httpsCallable(cloudFunctions, 'deleteDistributor')({ id: distributor.id })
      dispatch(updateDistributors(distributors.filter(dist => dist.id !== distributor.id)))
      history.push('/distributors')
    } catch (error) {
      setDeleteError(error.message)
      setIsDeleting(false)
      setConfirmOpen(false)
    }
  }

  return (
    <div className={styles.DistributorForm}>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            if (distributorsNames.filter((name) => name !== distributor.name.toUpperCase()).includes(values.name.toUpperCase())) {
              setRepeatedDistributor(true)
              setSubmitting(false)
            } else {
              setRepeatedDistributor(false)
              const res = await httpsCallable(
                cloudFunctions,
                'updateDistributor'
              )({
                id: distributor.id,
                values: {...values, openingHours: parseOpeningHours(values.openingHours)},
              })
              let updatedDistributor = []
              updatedDistributor.push(res.data)
              const newDistributors = distributors.map(obj => updatedDistributor.find(o => o.id === obj.id) || obj)
              dispatch(updateDistributors(newDistributors))
              setSubmitting(false)
              history.push('/distributorsList', { target })
            }
          } catch (error) {
            setErrors({ distr: error.message })
            setSubmitting(false)
          }
        }}
        validationSchema={distributorSchema(additionalContact, t)}
      >
        {({ isSubmitting, isValid, values, errors, setFieldValue }) => {

          if (values.name) {
            const distributor = allDistributors.filter(dist => dist.name === values.name)
            if ((distributor.length === 1) && !(distributor[0].customDistributor)) {
              values.distrPhotoURL = distributor[0].distrPhotoURL ? distributor[0].distrPhotoURL : null
              values.email = distributor[0].email && toggleEnterRef.current ? distributor[0].email : values.email
              values.phone = distributor[0].phone && toggleEnterRef.current ? distributor[0].phone.replace(new RegExp('-', 'g'),'') : values.phone
              values.website = distributor[0].website && toggleEnterRef.current ? distributor[0].website : values.website 
            } else {
              if (distributorName.current !== values.name) {
                Object.assign(values, refreshValues({...values, customDistributor: true, shouldBeRefreshed: true }))
              }
            }
            distributorName.current = values.name 
            toggleEnterRef.current = false
          } else {
            Object.assign(values, refreshValues({...values, shouldBeRefreshed: true }))
          }

          return (
            <Form autocomplete="off" className="ui form">
              {errors.distr && <Message error content={errors.distr} style={{ marginBottom: 10 }} />}
              {deleteError && <Message error content={deleteError} style={{ marginBottom: 10 }} />}
              { repeatedDistributor && <Message error content={t('distributor_already_exists', { name: values.name, ns: 'validationMessages' })} style={{ marginBottom: 10 }} /> }
              <Grid className={styles['Distributor-Grid']} stackable={true}>
                <Grid.Row>
                  <Grid.Column width={5} className={styles.ImageUploadContainer} vertical-align="middle">
                    <ProfileImage
                      placeholder={placeholderSrc}
                      currentImage={values.distrPhotoURL}
                      fieldName="distrPhotoURL"
                      fieldValueSetter={setFieldValue}
                    />
                  </Grid.Column>
                  <Grid.Column className='distributor-inputs'>
                    { !isLoadingDistributors && allDistributors.length === 1 ?
                      <TextInput name="name" placeholder={t('name', { ns: 'distributor' })} type="text" />
                    :
                      <CustomDropdown
                        name={'name'}
                        placeholder={values.name ? values.name : t('name', { ns: 'distributor' })}
                        options={allDistributors}
                        fieldValueToSet={'name'}
                        fieldValueSetter={setFieldValue}
                        compact
                        loading={isLoadingDistributors}
                        disabled={isLoadingDistributors}
                        customDistributor={'customDistributor'}
                        distributorFlow={true}
                        toggleEnterRef={toggleEnterRef}
                      />
                    }
                    <TextInput name="email" placeholder={t('email', { ns: 'distributor' })} type="email" />
                    <TextInput name="phone" placeholder={t('phone', { ns: 'distributor' })} type="text" />
                    <TextInput name="website" placeholder={t('website', { ns: 'distributor' })} type="text" />
                    { additionalContact &&
                      <>
                        <TextInput name="representativeName" placeholder={t('representative_name', { ns: 'distributor' })} type="text" />
                        <TextInput name="representativeEmail" placeholder={t('representative_email', { ns: 'distributor' })} type="email" />
                        <TextInput name="representativePhone" placeholder={t('representative_phone', { ns: 'distributor' })} type="text" />
                      </>
                    }
                    <p
                      className={styles.AddAdditionalContact}
                        onClick={() => {
                          setAdditionalContact(prevState => !prevState);
                          values.representativeName = '';
                          values.representativePhone = '';
                          values.representativeEmail = '';
                        }}>
                        { additionalContact ?
                          <>
                            <span><Icon className="pointer" name="trash" color="grey" alt="Remove" title="Remove" /></span>
                            <span>{t('remove_additional_contact', { ns: 'distributor' })}</span>
                          </>
                        :
                          <>
                            <span><Icon color="green" name="plus circle" className={cx(styles.Button, styles.ButtonGreenCustom)} /></span>
                            <span>{t('add_additional_contact', { ns: 'distributor' })}</span>
                          </>
                        }
                    </p>
                    <div className={styles.OpeningHours} unstackable={false} celled={false}>
                      <div>
                        <FieldArray
                          name="openingHours"
                          render={(arrayHelpers) => (
                            values.openingHours.map((el, index) => (
                              <>
                                <div className="opening timepicker-containers">
                                  <div className={styles.InputsContainer_FirstColumn}>
                                    <p className={cx(styles.OpeningHoursHint, 'hint')}>{t('opening_hours', { ns: 'distributor' })}</p>
                                  </div>
                                  <div className={styles.InputsContainer_SecondColumn}>
                                    <TimePicker
                                      name={`openingHours.${index}.openFrom`}
                                      fieldValueSetter={setFieldValue}
                                    />
                                    <p className={cx(styles.OpeningHoursHint, 'hint')}>{t('to', { ns: 'distributor' })}</p>
                                    <TimePicker
                                      name={`openingHours.${index}.openTo`}
                                      fieldValueSetter={setFieldValue}
                                    />
                                  </div>
                                </div>
                                {Object.keys(el.days).map((day, i) => (
                                  <CheckboxInputButton name={`openingHours.${index}.days.${day}.isOpen`} type="checkbox" prefix={day} selected={values.openingHours[index].days[day].isOpen} />
                                ))}
                                <p
                                  className={cx(styles.AddAdditionalHours, 'hint')}
                                    onClick={() => arrayHelpers.push({
                                      openFrom: '',
                                      openTo: '',
                                      days: {
                                        Su: { isOpen: false },
                                        M: { isOpen: false },
                                        Tu: { isOpen: false },
                                        W: { isOpen: false },
                                        Th: { isOpen: false },
                                        F: { isOpen: false },
                                        Sa: { isOpen: false }
                                      }
                                    })
                                    }
                                  >
                                    <span><Icon color="green" name="plus circle" className={cx(styles.Button, styles.ButtonGreenCustom)} /></span>
                                    <span>{t('add_additional_hours', { ns: 'distributor' })}</span>
                                </p>
                              </>
                            ))
                          )}
                        />
                      </div>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <div className={cx(styles.ButtonsContainer, 'btn-container bottom fixed floating full-width')}>
                <span className={styles.ButtonWrapper}>
                  <Button
                    className={cx(styles.Button, styles.Red)}
                    loading={isDeleting}
                    color="red"
                    basic
                    type="button"
                    content={t('delete', { ns: 'buttons' })}
                    onClick={() => {
                      setConfirmOpen(true)
                    }}
                  />
                  <Button
                    className={cx(styles.Button, styles.Green)}
                    loading={isSubmitting}
                    disabled={!isValid || isSubmitting}
                    type="submit"
                    color="green"
                    content={t('save', { ns: 'buttons' })}
                  />
                </span>
              </div>
            </Form>
          )
        }}
      </Formik>
      <GreenbytesModal
        confirmDeleteOpen={confirmOpen}
        setConfirmDeleteOpen={setConfirmOpen}
        text={t('delete_distributor', { ns: 'distributor' })}
        confirmButtonText={'delete'}
        cancelButtonText={'cancel'}
        handleConfirmClick={handleDelete}
      />
    </div>
  )
}

export default EditDistributor