import React, { useEffect, useState, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { Button, Grid, Message, Modal, Popup, Icon } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import { Form, FieldArray } from 'formik'
import TextInput from '../../../UI/Form/TextInput'
import TextAreaInput from '../../../UI/Form/TextAreaInput'
import Ingredient from '../../Ingredient/Ingredient'
import IngredientWithDropdown from '../../Ingredient/IngredientWithDropdown'
import RadioInput from '../../../UI/Form/RadioInput'
import SubMenuItem from '../../SubMenuItem/SubMenuItem'
import AddIngredientButton from '../../Form/Button/AddIngredientButton'
import AddSubMenuItemButton from '../../Form/Button/AddSubMenuItemButton'
import { getLocationArray, getCurrentDistributorProducts, getDistributorsOnboarding, getSubMenuItemArray, makeStockArray, getUnitArray, getLocationId, getAvailableUnits, filterAllergens } from '../../../../shared/utility'
import cx from 'classnames'
import styles from '../../../../assets/styles/modules/menu/AddMenuItem.module.scss'
import CheckboxInput from '../../../UI/Form/CheckboxInput'
import { shouldDisableNextStep } from '../AddMenuItem/utils'
import { PersistFormikValues } from 'formik-persist-values'
import Spinner from '../../../UI/Spinner/Spinner'
import { TYPE_YOUR_OWN_DISTRIBUTOR } from '../../../../store/wordings'
import { getUserId } from '../../../../store/actions/authActions'
import { cloudFunctions } from '../../../../config/firebase'
import { httpsCallable } from 'firebase/functions'
import {
  asyncActionStart,
  asyncActionFinish,
  asyncActionError,
  updateMenuItems
} from '../../../../store/actions'
import { flaskAPIGet } from '../../../../shared/utility'
import GreenbytesModal from '../../../UI/GreenbytesModal/GreenbytesModal'

export function EditShowForm({
  isSubmitting,
  errors,
  values,
  setFieldValue,
  resetForm,
  state,
  initialValues,
  batchItemValidation,
  setIsCombo,
  baseUrl,
  itemNameExists,
  selectedLocation,
  menuItems = [],
  id,
  location,
}) {
  const { user } = useSelector((state) => state.user)
  const { locations } = useSelector((state) => state.location)
  const { menuItem } = useSelector((state) => state.menu)
  const [stock, setStock] = useState([])
  const [distributors, setDistributors] = useState([])
  const [isLoadingDistributors, setIsLoadingDistributors] = useState(true)
  const [distributorProducts, setDistributorProducts] = useState({}) // Object with { distributorId: [productsArray] }
  const ingredientAddedRef = useRef(false)
  const { t } = useTranslation(['menu', 'buttons', 'common']);
  const buttonRef = useRef(null)
  const toggleEnterRef = useRef(false)
  const focusedRef = useRef(true)
  const [confirmArchiveOpen, setConfirmArchiveOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(null)
  const timeoutRef = useRef(null)
  const dispatch = useDispatch()

  const stockArr = makeStockArray(stock, t, values.locationIds)
  const sidesArr = getSubMenuItemArray(menuItems, 'isSubrecipe')
  const allMenuItemsArr = getSubMenuItemArray(menuItems)

  const history = useHistory()
  let { current_step } = useParams()
  const locationArr = getLocationArray(locations)

  useEffect(() => {
    if (state && state.values) {
      // Set formik persisted values to each field
      Object.keys(state.values).forEach((value) => {
        setFieldValue(value, state.values[`${value}`])
      })
    }
  }, [state, setFieldValue])

  useEffect(() => {
    if ((!values.locations) && (values.locationIds)) {
      const locs = []
      values.locationIds.map((locId) => {
        const loc = locationArr.find(({ value }) => value === locId)
        locs[locId] = loc.text
        return null
      })

      values.locations = locs
    }

    const getDistributors = async () => {
      setDistributors((await getDistributorsOnboarding()).concat([TYPE_YOUR_OWN_DISTRIBUTOR(t)]))
      setIsLoadingDistributors(false)
    }

    const getUserStock = async () => {
      const userId = await getUserId()
      const userStock = await flaskAPIGet({ endpoint: `/v2/stock/user/${userId}/location/${selectedLocation}/stock` })
      setStock(userStock)
    }

    getDistributors()
    getUserStock()

    return () => {
      buttonRef.current = null;
      toggleEnterRef.current = null;
    }
  }, [])

  const shouldDisableNext = useMemo(
    () => shouldDisableNextStep(current_step, values, user),
    [current_step, user, values]
  )

  const updateProducts = (distributorId) => {
    getDistributorProducts(distributorId)
    return distributorProducts[`${distributorId}`]?.length ? distributorProducts[`${distributorId}`] : []
  }

  const getDistributorProducts = async (distributorId) => {
    const distributor = distributors.filter(dist => dist.name === distributorId)
    if (!distributorProducts[`${distributorId}`]) {
      const custom = distributor.length && distributor[0].customDistributor
      const data = await getCurrentDistributorProducts(custom ? distributor[0].id : distributorId, custom ? getLocationId(selectedLocation) : null, t)
      setDistributorProducts((prev) => {
        return { ...prev, [distributorId]: data }
      })
    }
  }

  const onChangeIsBatchItem = ({ value: isBatchItem }) => {
    batchItemValidation(isBatchItem)
    values.isBatchItem = isBatchItem
  }

  const getPlaceholder = () => {
    if (values.name !== '') {
      return values.name
    } else {
      if (state && state.menuItem) {
        return state.menuItem.name
      }
      return t('item_name', { ns: 'menu' })
    }
  }

  const getLocationName = (id, locations) => locations && locations.find((el) => el.id === id).text

  const handleArchive = async () => {
    setConfirmArchiveOpen(false)
    setLoading(true)
    try {
      dispatch(asyncActionStart)
      const res = await httpsCallable(cloudFunctions, 'archiveMenuItem')({ id, location: [location] })
      let archivedMenuItem = []
      archivedMenuItem.push(res.data)
      const newMenu = menuItems.map(obj => archivedMenuItem.find(o => o.id === obj.id) || obj)
      dispatch(updateMenuItems(newMenu, selectedLocation.id ? selectedLocation.id : selectedLocation))
      dispatch(asyncActionFinish(t('item_was_archived', { ns: 'menu' })))
      setLoading(false)
      setOpenModal({ header: t('was_archived', { ns: 'menu' }) , description: t('successfully_archived', { item: menuItem.name, ns: 'menu' })})
    } catch (error) {
      dispatch(asyncActionError(error, t('item_could_not_be_archived', { ns: 'menu' })))
      setLoading(false)
      setOpenModal({ header: 'Error' , description: `${t('could_not_be_archived', { item: menuItem.name, ns: 'menu' })} ${JSON.stringify(error)}`})
    }
    timeoutRef.current = setTimeout(() => {
      setOpenModal(null)
      history.push('/menu')
    }, 5000)
  }

  if (isSubmitting || loading) return <Spinner loading content={t('please_wait', { ns: 'common' })} />

  return (
    <>
      {errors.recipes && <Message error content={errors.recipes} />}
      <Form autocomplete="off" name={baseUrl} className="ui form">
        <Grid className={styles.CustomGrid}>
          <Grid.Row
            className={current_step === 'second-step' ? cx(styles.ui, styles.grid, styles.row, styles.Hide) : ''}
          >
            <Grid.Column width={16}>
              { itemNameExists && <Message error content={t('name_already_exists', { name: values.name, ns: 'menu' })} /> }
              <TextInput
                placeholder={values.name ? values.name : t('item_name', { ns: 'menu' })}
                name="name"
                type="text"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={
              current_step === 'second-step'
                ? cx(styles.ui, styles.grid, styles.row, styles.Hide)
                : styles.RadioInputsContainer
            }
          >
            <Grid.Column className={styles.Subtitle}>
              <strong>{t('item_type', { ns: 'menu' })}:</strong>
            </Grid.Column>
            <Grid.Column>
              <RadioInput
                className={styles.ItemType}
                name="type"
                value="single"
                label={t('single_item', { ns: 'menu' })}
                onClick={() => {
                  setIsCombo(false)
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <RadioInput
                className={styles.ItemType}
                name="type"
                value="side"
                label={t('side_extra', { ns: 'menu' })}
                onClick={() => {
                  setIsCombo(false)
                }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={
              current_step === 'second-step'
                ? cx(styles.ui, styles.grid, styles.row, styles.Hide)
                : styles.IngredientsSection
            }
          >
            {values.type !== 'combo' && (
              <>
                <Grid.Column width={5} style={{ margin: '12px 0' }} className={cx(styles.Subtitle, styles.HideMobile)}>
                  {t('ingredients', { ns: 'menu' })}
                </Grid.Column>
                <Grid.Column width={16} className={styles.MobileTitle}>
                  <p>{t('ingredients', { ns: 'menu' })}</p>
                </Grid.Column>
                <Grid.Column className={styles.MobileCheckbox}>
                  <CheckboxInput
                    type="checkbox"
                    toggle
                    name="isBatchItem"
                    label={t('batch_item', { ns: 'menu' })}
                    onChange={onChangeIsBatchItem}
                    isBatchItem={true}
                  />
                  {values.isBatchItem && (
                    <div className="mobile-servings-input">
                      <Grid.Column
                        width={13}
                        textAlign="right"
                        style={{ paddingRight: '8px' }}
                        className={styles.Subtitle}
                      >
                        {t('how_many_servings', { ns: 'menu' })}
                      </Grid.Column>
                      <Grid.Column width={3}>
                        <TextInput className={errors?.servings ? 'error' : ''} name="servings" type="text" />
                      </Grid.Column>
                      <Grid.Column width={1}></Grid.Column>
                    </div>
                  )}
                </Grid.Column>
              </>
            )}
            {values.isBatchItem && (
              <>
                <Grid.Column
                  width={13}
                  textAlign="right"
                  style={{ paddingRight: '8px' }}
                  className={cx(styles.Subtitle, styles.DesktopServingsInput)}
                >
                  {t('how_many_servings', { ns: 'menu' })}&nbsp;&nbsp;
                </Grid.Column>
                <Grid.Column width={3} className={styles.DesktopServingsInput}>
                  <TextInput className={errors?.servings ? 'error' : ''} name="servings" type="text" />
                </Grid.Column>
                <Grid.Column width={1} className={styles.DesktopServingsInput}></Grid.Column>
              </>
            )}
          </Grid.Row>
          {values.type !== 'combo' && (
            <>
              <FieldArray
                name="ingredients"
                render={(arrayHelpers) => (
                  <>
                    {Object.values(values.ingredients).map((ingredient, index) => {
                      const unitArr = getUnitArray(getAvailableUnits(ingredient))
                      return current_step === 'second-step' ? (
                        <IngredientWithDropdown
                          key={`withdropdown-${ingredient.id}`}
                          index={index}
                          distributors={distributors}
                          unitArr={unitArr}
                          fieldArrayHelper={arrayHelpers}
                          fieldValueSetter={setFieldValue}
                          values={values.ingredients}
                          updateProducts={updateProducts}
                          isLoadingDistributors={isLoadingDistributors}
                          currentStep={current_step}
                        />
                      ) : (
                        <Ingredient
                          key={`Ingredient-${index}`}
                          index={index}
                          stockArr={stockArr}
                          unitArr={unitArr}
                          fieldArrayHelper={arrayHelpers}
                          fieldValueSetter={setFieldValue}
                          values={values.ingredients}
                          showColumns={current_step === 'third-step'}
                          unitError={errors.ingredients && errors.ingredients[index]}
                          isSubmitting={isSubmitting}
                          buttonRef={buttonRef}
                          toggleEnterRef={toggleEnterRef}
                          editFlow={true}
                        />
                      )
                    })}
                    <Grid.Row
                      style={{ paddingTop: '0px' }}
                      className={
                        current_step === 'second-step' ? cx(styles.ui, styles.grid, styles.row, styles.Hide) : ''
                      }
                    >
                      <Grid.Column width={16}>
                        <AddIngredientButton fieldArrayHelper={arrayHelpers} clickable={current_step === 'third-step'} buttonRef={buttonRef} toggleEnterRef={toggleEnterRef} focusedRef={focusedRef} ingredientAddedRef={ingredientAddedRef} />
                      </Grid.Column>
                    </Grid.Row>
                  </>
                )}
              />
              {current_step !== 'second-step' && (
                <FieldArray
                  name="subrecipes"
                  render={(arrayHelpers) => (
                    <>
                      <Grid.Row style={{ paddingTop: '0px' }}>
                        <Grid.Column width={16} className={styles.Subtitle}>
                          <strong>{t('sides_extras', { ns: 'menu' })}</strong>
                        </Grid.Column>
                      </Grid.Row>
                      {Object.keys(values.subrecipes).map((subrecipe, i) => (
                        <SubMenuItem
                          key={`side-extras-${i}`}
                          index={subrecipe}
                          items={sidesArr}
                          fieldArrayHelper={arrayHelpers}
                          fieldValueSetter={setFieldValue}
                          values={values.subrecipes}
                          buttonRef={buttonRef}
                          toggleEnterRef={toggleEnterRef}
                        />
                      ))}
                      { sidesArr.length === 0 &&
                        <Grid.Row style={{ paddingTop: '0px' }}>
                          <Grid.Column width={16}>
                            <p className={styles.NoSubrecipes}>{t('no_subrecipes', { ns: 'menu' })}</p>
                          </Grid.Column>
                        </Grid.Row>
                      }
                      <Grid.Row style={{ paddingTop: '0px' }}>
                        <Grid.Column width={16}>
                          <AddSubMenuItemButton label={t('add_side_extra', { ns: 'menu' })} fieldArrayHelper={arrayHelpers} buttonRef={buttonRef} toggleEnterRef={toggleEnterRef} disabled={sidesArr.length === 0} focusedRef={focusedRef} />
                        </Grid.Column>
                      </Grid.Row>
                    </>
                  )}
                />
              )}
            </>
          )}
          {values.type === 'combo' && current_step !== 'second-step' && (
            <FieldArray
              name="subrecipes"
              render={(arrayHelpers) => (
                <>
                  <Grid.Row style={{ paddingTop: '0px' }}>
                    <Grid.Column width={16} className={styles.Subtitle}>
                      {t('included_menu_items', { ns: 'menu' })}
                    </Grid.Column>
                  </Grid.Row>
                  {Object.keys(values.subrecipes).map((recipe, i) => (
                    <SubMenuItem
                      key={i}
                      index={recipe}
                      items={allMenuItemsArr}
                      values={values.subrecipes}
                      fieldArrayHelper={arrayHelpers}
                      fieldValueSetter={setFieldValue}
                      buttonRef={buttonRef}
                      toggleEnterRef={toggleEnterRef}
                    />
                  ))}
                  { allMenuItemsArr.length === 0 &&
                    <Grid.Row style={{ paddingTop: '0px' }}>
                      <Grid.Column width={16}>
                        <p className={styles.NoSubrecipes}>{t('no_subrecipes', { ns: 'menu' })}</p>
                      </Grid.Column>
                    </Grid.Row>
                  }
                  <Grid.Row style={{ paddingTop: '0px' }}>
                    <Grid.Column width={16}>
                      <AddSubMenuItemButton label={t('add_menu_item', { ns: 'menu' })} fieldArrayHelper={arrayHelpers} buttonRef={buttonRef} toggleEnterRef={toggleEnterRef} disabled={allMenuItemsArr.length === 0} focusedRef={focusedRef} />
                    </Grid.Column>
                  </Grid.Row>
                </>
              )}
            />
          )}
          { values.allergens && filterAllergens(values.allergens).length > 0 &&
            <Grid.Row>
              <Grid.Column width={16} style={{ padding: 0 }}>
                <p className={styles.AllergensTitle}>Allergens</p>
                <div className={styles.PillsContainer}>
                  { filterAllergens(values.allergens).map((allergen) => <span className={styles.Pill}>{allergen}</span> ) }
                  <Popup
                    className={styles.AllergensPopup}
                    position="bottom right"
                    trigger={ <Icon className={styles.AllergensTrigger} color="grey" name="info circle" /> }
                  >
                    <p style={{ marginBottom: '15px' }}>The ingredients listed in this dish may contain common allergens. Please familiarize yourself with potential allergens and consider cross-contamination with other allergens.</p>
                    <p>This allergen information was generated using AI technology and is intended to provide a general guideline. However, the results may not be 100% accurate.</p>
                  </Popup>
                </div>
              </Grid.Column>
            </Grid.Row>
          } 
          <Grid.Row
            style={{ paddingTop: '0px' }}
            className={current_step === 'second-step' ? cx(styles.ui, styles.grid, styles.row, styles.Hide) : ''}
          >
            <Grid.Column width={16}>
              <TextAreaInput name="notes" placeholder={t('notes', { ns: 'menu' })} onFocus={() => focusedRef.current = false} />
            </Grid.Column>
          </Grid.Row>
          { current_step !== 'second-step' &&
            <Grid.Row>
              <Grid.Column width={16} textAlign="right">
                <Button
                  className={cx(styles.Button, styles.Green)}
                  floated="right"
                  type="button"
                  basic
                  color="orange"
                  size="big"
                  onClick={() => {
                    setConfirmArchiveOpen(true)
                  }}
                  content={t('archive', { ns: 'buttons' })}
                />
              </Grid.Column>
            </Grid.Row>
          }
        </Grid>
        <div className={cx(styles.ButtonsContainer, 'btn-container bottom fixed floating full-width')}>
          <span className={styles.ButtonWrapper}>
            <Button
              className={cx(styles.Button, styles.Green)}
              floated="left"
              type="button"
              basic
              color="orange"
              size="big"
              onClick={() => {
                resetForm(initialValues)
                localStorage.removeItem(baseUrl)
                history.push('/menu')
              }}
              content={t('cancel', { ns: 'buttons' })}
            />
            {current_step === 'third-step' || values.type === 'combo' ? (
              <Button className={cx(styles.Button, styles.Green)} loading={isSubmitting} type="submit" color="green" content={t('save', { ns: 'buttons' })} floated="right" size="big" disabled={itemNameExists} />
            ) : (
              <Button
                className={cx(styles.Button, styles.Green)}
                type="button"
                color="green"
                content={t('next', { ns: 'buttons' })}
                floated="right"
                size="big"
                onClick={() => {
                  const nextStep = current_step === 'first-step' ? 'second-step' : 'third-step'
                  history.push(`${baseUrl}/${nextStep}`)
                }}
                disabled={shouldDisableNext || itemNameExists || (current_step === 'first-step' && !ingredientAddedRef.current)}
              />
            )}
          </span>
        </div>
        <PersistFormikValues name={baseUrl} />
      </Form>
      <GreenbytesModal
        confirmDeleteOpen={confirmArchiveOpen}
        setConfirmDeleteOpen={setConfirmArchiveOpen}
        text={t('archive_menu_item', { ns: 'menu' })}
        confirmButtonText={'archive'}
        cancelButtonText={'cancel'}
        handleConfirmClick={handleArchive}
      />
      <Modal
        open={openModal !== null}
        closeOnDimmerClick={openModal && !openModal.header.includes('deleted')}
        closeIcon={openModal && !openModal.header.includes('deleted')}
        onClose={() => {
          clearTimeout(timeoutRef.current)
          setOpenModal(null)
        }}
      >
        <Modal.Header className={styles['Modal-Header']}>
          {openModal && openModal.header}
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p className={styles['Modal-Description']}>
              {openModal && openModal.description}
            </p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions className={cx(styles.Modal, styles.Actions)}>
          <Button 
            onClick={() => {
              setOpenModal(false)
              history.push('/menu')
            }}
            className={styles['Confirm-Button']}
            positive
          >
            {t('go_to_menu', { ns: 'menu' })}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}