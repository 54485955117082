import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button } from 'semantic-ui-react'
import Spinner from '../UI/Spinner/Spinner'
import Distributor from '../../components/Distributors/Distributor/Distributor'
import { updateDistributors, setPageTitle } from '../../store/actions'
import { flaskAPIGet } from '../../shared/utility'
import cx from 'classnames'

import styles from '../../assets/styles/modules/distributors/DistributorList.module.scss'
import { getUserId } from '../../store/actions/authActions'

const Distributors = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation(['distributor', 'common'])

  const { distributors } = useSelector((state) => state.distributor)
  const { loading } = useSelector((state) => state.async)
  const [firstLoad, setFirstLoad] = React.useState(true)

  React.useEffect(() => {
    dispatch(setPageTitle(t('distributors', { ns: 'distributor' })))
    const loadDistributors = async () => {
      const userId = await getUserId()
      const distributorsData = await flaskAPIGet({ endpoint: `/v2/distributors/user/${userId}?mode=user` })
      dispatch(updateDistributors(distributorsData))
      setFirstLoad(false)
    }

    if (firstLoad && !distributors.length) {
      loadDistributors()
    }
    
  }, [dispatch])

  if (loading) return <Spinner content={t('loading_distributors', { ns: 'distributor' })} />

  const sortDistributors = (distributors) => {
    const incompleteInfo = distributors.filter((dist) => dist.email === '' && !dist.hidden)
    const completeInfo = distributors.filter((dist) => dist.email !== '')
    return incompleteInfo.concat(completeInfo)
  }

  const DistributorList = () => {
    if (distributors.length === 0) {
      return (
        <p className="align-center">
          {t('nothing_here', { ns: 'common' })}
          <br />
          {t('add_your_distributors', { ns: 'distributor' })}
        </p>
      )
    } else {
      return sortDistributors(distributors).map((distributor) => {
        return !distributor.deleted && <Distributor key={distributor.id} distributor={distributor} history={history} path={props.match.path} />
      })
    }
  }
  
  return (
    <>
      <div className={styles.DistributorList}>
        <DistributorList />
        <div className={cx(styles.ButtonsContainer, 'btn-container bottom fixed floating full-width')}>
          <span className={styles.ButtonWrapperCentered}>
            <Button
              className={cx(styles.Button, styles.Green)}
              color="green"
              onClick={() => history.push('distributors/add')
            }>
              {t('add_distributor', { ns: 'distributor' })}
            </Button>
          </span>
        </div>
      </div>
    </>
  )
}

export default Distributors
