import * as actionTypes from './actionTypes'
import { firestore } from '../../config/firebase'
import { doc, updateDoc } from 'firebase/firestore'
import { getUserId } from './authActions'

export const updateLocations = (locations) => {
  return {
    type: actionTypes.UPDATE_LOCATIONS_SUCCESS,
    locations: locations,
  }
}

export const setSelectedLocation = (selectedLocation) => {
  return {
    type: actionTypes.SET_SELECTED_LOCATION,
    selectedLocation,
  }
}

export const listenToLocation = (location) => {
  return {
    type: actionTypes.GET_LOCATION_SUCCESS,
    location: location,
  }
}

export const setLocationIds = (locationIds) => {
  return {
    type: actionTypes.GET_LOCATION_IDS,
    locationIds: locationIds,
  }
}

export function getLocationFromFirestore(locId) {
  if (locId) {
    return doc(firestore, `users/${getUserId()}/locations`, locId)
  }
  return null
}

export async function setLocationImage(locationId, downloadURL) {
  try {
    return await updateDoc(doc(firestore, `users/${getUserId()}/locations`, locationId), {
      photoURL: downloadURL,
    })
  } catch (error) {
    throw error
  }
}
