import React from 'react'
import { Button, Modal, Message } from 'semantic-ui-react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import styles from '../../../../assets/styles/modules/UI/GreenbytesModal.module.scss'
import TextInput from '../../../UI/Form/TextInput'
import CustomDropdown from '../../../UI/Form/CustomDropdown'
import { Formik, Form } from 'formik'
import { cloudFunctions } from '../../../../config/firebase'
import { httpsCallable } from 'firebase/functions'
import { getUserIdToken, getUserId } from '../../../../store/actions/authActions'
import * as Yup from 'yup'

const AddInfoModal = ({
  confirmDeleteOpen = false,
  setConfirmDeleteOpen,
  text,
  headerText = null,
  distributor,
  selectedLocation,
  itemId,
  missingUnit = false,
  missingEmail = false
}) => {
  const { t } = useTranslation(['buttons', 'validationMessages', 'orders'])

  const defaultUnits = [
    { key: 'g', value: 'g', text: 'g' },
    { key: 'kg', value: 'kg', text: 'kg' },
    { key: 'L', value: 'L', text: 'L' },
    { key: 'mL', value: 'mL', text: 'mL' },
    { key: 'pcs', value: 'pcs', text: 'pcs' },
  ]

  const formFields = {
    email: '',
    unit: ''
  }

  const validationSchema = (missingEmail, missingUnit) => {
    let schemaAttributes = {}
    
    if (missingUnit) {
      schemaAttributes.unit = Yup.object()
        .nullable(true)
        .test('validate', t('unit', { ns: 'validationMessages' }), (unit) => ['kg', 'g', 'L', 'mL', 'pcs'].includes(unit?.value)) 
    }

    if (missingEmail) {
        schemaAttributes.email = Yup.string().email(t('valid_email', { ns: 'validationMessages' })).required(t('email', { ns: 'validationMessages' }))
    }

    return Yup.object().shape(schemaAttributes)
}

  return (
    <Modal
      className={styles['ModalContainer']}
      open={confirmDeleteOpen}
      onClose={() => setConfirmDeleteOpen(false)}
    >
      <Modal.Header className={styles['ModalHeader']}></Modal.Header>
      <Modal.Content className={cx(styles['Ui'], styles['Modal'], styles['Content'], styles['AddInfoModal'])}>
        <Modal.Description>
          { headerText &&
            <h2 className={styles['ModalHeaderText']}>
              {headerText}
            </h2>
          }
          <p className={styles['ModalDescription']}>
            {text}
          </p>
          <Formik
            initialValues={formFields}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
              try {
                if (values.unit) {
                  let user_id = await getUserId()
                  let user_token = await getUserIdToken()
                  const payload = {
                    endpoint: `/stock/user/${user_id}/location/${selectedLocation.id}/stock/${itemId}/units`,
                    user_token,
                    payload: {
                      unit: values.unit ? values.unit.value : ''
                    }
                  }
                  await httpsCallable(
                    cloudFunctions,
                    'flaskAPIPostRequest'
                  )({ ...payload })  
                }
                
                if (values.email) {
                  await httpsCallable(
                    cloudFunctions,
                    'updateDistributor'
                  )({
                    id: distributor.id,
                    values: {...distributor, email: values.email}
                  })
                }
                setSubmitting(false)
                setConfirmDeleteOpen(false)
              } catch (error) {
                setErrors({ content: error.message })
                setSubmitting(false)
                setConfirmDeleteOpen(false)
              }
            }}
            validationSchema={validationSchema(missingEmail, missingUnit)}
          >
            {({ isSubmitting, isValid, values, errors, setFieldValue }) => (
                <Form autocomplete="off" className={cx(styles.Form, 'ui form')}>
                  { errors.content && <Message error content={errors.content} style={{ marginBottom: 10 }} /> }
                  { missingUnit &&
                    <CustomDropdown
                      name={'unit'}
                      placeholder={values.unit ? values.unit.text : t('unit', { ns: 'orders' })}
                      options={defaultUnits}
                      fieldValueToSet={'unit'}
                      fieldValueSetter={setFieldValue}
                      compact
                    />
                  }
                  { missingEmail &&
                    <TextInput name="email" placeholder={t('email', { ns: 'distributor' })} type="email" />
                  }
                  <div className={cx(styles.ButtonsContainer, styles.AddInfoModal, 'btn-container bottom fixed floating full-width')}>
                    <span className={styles.ButtonWrapper}>
                      <Button
                        className={cx(styles.Button, styles.Orange)}
                        floated="left"
                        color="orange"
                        basic
                        type="button"
                        content={t('cancel', { ns: 'buttons' })}
                        size="big"
                        onClick={() => {
                          setConfirmDeleteOpen(false)
                        }}
                      />
                      <Button
                        className={cx(styles.Button, styles.Green)}
                        loading={isSubmitting}
                        type="submit"
                        color="green"
                        content={t('update', { ns: 'buttons' })}
                      />
                    </span>
                  </div>
                </Form>
              )
            }
          </Formik>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  )
}

export default AddInfoModal