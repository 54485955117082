import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import useFirestoreDoc from '../../../hooks/useFirestoreDoc'
import { cloudFunctions } from '../../../config/firebase'
import { httpsCallable } from 'firebase/functions'
import { Button, Label, Grid } from 'semantic-ui-react'
import { Form, Formik } from 'formik'
import {
  getLocationFromFirestore,
  listenToLocation,
  setPageTitle,
  receivedPos,
} from '../../../store/actions'
import TextInput from '../../UI/Form/TextInput'
import * as Yup from 'yup'
import ProfileImage from '../../ProfileImage/ProfileImage'
import placeholderSrc from '../../../assets/images/locations/placeholder.svg'
import Spinner from '../../UI/Spinner/Spinner'
import styles from '../../../assets/styles/modules/locations/Location.module.scss'
import SelectInput from '../../UI/Form/SelectInput'
import { Toaster } from 'react-hot-toast'
import { updateUserFlags, kitchenTypeOptions } from '../../../shared/utility'
import GreenbytesModal from '../../UI/GreenbytesModal/GreenbytesModal'
import cx from 'classnames'

const EditLocation = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation(['location', 'validationMessages', 'buttons', 'common'])

  const { location } = useSelector((state) => state.location)
  const { loading } = useSelector((state) => state.async)
  const { user } = useSelector((state) => state.user)

  const [isDeleting, setIsDeleting] = useState(false)
  const [deleteError, setDeleteError] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [confirmCancel, setConfirmCancel] = useState(false)
  const [isCancelingPremium, setIsCancelingPremium] = useState(false)
  const [cancelButtonDisabled, setCancelButtonDisabled] = useState(null)
  const [canCancelingPremium, setCanCancelingPremium] = useState(
    location?.stripe_start && !location?.stripe_canceled_at
  )
  const [canDeleteLocation, setCanDeleteLocation] = useState(!location?.stripe_start || !location?.isActive)
  const [loadingChannels, setLoadingChannels] = useState(false)
  const [openActivateModal, setOpenActivateModal] = useState(false)

  const msgDescriptionInactive = t('can_not_inactivate_location', { ns: 'location' })

  const [channels, setChannels] = useState([])

  useEffect(() => {
    setCanCancelingPremium(location?.stripe_start && !location?.stripe_canceled_at)
    setCanDeleteLocation(!location?.stripe_start || !location?.isActive)
  }, [location])

  useEffect(() => {
    dispatch(setPageTitle(t('edit_location', { ns: 'location' })))
  }, [dispatch])

  useFirestoreDoc({
    query: () => getLocationFromFirestore(props.match.params.id),
    data: (location) => dispatch(listenToLocation(location)),
    deps: [dispatch],
  })

  if (!props.match.params.id) {
    return <Redirect to="/locations" />
  }

  if (loading || !location) {
    return <Spinner content={t('please_wait', { ns: 'common' })} />
  }

  const initialValues = {
    name: location.name,
    kitchenType: location.kitchenType ? location.kitchenType : '',
    street: location.street,
    postcode: location.postcode,
    place: location.place,
    phone: location.phone || '',
    email: location.email,
    kennitala: location.kennitala || '',
    isActive: location.isActive !== false ? true : false,
    locationPhotoURL: location.locationPhotoURL ? location.locationPhotoURL : '',
    channels: location.channels || [],
  }
  const validationSchema = Yup.object({
    name: Yup.string().required(t('kitchen_name', { ns: 'validationMessages' })),
    kitchenType: Yup.string().required(t('kitchen_type', { ns: 'validationMessages' })),
    street: Yup.string().required(t('street', { ns: 'validationMessages' })),
    postcode: Yup.string().required(t('postcode', { ns: 'validationMessages' })),
    place: Yup.string().required(t('place', { ns: 'validationMessages' })),
    email: Yup.string().email(t('valid_email', { ns: 'validationMessages' })).required(t('email', { ns: 'validationMessages' })),
    kennitala: Yup.string().required(t('kennitala', { ns: 'validationMessages' })),
    isActive: Yup.bool().required(t('status', { ns: 'validationMessages' })),
  })

  async function handleDelete() {
    setIsDeleting(true)
    setConfirmOpen(false)
    try {
      await httpsCallable(cloudFunctions, 'deleteLocation')({ locationId: location.id })
      history.push('/locations')
    } catch (error) {
      setDeleteError(error.message)
      setIsDeleting(false)
      setConfirmOpen(false)
    }
  }

  const getHasEqualValues = (values) => {
    return _.isEqual(initialValues, values)
  }

  const handleOnSubmit = async (values, { setSubmitting }) => {
    const locationId = location.id
    const { type, ...rest } = values
    const shouldUpdateLocation = !getHasEqualValues({...rest})

    if (!initialValues.isActive && values.isActive) {
      setOpenActivateModal(true)
      return
    }

    if (shouldUpdateLocation) {
      await httpsCallable(
        cloudFunctions,
        'updateLocation'
      )({
        locationId,
        values: {...rest},
      })

      if (!user.hasEditedLocation) {
        await updateUserFlags({
          hasEditedLocation: true,
          hasCheckedSettings: false,
        })
      }
    }
    setSubmitting(false)
    history.push('/locations')
  }

  return (
    <div className={styles.EditLocationContainer}>
      <Formik initialValues={initialValues} onSubmit={handleOnSubmit} validationSchema={validationSchema}>
        {({ isSubmitting, isValid, values, errors, setFieldValue, setSubmitting }) => (
          <Form autocomplete="off" className="ui form">
            {errors.location && <Label basic color="red" content={errors.location} style={{ marginBottom: 10 }} />}
            {deleteError && <Label basic color="red" content={deleteError} style={{ marginBottom: 10 }} />}
            <Grid stackable={true}>
              <Grid.Row>
                <Grid.Column width={5} className={styles.ImageUploadContainer} vertical-align="middle">
                  <ProfileImage
                    placeholder={placeholderSrc}
                    currentImage={values.locationPhotoURL}
                    fieldName="locationPhotoURL"
                    fieldValueSetter={setFieldValue}
                    disabled={isSubmitting || isDeleting}
                  />
                </Grid.Column>
                <Grid.Column width={11}>
                  <TextInput
                    name="name"
                    textLabel={t('kitchen_name', { ns: 'location' })}
                    placeholder={t('kitchen_name', { ns: 'location' })}
                    type="text"
                    disabled={isSubmitting || isDeleting}
                  />
                  <p className={styles.KitchenTypeLabel}>{t('kitchen_type', { ns: 'location' })}</p>
                  <SelectInput
                    name={'kitchenType'}
                    placeholder={t('kitchen_type', { ns: 'location' })}
                    options={kitchenTypeOptions}
                    fieldValueSetter={setFieldValue}
                    fieldValueToSet={'type'}
                    disabled={isSubmitting || isDeleting}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <TextInput
                    name="street"
                    textLabel={t('address', { ns: 'location' })}
                    placeholder={t('street', { ns: 'location' })}
                    type="text"
                    disabled={isSubmitting || isDeleting}
                  />
                  <TextInput
                    name="postcode"
                    placeholder={t('postcode', { ns: 'location' })}
                    type="text"
                    className={styles.PostcodeField}
                    disabled={isSubmitting || isDeleting}
                  />
                  <TextInput
                    name="place"
                    placeholder={t('place', { ns: 'location' })}
                    type="text"
                    className={styles.PlaceField}
                    disabled={isSubmitting || isDeleting}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={16}>
                  <TextInput
                    name="kennitala"
                    placeholder={t('id_number', { ns: 'location' })}
                    type="kennitala"
                    disabled={isSubmitting || isDeleting}
                    textLabel={t('id_number', { ns: 'location' })}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={5}>
                  <TextInput
                    className='left-icon-input'
                    name="phone"
                    icon="phone"
                    iconPosition="left"
                    placeholder={t('phone', { ns: 'location' })}
                    type="text"
                    disabled={isSubmitting || isDeleting}
                  />
                </Grid.Column>
                <Grid.Column width={11}>
                  <TextInput
                    className='left-icon-input'
                    name="email"
                    icon="envelope"
                    iconPosition="left"
                    placeholder={t('email', { ns: 'location' })}
                    type="email"
                    disabled={isSubmitting || isDeleting}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <div className={cx(styles.EditButtonsContainer, 'btn-container bottom fixed floating full-width')}>
              <span className={styles.ButtonWrapper}>
                {canDeleteLocation && (
                  <Button
                    className={cx(styles.Button, styles.Orange)}
                    loading={isDeleting}
                    floated="left"
                    color="orange"
                    basic
                    type="button"
                    content={t('delete', { ns: 'buttons' })}
                    size="big"
                    onClick={() => {
                      setConfirmOpen(true)
                    }}
                  />
                )}

                {canCancelingPremium && (
                  <Button
                    className={cx(styles.Button, styles.Orange)}
                    floated="left"
                    color="orange"
                    basic
                    type="button"
                    content={t('cancel', { ns: 'buttons' })}
                    size="big"
                    onClick={(e) => {
                      if(getHasEqualValues(values)){
                        history.push('/locations')
                      }else{
                        setCancelButtonDisabled(e)
                        setConfirmCancel(true)
                      }
                    }}
                  />
                )}
                <Button
                  className={cx(styles.Button, styles.Green)}
                  loading={isSubmitting}
                  disabled={!isValid || isSubmitting || isDeleting}
                  type="submit"
                  color="green"
                  content={t('save', { ns: 'buttons' })}
                  floated="right"
                  size="big"
                />
              </span>
            </div>
          </Form>
        )}
      </Formik>
      <GreenbytesModal
        confirmDeleteOpen={confirmOpen}
        setConfirmDeleteOpen={setConfirmOpen}
        headerText={t('want_to_delete', { ns: 'location' })}
        text={t('assigned_deleted', { ns: 'location' })}
        confirmButtonText={'delete'}
        cancelButtonText={'cancel'}
        handleConfirmClick={handleDelete}
      />
      <GreenbytesModal
        confirmDeleteOpen={confirmCancel}
        setConfirmDeleteOpen={setConfirmCancel}
        headerText={t('want_to_cancel', { ns: 'location' })}
        text={t('changes_lost', { ns: 'location' })}
        confirmButtonText={'ok'}
        cancelButtonText={'cancel'}
        handleConfirmClick={() => history.push('/locations')}
      />
      <Toaster />
    </div>
  )
}

export default EditLocation
