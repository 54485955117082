import * as Yup from 'yup'

export const getPayload = (data) => {
  const { onboarding, ...rest } = data

  const onboardingData = Object.values(onboarding).map((item) => {
    let { name, generic_name, shouldBeChecked, product, itemNumber, id_product_onboarding, distributor, customDistributor, typed_product, id_distributor_onboarding, ...rest } = item
    let genericName = (shouldBeChecked || customDistributor) ? product : id_product_onboarding
    genericName = ((itemNumber !== undefined) && (itemNumber !== '')) ? `${genericName} - Item Number: ${itemNumber}` : genericName
    let parsedItem = {
      generic_name: customDistributor ? data.name : genericName ? genericName : generic_name,
      id_product_onboarding: (shouldBeChecked || customDistributor) ? null : id_product_onboarding,
      shouldBeChecked: !!shouldBeChecked,
      customDistributor: !!customDistributor,
      typed_product: customDistributor ? product : null,
      id_distributor_onboarding: !id_distributor_onboarding ? !distributor ? 'none' : distributor : id_distributor_onboarding,
      ...rest,
    }
    return { ...parsedItem }
  })

  return { ...rest, onboarding: onboardingData }
}

const hasDistributorAndProduct = (distributor, product) => distributor && product

export const shouldDisableSaveButton = (values) =>
  Object.values(values.onboarding).some(
    (item) => !hasDistributorAndProduct(item.id_distributor_onboarding, item.id_product_onboarding) && !item.product
  )

export const shouldDisableSaveEditButton = (values) =>
  Object.values(values.onboarding).some(
    (item) =>
      !hasDistributorAndProduct(item.id_distributor_onboarding, item.id_product_onboarding) && !item.product && !item.generic_name
  )

export const stockItemSchema = (t) => {
  let schemaAttributes = {
    name: Yup.string().nullable(true).required(t('item_name', { ns: 'validationMessages' })),
    amount: Yup.number().typeError(t('invalid_number', { ns: 'validationMessages' })).min(0, t('below_zero', { ns: 'validationMessages' })).required(t('amount', { ns: 'validationMessages' })),
    unit: Yup.string().nullable(true).required(t('unit', { ns: 'validationMessages' })),
    onboarding: Yup.array().of(
      Yup.object().shape({
        id_distributor_onboarding: Yup.string().notRequired(t('distributor_name', { ns: 'validationMessages' })),
        id_product_onboarding: Yup.string()
          .nullable(true)
          .notRequired()
          .test('validate', t('product', { ns: 'validationMessages' }), (val) => val === null || val !== ''),
        typed_product: Yup.string()
          .nullable(true)
          .notRequired()
          .test('validate', t('product', { ns: 'validationMessages' }), (val) => val === null || val !== '') 
      })
    ),
    locationIds: Yup.array().min(1, t('select_location', { ns: 'validationMessages' }))
  }
  return Yup.object().shape(schemaAttributes)
}