import * as React from 'react'
import { Redirect, useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Button, Grid, Message, Modal } from 'semantic-ui-react'
import * as Yup from 'yup'
import { Formik, Form, FieldArray } from 'formik'
import TextInput from '../../UI/Form/TextInput'
import { getUserProfileFromFirestore, listenToUserProfile } from '../../../store/actions/userActions'
import { updateLocations } from '../../../store/actions/locationActions'
import { setPageTitle, asyncActionStart, asyncActionFinish, asyncActionError } from '../../../store/actions'
import useFirestoreDoc from '../../../hooks/useFirestoreDoc'
import useFirestoreCollection from '../../../hooks/useFirestoreCollection'
import useFetchLocation from '../../../hooks/useFetchLocation'
import { cloudFunctions } from '../../../config/firebase'
import { httpsCallable } from 'firebase/functions'
import { getSubMenuItemArray, flaskAPIGet } from '../../../shared/utility'
import { getUserId } from '../../../store/actions/authActions'
import TextAreaInput from '../../UI/Form/TextAreaInput'
import SelectInput from '../../UI/Form/SelectInput'
import Spinner from '../../UI/Spinner/Spinner'
import SubMenuItem from '../SubMenuItem/SubMenuItem'
import AddSubMenuItemButton from '../Form/Button/AddSubMenuItemButton'
import styles from '../../../assets/styles/modules/menu/AddMenuItem.module.scss'
import LocationMultiselect from '../../UI/Form/LocationMultiselect'
import GreenbytesModal from '../../UI/GreenbytesModal/GreenbytesModal'
import { updateMenuItems } from '../../../store/actions'
import cx from 'classnames'

const EditCombo = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = useParams()
  const { fetchLocations } = useFetchLocation()
  const { t } = useTranslation(['validationMessages', 'common', 'menu', 'buttons'])

  // @todo Add location handling
  const [menuItem, setMenuItem] = React.useState(null)
  const { menuItems } = useSelector((state) => state.menu)
  const { user } = useSelector((state) => state.user)
  const { selectedLocation, locationArray } = useSelector((state) => state.location)
  const { loading, error, message } = useSelector((state) => state.async)

  const [confirmArchiveOpen, setConfirmArchiveOpen] = React.useState(false)
  const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false)
  const [openModal, setOpenModal] = React.useState(null)
  const timeoutRef = React.useRef(null)
  const buttonRef = React.useRef(null)
  const [userProducts, setUserProducts] = React.useState([])
  const [isLoadingUserProducts, setIsLoadingUserProducts] = React.useState(false)
  const [menuItemName, setMenuItemName] = React.useState('')
  const itemAlreadyExists = React.useRef(false)
  const menuItemOriginalName = React.useRef(null)
  const toggleEnterRef = React.useRef(false)
  const focusedRef = React.useRef(true)

  React.useEffect(() => {
    const loadData = async () => {
      const userId = await getUserId()
      const locationId = selectedLocation && selectedLocation.id ? selectedLocation.id : selectedLocation
      const recipeData = await flaskAPIGet({ endpoint: `/v2/menu/user/${userId}/location/${locationId}/recipes/${id}` })
      setMenuItem(recipeData)
      setMenuItemName(recipeData?.name)
      menuItemOriginalName.current = recipeData.name
    }

    loadData()
  }, [])

  React.useEffect(() => {
    dispatch(setPageTitle(t('edit_combo', { ns: 'menu' })))
    fetchLocations(dispatch)
  }, [dispatch])


  useFirestoreDoc({
    query: () => getUserProfileFromFirestore(),
    data: (user) => dispatch(listenToUserProfile(user)),
    deps: [dispatch],
  })


  React.useEffect(() => {
    return () => {
      buttonRef.current = null;
      toggleEnterRef.current = null;
    }
  }, [])

  const getLocationName = (id, locations) => locations && locations.find((el) => el.id === id).text

  if (!props.match.params.id) return <Redirect to="/menu" />

  if (!selectedLocation) return <Redirect to="/menu" />

  if (loading || !menuItem) return <Spinner content={t('please_wait', { ns: 'common' })} />

  const ingredientArrayFormat = Object.values(menuItem.ingredients)
  const subrecipesArrayFormat = Object.values(menuItem.subrecipes)

  const initialValues = {
    type: 'combo',
    name: menuItem.name,
    ingredientIds: [],
    ingredients: ingredientArrayFormat,
    subrecipeIds: menuItem.subrecipeIds,
    subrecipes: subrecipesArrayFormat,
    notes: menuItem.notes,
    isBatchItem: menuItem.isBatchItem || false,
    servings: menuItem.servings || 0,
    locationIds: [selectedLocation.id ? selectedLocation.id : selectedLocation],
    locations: {[selectedLocation.id ? selectedLocation.id : selectedLocation]: getLocationName(selectedLocation.id ? selectedLocation.id : selectedLocation, locationArray)}
  }

  const validationSchema = Yup.object({
    name: Yup.string().required(t('item_name', { ns: 'validationMessages' })),
    subrecipes: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().required(t('item', { ns: 'validationMessages' })),
        servings: Yup.number()
          .typeError(t('number', { ns: 'validationMessages' }))
          .min(0, t('too_low', { ns: 'validationMessages' }))
          .required(t('number_of_servings', { ns: 'validationMessages' })),
      })
    ),
  })

  const menuItemNameExists = (name) => {
    if (!menuItems) return false
    const filteredMenus = menuItems.filter(item => item.name !== menuItemOriginalName.current).map(e => e.name.toUpperCase())
    return filteredMenus.includes(name.toUpperCase())
  }

  const allMenuItemsArr = getSubMenuItemArray(menuItems)

  async function handleArchive() {
    setConfirmArchiveOpen(false)
    dispatch(asyncActionStart())
    try {
      const res = await httpsCallable(
        cloudFunctions,
        'archiveMenuItem'
      )({
        id: props.match.params.id,
        locationId: selectedLocation ? [selectedLocation.id] : null,
      })
      let archivedMenuItem = []
      archivedMenuItem.push(res.data)
      const newMenu = menuItems.map(obj => archivedMenuItem.find(o => o.id === obj.id) || obj)
      dispatch(updateMenuItems(newMenu, selectedLocation.id ? selectedLocation.id : selectedLocation))
      dispatch(asyncActionFinish(t('item_was_archived', { ns: 'menu' })))
      setOpenModal({ header: t('was_archived', { ns: 'menu' }) , description: t('successfully_archived', { item: menuItem.name, ns: 'menu' })})
    } catch (error) {
      dispatch(asyncActionError(error, t('item_could_not_be_archived', { ns: 'menu' })))
      setOpenModal({ header: 'Error' , description: t('could_not_be_archived', { item: menuItem.name, ns: 'menu' })})
    }
    timeoutRef.current = setTimeout(() => {
      setOpenModal(null)
      history.push('/menu')
    }, 5000)
  }

  async function handleDelete() {
    setConfirmDeleteOpen(false)
    dispatch(asyncActionStart())
    try {
      const res = await httpsCallable(
        cloudFunctions,
        'deleteMenuItem'
      )({
        id: props.match.params.id,
        locationId: selectedLocation ? [selectedLocation.id] : null,
      })
      let deletedMenuItem = []
      deletedMenuItem.push(res.data)
      const newMenu = menuItems.map(obj => deletedMenuItem.find(o => o.id === obj.id) || obj)
      dispatch(updateMenuItems(newMenu, selectedLocation.id ? selectedLocation.id : selectedLocation))
      dispatch(asyncActionFinish())
      setOpenModal({ header: t('was_deleted', { ns: 'menu' }) , description: t('successfully_deleted', { item: menuItem.name, ns: 'menu' })})
    } catch (error) {
      dispatch(asyncActionError(error, t('item_could_not_be_deleted', { ns: 'menu' })))
      setOpenModal({ header: 'Error' , description: t('could_not_be_deleted', { item: menuItem.name, ns: 'menu' })})
    }
    timeoutRef.current = setTimeout(() => {
      setOpenModal(null)
      history.push('/menu')
    }, 5000)
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          let ingredientsArrayFormat = values.ingredients
          let subrecipesArrayFormat = values.subrecipes

          if (values.subrecipes.length > 0) {
            try {
              let ingredientsDictionary = Object.assign(
                {},
                ...ingredientsArrayFormat.map((ingredient) => ({ [ingredient.id]: ingredient }))
              )
              values.ingredients = { ...ingredientsDictionary }

              let subrecipesDictionary = Object.assign(
                {},
                ...subrecipesArrayFormat.map((subrecipe) => {
                  const { name, ...rest } = subrecipe
                  return { [subrecipe.id]: { name: name.text ? name.text : name, ...rest } }
                })
              )
              values.subrecipes = { ...subrecipesDictionary }

              const res = await httpsCallable(
                cloudFunctions,
                'updateMenuItem'
              )({
                id: id,
                values,
                initialValues,
                locationId: selectedLocation ? selectedLocation.id ? selectedLocation.id : selectedLocation : null,
              })

              let updatedMenuItem = []
              updatedMenuItem.push(res.data)
              const newMenu = menuItems.map(obj => updatedMenuItem.find(o => o.id === obj.id) || obj)
              dispatch(updateMenuItems(newMenu, selectedLocation.id ? selectedLocation.id : selectedLocation))
              setSubmitting(false)
              values.ingredients = ingredientsArrayFormat
              values.subrecipes = subrecipesArrayFormat
              history.push('/menu')
            } catch (error) {
              setErrors({ recipes: error.message })
              setSubmitting(false)
            }
          } else {
            setErrors({ recipes: t('add_at_least_one_item', { ns: 'menu' }) })
            setSubmitting(false)
          }
        }}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, errors, values, setFieldValue }) => {
          
          if ((menuItemName !== '') && (menuItemName !== values.name)) {
            setMenuItemName(values.name)
            itemAlreadyExists.current = menuItemNameExists(values.name)
          }

          return (
            <>
              { itemAlreadyExists.current && <Message error content={t('name_already_exists', { name: values.name, ns: 'menu' })} /> }
              {errors.recipes && <Message error content={errors.recipes} />}
              {error && <Message error content={error} />}
              {message && <Message success content={message} />}
              {user.hasLocations && !selectedLocation && (
                <Message>
                  <Message.Content>
                    <strong>{t('edit_main_view', { ns: 'menu' })}</strong>
                    <br />
                    {t('warning_main_view',  { ns: 'menu' })}
                  </Message.Content>
                </Message>
              )}
              <Form autocomplete="off" className={cx(styles.EditComboContainer, 'ui form')}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={16}>
                      <TextInput
                        placeholder={menuItem && menuItem.name ? menuItem.name : t('item_name', { ns: 'menu' })}
                        name="name"
                        type="text"
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <FieldArray
                    name="subrecipes"
                    render={(arrayHelpers) => (
                      <>
                        <Grid.Row style={{ paddingTop: '0px' }}>
                          <Grid.Column width={16} className={styles.Subtitle}>
                            <p>
                              <strong>{t('included_menu_items', { ns: 'menu' })}</strong>
                            </p>
                          </Grid.Column>
                        </Grid.Row>
                        {Object.keys(values.subrecipes).map((sr, i) => (
                          <SubMenuItem
                            key={i}
                            index={sr}
                            items={allMenuItemsArr}
                            fieldArrayHelper={arrayHelpers}
                            fieldValueSetter={setFieldValue}
                            values={values.subrecipes}
                            buttonRef={buttonRef}
                            toggleEnterRef={toggleEnterRef}
                          />
                        ))}
                        { allMenuItemsArr.length === 0 &&
                          <Grid.Row style={{ paddingTop: '0px' }}>
                            <Grid.Column width={16}>
                              <p className={styles.NoSubrecipes}>{t('no_subrecipes', { ns: 'menu' })}</p>
                            </Grid.Column>
                          </Grid.Row>
                        }
                        <Grid.Row style={{ paddingTop: '0px' }}>
                          <Grid.Column width={8}>
                            <AddSubMenuItemButton fieldArrayHelper={arrayHelpers} label={t('menu_item', { ns: 'menu' })} buttonRef={buttonRef} toggleEnterRef={toggleEnterRef} disabled={allMenuItemsArr.length === 0} focusedRef={focusedRef} />
                          </Grid.Column>
                        </Grid.Row>
                      </>
                    )}
                  />

                  <Grid.Row style={{ paddingTop: '0px' }}>
                    <Grid.Column width={16}>
                      <TextAreaInput name="notes" placeholder={t('notes', { ns: 'menu' })} onFocus={() => focusedRef.current = false} />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <div className={cx(styles.ButtonsContainer, 'btn-container bottom fixed floating full-width')}>
                  <span className={styles.ButtonWrapper}>
                    {!menuItem.isArchived && (
                      <Button
                        className={cx(styles.Button, styles.Green)}
                        type="button"
                        floated="left"
                        loading={loading}
                        color="green"
                        basic
                        content={t('archive', { ns: 'buttons' })}
                        size="big"
                        onClick={() => {
                          setConfirmArchiveOpen(true)
                        }}
                      />
                    )}
                    {menuItem.isArchived && (
                      <Button
                        className={cx(styles.Button, styles.Green)}
                        type="button"
                        floated="left"
                        loading={loading}
                        color="green"
                        basic
                        content={t('delete', { ns: 'buttons' })}
                        size="big"
                        onClick={() => {
                          setConfirmDeleteOpen(true)
                        }}
                      />
                    )}
                    <Button className={cx(styles.Button, styles.Green)} type="submit" floated="right" loading={isSubmitting} color="green" content={t('save', { ns: 'buttons' })} size="big" disabled={itemAlreadyExists.current} />
                  </span>
                </div>
              </Form>
            </>
        )}}
      </Formik>
      <GreenbytesModal
        confirmDeleteOpen={confirmArchiveOpen}
        setConfirmDeleteOpen={setConfirmArchiveOpen}
        text={t('archive_combo', { ns: 'menu' })}
        confirmButtonText={'archive'}
        cancelButtonText={'cancel'}
        handleConfirmClick={handleArchive}
      />
      <GreenbytesModal
        confirmDeleteOpen={confirmDeleteOpen}
        setConfirmDeleteOpen={setConfirmDeleteOpen}
        text={t('delete_combo', { ns: 'menu' })}
        confirmButtonText={'delete'}
        cancelButtonText={'cancel'}
        handleConfirmClick={handleDelete}
      />
      <Modal
        open={openModal !== null}
        closeOnDimmerClick={openModal && !openModal.header.includes('deleted')}
        closeIcon={openModal && !openModal.header.includes('deleted')}
        onClose={() => {
          clearTimeout(timeoutRef.current)
          setOpenModal(null)
        }}
      >
        <Modal.Header className={styles['Modal-Header']}>
          {openModal && openModal.header}
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p className={styles['Modal-Description']}>
              {openModal && openModal.description}
            </p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button 
            onClick={() => {
              setOpenModal(false)
              history.push('/menu')
            }}
            className={styles['Confirm-Button']}
            positive
          >
            {t('go_to_menu', { ns: 'menu' })}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default EditCombo
