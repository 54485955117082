import React from 'react'
import { Grid } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import styles from '../../assets/styles/modules/shoppingList/shoppingListDetails.module.scss'

const ShoppingListDetails = ({ shoppingList }) => {
  const { t } = useTranslation(['orders', 'stock', 'buttons'])

  return (
    <div className={styles.ShoppingListDetails}>
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <h2>{shoppingList.distributor.name}</h2>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="grid-headers">
          <Grid.Column>
            <strong>{t('item', { ns: 'orders' })}</strong>
          </Grid.Column>
          <Grid.Column></Grid.Column>
          <Grid.Column textAlign="center">
            <strong>{t('total_amount', { ns: 'orders' })}</strong>
          </Grid.Column>
        </Grid.Row>

        {shoppingList.orderedItems &&
          shoppingList.orderedItems.map((item, index) => (
            <>
              <Grid.Row key={'item' + index} className={cx('grid-item', styles.BorderBottom)}>
                <Grid.Column>{item.name}</Grid.Column>
                <Grid.Column></Grid.Column>
                <Grid.Column textAlign="center" className={item.amountReceived ? styles.strikethrough : ''}>
                  {item.totalAmount.toString().split('.')[1] ? item.totalAmount.toFixed(2) : item.totalAmount} {item.unit}
                </Grid.Column>
              </Grid.Row>
            </>
          ))}
      </Grid>
      <hr className={styles.Divider} />
      <Grid>
        {shoppingList.notes && (
          <Grid.Row>
            <Grid.Column width={16}>
              <p className="hint">{t('notes', { ns: 'orders' })}:</p>
              { shoppingList.notes.map((el) => 
                  ( el.note.split('\n').map((line) => <p className={styles.NoteItem}>{line}</p> ))
              )}
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </div>
  )
}

export default ShoppingListDetails