import * as React from 'react'
import { Button, Grid, Message, Icon } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Formik, Form, FieldArray } from 'formik'
import TextInput from '../../UI/Form/TextInput'
import { setPageTitle, updateDistributors } from '../../../store/actions'
import ProfileImage from '../../ProfileImage/ProfileImage'
import placeholderSrc from '../../../assets/images/distributors/placeholder.svg'
import { useHistory } from 'react-router-dom'
import CheckboxInputButton from '../../UI/Form/CheckboxInputButton'
import CustomDropdown from '../../UI/Form/CustomDropdown'
import { cloudFunctions } from '../../../config/firebase'
import { httpsCallable } from 'firebase/functions'
import { parseOpeningHours, distributorSchema, refreshValues, NODE_OPENING_HOURS } from './utils'
import TimePicker from '../../UI/Form/TimePicker'
import { getUserId } from '../../../store/actions/authActions'
import { TYPE_YOUR_OWN_DISTRIBUTOR } from '../../../store/wordings'
import styles from '../../../assets/styles/modules/distributors/AddDistributor.module.scss'
import { flaskAPIGet } from '../../../shared/utility'
import cx from 'classnames'

const AddDistributor = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [additionalContact, setAdditionalContact] = React.useState(false)
  const { t } = useTranslation(['distributor', 'buttons', 'validationMessages', 'menu'])
  const [allDistributors, setAllDistributors] = React.useState([])
  const [distributorsNames, setDistributorsNames] = React.useState([])
  const [repeatedDistributor, setRepeatedDistributor] = React.useState(false)
  const [isLoadingDistributors, setIsLoadingDistributors] = React.useState(true)
  const { distributors } = useSelector((state) => state.distributor)
  const toggleEnterRef = React.useRef(false)
  const distributorName = React.useRef('')
  const [firstLoad, setFirstLoad] = React.useState(true)

  React.useEffect(() => {
    dispatch(setPageTitle(t('add_distributor', { ns: 'distributor' })))
    const getDistributorsOnboarding = async () => {
      const userId = await getUserId()
      const response = await flaskAPIGet({ endpoint: `/v2/distributors/user/${userId}` })
      const data = response.map((el) => {
        let distributor = {}
        const { email, id, name, phone, website, distrPhotoURL } = el
        if (distributors.some(distributor => distributor.name === el.name)) {
          distributor = { email, id, name, phone, website, distrPhotoURL, value: el.id, text: el.name, description: 'already on your list', disabled: true }
        } else {
          distributor = { email, id, name, phone, website, distrPhotoURL, value: el.id, text: el.name }
        }
        return distributor
      })
      setAllDistributors(data.concat([TYPE_YOUR_OWN_DISTRIBUTOR(t)]))
      setDistributorsNames(distributors.map((dist) => dist?.name?.toUpperCase()))
      setIsLoadingDistributors(false)
    }

    const loadDistributors = async () => {
      const userId = await getUserId()
      const distributorsData = await flaskAPIGet({ endpoint: `/v2/distributors/user/${userId}?mode=user` })
      dispatch(updateDistributors(distributorsData))
      setFirstLoad(false)
    }

    // Should be able to type your own distributor
    getDistributorsOnboarding()

    if (firstLoad && !distributors.length) {
      loadDistributors()
    }
  }, [dispatch])

  const formFields = {
    distrPhotoURL: '',
    name: '',
    email: '',
    phone: '',
    website: '',
    openingHours: [NODE_OPENING_HOURS],
    representativeName: '',
    representativePhone: '',
    representativeEmail: '',
    customDistributor: true
  }

  return (
    <div className={styles.DistributorForm}>
      <Formik
        initialValues={formFields}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            if (distributorsNames.includes(values.name.toUpperCase())) {
              setRepeatedDistributor(true)
              setSubmitting(false)
            } else {
              setRepeatedDistributor(false)
              const res = await httpsCallable(cloudFunctions, 'addDistributor')({ values: {...values, openingHours: parseOpeningHours(values.openingHours) }})
              let newDistributors = [...distributors]
              newDistributors.push(res.data)
              dispatch(updateDistributors(newDistributors))
              setSubmitting(false)
              history.push('/distributors')
            }
          } catch (error) {
            setErrors({ distr: error.message })
            setSubmitting(false)
          }
        }}
        validationSchema={distributorSchema(additionalContact, t)}
      >
        {({ isSubmitting, isValid, values, errors, setFieldValue }) => {

          if (values.name) {
            const distributor = allDistributors.filter(dist => dist.name === values.name)
            if (distributor.length === 1) {
              values.distrPhotoURL = distributor[0].distrPhotoURL ? distributor[0].distrPhotoURL : null
              values.email = distributor[0].email && toggleEnterRef.current ? distributor[0].email : values.email
              values.phone = distributor[0].phone && toggleEnterRef.current ? distributor[0].phone.replace(new RegExp('-', 'g'),'') : values.phone
              values.website = distributor[0].website && toggleEnterRef.current ? distributor[0].website : values.website
              values.openingHours = distributor[0].openingHours && toggleEnterRef.current ? distributor[0].openingHours : values.openingHours
              values.representativeName = distributor[0].representativeName && toggleEnterRef.current ? distributor[0].representativeName : values.representativeName
              values.representativePhone = distributor[0].representativePhone && toggleEnterRef.current ? distributor[0].representativePhone : values.representativePhone
              values.representativeEmail = distributor[0].representativeEmail && toggleEnterRef.current ? distributor[0].representativeEmail : values.representativeEmail
              values.customDistributor = false
            } else {
              Object.assign(values, refreshValues({...values, customDistributor: true, shouldBeRefreshed: distributorName.current !== values.name }))
            }
            distributorName.current = values.name
            toggleEnterRef.current = false
          }
          
          return (
            <Form autocomplete="off" className="ui form">
              {errors.distr && <Message error content={errors.distr} style={{ marginBottom: 10 }} />}
              { repeatedDistributor && <Message error content={t('distributor_already_exists', { name: values.name, ns: 'validationMessages' })} style={{ marginBottom: 10 }} /> }
              <Grid className={styles['Distributor-Grid']} stackable={true}>
                <Grid.Row>
                  <Grid.Column width={5} className={styles.ImageUploadContainer} vertical-align="middle">
                    <ProfileImage
                      placeholder={placeholderSrc}
                      currentImage={values.distrPhotoURL}
                      fieldName="distrPhotoURL"
                      fieldValueSetter={setFieldValue}
                    />
                  </Grid.Column>
                  <Grid.Column className="distributor-inputs">
                    { !isLoadingDistributors && allDistributors.length === 1 ?
                      <TextInput name="name" placeholder={t('name', { ns: 'distributor' })} type="text" />
                    :
                      <CustomDropdown
                        name={'name'}
                        placeholder={values.name ? values.name : t('name', { ns: 'distributor' })}
                        options={allDistributors}
                        fieldValueToSet={'name'}
                        fieldValueSetter={setFieldValue}
                        compact
                        loading={isLoadingDistributors}
                        disabled={isLoadingDistributors}
                        customDistributor={'customDistributor'}
                        distributorFlow={true}
                        toggleEnterRef={toggleEnterRef}
                      />
                    }
                    <TextInput name="email" placeholder={t('email', { ns: 'distributor' })} type="email" />
                    <TextInput name="phone" placeholder={t('phone', { ns: 'distributor' })} type="text" />
                    <TextInput name="website" placeholder={t('website', { ns: 'distributor' })} type="text" />
                    { additionalContact &&
                      <>
                        <TextInput name="representativeName" placeholder={t('representative_name', { ns: 'distributor' })} type="text" />
                        <TextInput name="representativeEmail" placeholder={t('representative_email', { ns: 'distributor' })} type="email" />
                        <TextInput name="representativePhone" placeholder={t('representative_phone', { ns: 'distributor' })} type="text" />
                      </>
                    }
                    <p
                      className={styles.AddAdditionalContact}
                        onClick={() => {
                          setAdditionalContact(prevState => !prevState);
                          values.representativeName = '';
                          values.representativePhone = '';
                          values.representativeEmail = '';
                        }}>
                        { additionalContact ?
                          <>
                            <span><Icon className="pointer" name="trash" color="grey" alt="Remove" title="Remove" /></span>
                            <span>{t('remove_additional_contact', { ns: 'distributor' })}</span>
                          </>
                        :
                          <>
                            <span><Icon color="green" name="plus circle" className={cx(styles.Button, styles.ButtonGreenCustom)} /></span>
                            <span>{t('add_additional_contact', { ns: 'distributor' })}</span>
                          </>
                        }
                    </p>
                    <div className={styles.OpeningHours} unstackable={false} celled={false}>
                      <div>
                        <FieldArray
                          name="openingHours"
                          render={(arrayHelpers) => (
                            values.openingHours.map((el, index) => (
                              <>
                                <div className="opening timepicker-containers">
                                  <div className={styles.InputsContainer_FirstColumn}>
                                    <p className={cx(styles.OpeningHoursHint, 'hint')}>{t('opening_hours', { ns: 'distributor' })}</p>
                                  </div>
                                  <div className={styles.InputsContainer_SecondColumn}>
                                    <TimePicker
                                      name={`openingHours.${index}.openFrom`}
                                      fieldValueSetter={setFieldValue}
                                    />
                                    <p className={cx(styles.OpeningHoursHint, 'hint')}>{t('to', { ns: 'distributor' })}</p>
                                    <TimePicker
                                      name={`openingHours.${index}.openTo`}
                                      fieldValueSetter={setFieldValue}
                                    />
                                  </div>
                                </div>
                                {Object.keys(el.days).map((day, i) => (
                                  <CheckboxInputButton name={`openingHours.${index}.days.${day}.isOpen`} type="checkbox" prefix={day} />
                                ))}
                                <p
                                  className={cx(styles.AddAdditionalHours, 'hint')}
                                    onClick={() => arrayHelpers.push({
                                      openFrom: '',
                                      openTo: '',
                                      days: {
                                        Su: { isOpen: false },
                                        M: { isOpen: false },
                                        Tu: { isOpen: false },
                                        W: { isOpen: false },
                                        Th: { isOpen: false },
                                        F: { isOpen: false },
                                        Sa: { isOpen: false }
                                      }
                                    })
                                    }
                                  >
                                    <span><Icon color="green" name="plus circle" className={cx(styles.Button, styles.ButtonGreenCustom)} /></span>
                                    <span>{t('add_additional_hours', { ns: 'distributor' })}</span>
                                </p>
                              </>
                            ))
                          )}
                        />
                      </div>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <div className={cx(styles.ButtonsContainer, 'btn-container bottom fixed floating full-width')}>
                <span className={styles.ButtonWrapperCentered}>
                  <Button
                    className={cx(styles.Button, styles.Green)}
                    loading={isSubmitting}
                    disabled={!isValid || isSubmitting}
                    type="submit"
                    color="green"
                    content={t('save', { ns: 'buttons' })}
                  />
                </span>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default AddDistributor