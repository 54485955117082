import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import ModalWrapper from '../../UI/Modal/ModalWrapper'
import { Button, Label, Message } from 'semantic-ui-react'
import { Form, Formik } from 'formik'
import { getUnitArray } from '../../../shared/utility'
import SelectInput from '../../UI/Form/SelectInput'
import { changeUserPreferredCurrency } from '../../../store/actions/userActions'
import btnStyles from '../../../assets/styles/modules/UI/Button.module.scss'
import { asyncActionFinish, closeModal } from '../../../store/actions'

const ChangePreferredCurrency = () => {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.user)
  const { t } = useTranslation(['validationMessages', 'changeCurrency', 'buttons'])
  const options = ['kr', '$', '€', '£']

  const formFields = {
    preferredCurrency: user && user.preferredCurrency ? user.preferredCurrency : '',
  }

  return (
    <ModalWrapper size="auto" scrollable={false} header={t('title', { ns: 'changeCurrency'})}>
      <Formik
        initialValues={formFields}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            if (values.preferredCurrency?.value) {
              await changeUserPreferredCurrency(values)
              setSubmitting(false)
              dispatch(asyncActionFinish(t('currency_has_been_changed', { ns: 'changeCurrency'})))
              dispatch(closeModal())
            } else {
              if (values.preferredCurrency) {
                setErrors({ auth: t('different_currency', { ns: 'validationMessages' }) })
              } else {
                setErrors({ auth: t('currency', { ns: 'validationMessages' }) })
              }
              setSubmitting(false)
            }
          } catch (error) {
            setErrors({
              auth: t('currency_could_not_be_changed', { ns: 'changeCurrency'})
            })
            setSubmitting(false)
          }
        }}
      >
        {({ values, isSubmitting, errors, setFieldValue }) => (
          <Form autocomplete="off" className="ui form">
            {errors.auth && <Label basic color="red" content={errors.auth} style={{ marginBottom: 10 }} />}
            { user.preferredCurrency && <Message warning content={'Please note that your currency preference will be updated, but the numerical values will remain unchanged and won\'t be automatically converted.'} style={{ padding: '0 20px' }} /> }
            <SelectInput
              name={'preferredCurrency'}
              placeholder={values.preferredCurrency ? values.preferredCurrency.value : t('select_currency', { ns: 'changeCurrency'})}
              options={getUnitArray(options)}
              fieldValueSetter={setFieldValue}
              fieldValueToSet={'preferredCurrency'}
            />
            <br />
            <br />
            <br />
            <div className="btn-container bottom fixed flex space-between full-width gap-16">
              <Button
                disabled={isSubmitting}
                className={btnStyles.Left}
                type="button"
                color="red"
                basic
                size="large"
                onClick={() => dispatch(closeModal())}
              >
                {t('cancel', { ns: 'buttons' })}
              </Button>
              <Button
                loading={isSubmitting}
                className={btnStyles.Right}
                type="submit"
                color="green"
                size="large"
              >
                {t('submit', { ns: 'buttons' })}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  )
}

export default ChangePreferredCurrency
