import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Redirect, useLocation } from 'react-router-dom'
import { PersistFormikValues } from 'formik-persist-values'
import { useTranslation } from 'react-i18next'
import {
  getUserProfileFromFirestore,
  listenToUserProfile,
} from '../../store/actions/userActions'
import useFirestoreDoc from '../../hooks/useFirestoreDoc'
import useSessionStorage from '../../hooks/useSessionStorage'
import { setPageTitle } from '../../store/actions'
import { orderInProgressAction } from '../../store/actions/orderActions'
import Item from './Item'
import { Button, Checkbox, FormField, Grid, Label, Message, Search, Table, Dropdown } from 'semantic-ui-react'
import Spinner from '../UI/Spinner/Spinner'
import Accordion from '../UI/Accordion/Accordion'
import { Form, Formik, FieldArray } from 'formik'
import useSearchCallback from '../../hooks/useSearchCallback'
import * as Yup from 'yup'
import { getDistributorsFromStock } from '../../shared/utility'
import styles from '../../assets/styles/modules/orders/OrderForm.module.scss'
import cx from 'classnames'

const ShoppingListForm = (props) => {
  const FORM_NAME = 'order-form'
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const recipesData = location.state?.recipesData
  const calculatedIngredients = location.state?.calculatedIngredients
  const { t } = useTranslation(['orders', 'validationMessages', 'buttons', 'common', 'distributor'])

  const { stock } = useSelector((state) => state.stock)
  const { user } = useSelector((state) => state.user)
  const { loading, error } = useSelector((state) => state.async)
  const { value, searchResults } = useSelector((state) => state.search)
  const { selectedLocation } = useSelector((state) => state.location)
  const { distributors } = useSelector((state) => state.distributor)

  const [dateRange, setDateRange] = React.useState([])
  const [suggestionsPerItem, setSuggestionsPerItem] = React.useState({}) // Suggestions for date filter
  const [currentOrder, setCurrentOrder] = useSessionStorage('currentOrder', null)
  const [currentResults, setCurrentResults] = React.useState(searchResults)
  const [btnClicked, setBtnClicked] = React.useState('')

  React.useEffect(() => {
    dispatch(setPageTitle(t('shopping_list', { ns: 'orders' })))

    if (calculatedIngredients && !calculatedIngredients.error) {
      for(let key in calculatedIngredients) {
        calculatedIngredients[key] = Number(calculatedIngredients[key].toFixed(2))
      }
    }
  }, [])

  React.useEffect(() => {
    if (history.action !== 'POP') {
      localStorage.removeItem(FORM_NAME)
    }
  }, [history])

  useFirestoreDoc({
    query: () => getUserProfileFromFirestore(),
    data: (user) => dispatch(listenToUserProfile(user)),
    deps: [dispatch],
  })

  // Filter out archived items
  const activeStock = stock ? stock.filter((item) => item.isArchived !== true) : []
  const distributorsFromStock = getDistributorsFromStock(activeStock, distributors, t)
  const handleSearchChange = useSearchCallback(activeStock)
  let initialAmounts = new Array(activeStock.length); for (let i=0; i<activeStock.length; ++i) initialAmounts[i] = 0;
  const totalAmounts = React.useRef(initialAmounts)

  const getSuggestionForItem = (item) => {
    let suggestion = 0
    if (user.useSuggestions) {
      if (suggestionsPerItem[`${item.id}`]) {
        let divider = 1
        if (item.unit !== suggestionsPerItem[`${item.id}`].unit) {
          if (item.unit === 'kg' || item.unit === 'l') {
            divider = 1000
          }
        }
        suggestion = Math.ceil(suggestionsPerItem[`${item.id}`].qty / divider)
      }
    }
    return suggestion
  }

  // Map stock items with order suggestion values
  const items = activeStock
    .filter((item) => item.isArchived !== true)
    .map((item) => ({
      itemId: item.id,
      name: item.name,
      itemNumber: item.itemNumber ? item.itemNumber : '',
      distributors: item.distributors,
      distributorId: item.distributorId,
      distributor: item.distributor,
      amountInStock: item.amount,
      amount: calculatedIngredients && Object.keys(calculatedIngredients).includes(item.id) ? calculatedIngredients[item.id] : getSuggestionForItem(item),
      qtyPiece: item.qtyPiece,
      unit: item.unit,
      orderUnit: item.orderUnit ? item.orderUnit : item.unit,
      orderUnitPcs: item.orderUnitPcs,
      minOrder: item.minOrder,
      included: calculatedIngredients && Object.keys(calculatedIngredients).includes(item.id) && (calculatedIngredients[item.id] !== 0),
      productIds: item.onboarding ? Object.assign({}, ...item.onboarding.map((el) => ({[el.id_distributor]: el.id_product_onboarding}))) : null,
      onboarding: item.onboarding ? item.onboarding : null,
      multiDistributors: item.multiDistributors ? item.multiDistributors : null 
    }))

  const sortItems = (items) => items.sort((a, b) => {
    if (a.included > b.included) {
      return -1;
    }
    if (a.included < b.included) {
      return 1;
    }
    return 0;
  })

  // For the order date picker
  const currentDate = new Date()
  const minDate = new Date()
  minDate.setDate(currentDate.getDate() - 1)
  const maxDate = new Date()
  maxDate.setDate(maxDate.getDate() + 6)
  const initialValues = { items: sortItems(items) }

  React.useEffect(() => {
    if (searchResults.length !== 0) {
      setCurrentResults(searchResults)
    } else {
      setCurrentResults(sortItems(items))
    }
  }, [searchResults])

  const onSelectAll = (elm, formValues, fieldValueSetter) => {
    for (let i in formValues) {
      fieldValueSetter(`items.${i}.included`, elm.checked)
    }
  }

  const saveOrderInProgress = (values) => {
    let orderItems = values.items.filter((item) => item.included && Number(item.amount) > 0)
    const hasMultipleDistributors =
      orderItems.filter((item) => item.distributors && Object.keys(item.distributors).length > 1).length > 0
    const hasMultipleStockPerDistributor =
      orderItems.filter((item) => item.multiDistributors && Object.keys(item.multiDistributors).some(distId => item.multiDistributors[distId].length > 1)).length > 0
    let stockItem = null
    orderItems = orderItems.map((item, index) => {
      const itemDecimals = totalAmounts.current && totalAmounts.current.length > 0 ? totalAmounts.current.filter(el => el.itemNumber === item.itemNumber && el.amount === item.amount) : null
      if (item.distributors && Object.keys(item.distributors).length === 1) {
        const distributorId = Object.keys(item.distributors)[0]
        item.distributor = item.distributors[distributorId].name
        item.distributorId = distributorId
        item.itemNumber = item.distributors[distributorId].itemNumber
        item.qtyPiece = item.distributors[distributorId].qtyPiece
        item.orderUnit = item.distributors[distributorId].customDistributor ? item.unit : item.distributors[distributorId].orderUnit
        item.orderUnitPcs = item.distributors[distributorId].orderUnitPcs
        item.unitForAmount = hasMultipleDistributors || !item.distributors[distributorId]?.orderUnit
          ? item.unit
          : item.distributors[distributorId].orderUnit
      }
      item.originalDistributors = item.distributors
      let allDistributors = []
      distributors.forEach((distributor) => {
        if (Object.keys(item.distributors).includes(distributor.id)) {
          allDistributors[distributor.id] = item.distributors[distributor.id]
        } else {
          allDistributors[distributor.id] = { customDistributor: distributor.customDistributor, itemNumber: '', name: distributor.name, orderUnit: '', orderUnitPcs: '', qtyPiece: '' }
        }
      })
      allDistributors['none'] = { customDistributor: null, itemNumber: '', name: 'No distributor selected', orderUnit: '', orderUnitPcs: '', qtyPiece: '' }
      item.distributors = allDistributors
      item.decimals = itemDecimals.length > 0 ? itemDecimals[0].decimals : 0
      stockItem = activeStock.find((el) => el.id === item.itemId)
      item.previousTotalAmount = stockItem ? stockItem.amount : 0
      item.totalAmount = item.amount
      return item
    })
    dispatch(
      orderInProgressAction({
        orderItems,
        suggestionsUsed: !!user.useSuggestions,
        dateRange: dateRange,
        deliveryDate: [],
        location: selectedLocation,
        delivery: [],
        pickUp: [],
        notes: [],
        isShoppingList: btnClicked === 'send'
      })
    )
    setCurrentOrder(null)
    history.push(hasMultipleDistributors || hasMultipleStockPerDistributor || btnClicked === 'send' ? '/order-distributors' : '/order-summary')
  }

  const getIsDisabled = (values) => {
    const itemsToOrder = values.items.filter((item) => item.amount > 0 && item.included)
    return itemsToOrder.length === 0
  }

  const updateTotalAmounts = (values) => {
    const result = [];
    for (let i = 0; i < values.items.length; i++) {
      const { orderUnitPcs, amount, qtyPiece, distributors, itemNumber, ...rest } = values.items[i]
      const quantityPiece = Object.keys(distributors).length > 1 ? 0 : Number(qtyPiece)
      if ((amount === '') || (parseInt(amount) <= 0)) {
        result.push({ amount: 0, orderUnitPcs, qtyPiece: quantityPiece, decimals: 0, itemNumber })
      } else {
        result.push({ amount: parseInt(amount), orderUnitPcs, qtyPiece: quantityPiece, decimals: quantityPiece.toString().split('.')[1] ? 2 : 0, itemNumber })
      }
    }
    totalAmounts.current = result
  }

  if (
    (!user.isPremium) || (user.hasLocations && props.match.params.location && !selectedLocation)
  ) {
    return <Redirect to="/orders" />
  }

  if (loading || !user) return <Spinner content={t('please_wait', { ns: 'common' })} />

  if (!stock || !activeStock.length) return <Message content={t('add_some_stock', { ns: 'orders' }) }/>

  return (
    <div className={styles.OrderFormContainer}>
      <div className="btn-container top fixed center-content search">
        <Search
          id="ingredientSearch"
          loading={loading}
          onSearchChange={handleSearchChange}
          results={[]}
          showNoResults={false}
          value={value}
          placeholder={t('search_for_ingredient', { ns: 'orders' })}
        />
      </div>
      <div>
        {error && <Message warning>{t('something_wrong', { ns: 'common' , error })}</Message>}
        { recipesData && recipesData.length && 
          <Accordion info={recipesData} customTitle={true} />
        }
        { props.match.params.location && selectedLocation && (
          calculatedIngredients ?
            <p className="x-large align-center" style={{ margin: '0 auto 12px', maxWidth: '600px' }}>
              {t('based_on_dishes', { ns: 'orders' })}
            </p>
            :
            <p className="x-large align-center" style={{ marginBottom: 12 }}>
              {t('new_order_for', { ns: 'orders' })} <strong>{selectedLocation.name}</strong>
            </p>
        )}
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => saveOrderInProgress(values)}
          validationSchema={Yup.object().shape({
            items: Yup.array().of(
              Yup.object().shape({
                amount: Yup.number().typeError(t('number', { ns: 'validationMessages' })).min(0, t('below_zero', { ns: 'validationMessages' })),
              })
            ),
          })}
          enableReinitialize={true}
        >
          {({ isSubmitting, errors, setFieldValue, values, handleReset, resetForm, submitForm }) => {

            updateTotalAmounts(values)
            
            return (
            <Form autocomplete="off" className="ui form" name={FORM_NAME}>
              {errors.distr && <Label basic color="red" content={errors.stock} style={{ marginBottom: 10 }} />}
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column width={8}>
                    <FormField style={{ paddingLeft: '10px' }}>
                      <Checkbox
                        id="selectAll"
                        label={t('add_all_ingredients', { ns: 'orders' })}
                        onClick={(e) => onSelectAll(e.target, values.items, setFieldValue)}
                      />
                    </FormField>
                  </Grid.Column>
                  <Grid.Column width={8} textAlign="right">
                    <Dropdown
                      placeholder={t('filter_by_distributor', { ns: 'orders' })}
                      clearable
                      id="distributorFilter"
                      selection
                      search
                      options={distributorsFromStock}
                      onChange={handleSearchChange}
                      fluid
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              <FieldArray
                name="items"
                render={() => (
                  <Table striped compact className="order-form-table">
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>{t('ingredient', { ns: 'orders' })}</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell textAlign="left" style={{ paddingLeft: '30px' }}>{t('amount_to_order', { ns: 'orders' })}</Table.HeaderCell>
                        <Table.HeaderCell width="1" textAlign="center">
                          {t('order', { ns: 'orders' })}
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {values.items.map((item, index) => {
                        if (!currentResults.length || currentResults.find((ing) => ((ing.itemId === item.itemId) || (ing.id === item.itemId)))) {
                          return (
                            <Item
                              key={item.itemId}
                              index={index}
                              item={item}
                              fieldValueSetter={setFieldValue}
                              currentValues={values}
                              totalAmount={totalAmounts.current[index]}
                              selectedLocation={selectedLocation}
                              distributor={distributors.filter(dist => dist.id === item.distributorId)[0]}
                            />
                          )
                        } else {
                          return null
                        }
                      })}
                    </Table.Body>
                  </Table>
                )}
              />
              <div className={cx(styles.ButtonsContainer, 'btn-container bottom fixed floating full-width')}>
                <span className={styles.ButtonWrapper}>
                  <Button
                    className={cx(styles.Button, styles.Orange)}
                    floated="left"
                    type="button"
                    basic
                    color="orange"
                    onClick={() => history.push('/orders')}
                    content={t('cancel', { ns: 'buttons' })}
                  />
                  <Button
                    className={cx(styles.Button, styles.Green)}
                    loading={isSubmitting}
                    disabled={getIsDisabled(values)}
                    type="submit"
                    color="green"
                    onClick={() => setBtnClicked('send')}
                    content={t('send', { ns: 'buttons' })}
                  />
                </span>
              </div>
              <PersistFormikValues name={FORM_NAME} />
            </Form>
          )}}
        </Formik>
      </div>
    </div>
  )
}

export default ShoppingListForm