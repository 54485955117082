import React, { useState } from 'react'
import { Icon, Popup } from 'semantic-ui-react'
import cx from 'classnames'
import styles from '../../../../assets/styles/modules/UI/AccordionViewMenuItem.module.scss'
import { calculateCeil, getLabelPosition, swapElements } from '../../../../shared/utility'
import moment from 'moment'

const AccordionViewMenuItem = ({ title = '', items = null, openable = true, text = '', currencySign = '', showHint = false, rearrange = false }) => {
  const [open, setOpen] = useState(false)
  const checkDecimals = (num) => (num % 1 != 0) ? 2 : 0
  
  return (
    <div className={styles.AccordionContainer}>
      <span className={styles.AccordionTitle} onClick={() => setOpen(!open)}>
        <span>{title}</span>
        <span>
          <strong>{text}</strong>
          { openable ?
            open ?
            <Icon name="caret up" className={styles.Icon} />  
            :
            <Icon name="caret down" className={styles.Icon} />
            :
            <></>
          }
        </span>
      </span>
      { open && showHint &&
        <p className={cx(styles.Accordion, styles.Item)}>
          <Popup
            className={styles.Popup}
            position="bottom right"
            trigger={ <Icon className={styles.Trigger} color="grey" name="info circle" /> }
          >
            <p style={{ marginBottom: '15px' }}>The nutritional information provided for this dish is intended to offer a general guideline. Please be aware that exact values may vary depending on the brands and specific products used.</p>
            <p>This nutritional information was generated using AI technology and may not be 100% accurate. Variations in preparation, ingredients, and portion sizes can affect the nutritional values.</p>
          </Popup>
        </p>
      }
      { open && items && swapElements(items, getLabelPosition(items, 'FIBER'), getLabelPosition(items, 'TOTAL FATS'), rearrange).map((row) => {
        if (row?.label && !row?.value && row?.text) {
          if (row.text && row.text.toUpperCase() !== 'NO') {
            return <p className={cx(styles.Accordion, styles.Item)}>
              <span><strong>{row.label}</strong></span>
              <span>{row.text}</span>
            </p>
          } else {
            return null
          }
        } else if (!row.label) {
          const rightText = row.text.split(':')
          return <p className={cx(styles.Accordion, styles.Item)}>
            <span></span>
            <span>
              { rightText[0] }{ rightText[1] ? ': ' : '' }
              { rightText[1] ? <strong>{moment(rightText[1]).format('MMMM DD, YYYY')}</strong> : <></> }
            </span>
          </p>
        } else {
          const rowValueFrom = typeof row.value.from === 'number' ? calculateCeil(row.value.from, currencySign) : 0
          const rowValueTo = typeof row.value.to === 'number' ? calculateCeil(row.value.to, currencySign) : 0
          const rowValue = (!(row.value.from)) && (!(row.value.to)) ? calculateCeil(row.value, currencySign) : 0 
          return <p className={cx(styles.Accordion, styles.Item)}>
            <span><strong>{row.label}</strong></span>
            { typeof row.value.from === 'number' && typeof row.value.to === 'number' ?
              <span>{(rowValueFrom).toFixed(checkDecimals(rowValueFrom))} {row.text ? row.text : currencySign} to {(rowValueTo).toFixed(checkDecimals(rowValueTo))} {row.text ? row.text : currencySign}</span>
              :
              <span>{(rowValue).toFixed(checkDecimals(rowValue))} {row.text ? row.text : currencySign}</span>
            }
          </p>
        }
      })}
    </div>
  )
}

export default AccordionViewMenuItem