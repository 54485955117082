import React from 'react'
import styles from '../../../assets/styles/modules/UI/SortingField.module.scss'
import { Icon } from 'semantic-ui-react'

const SortingField = (props) => {
  const classes = [styles.SortingField]
  if (props.active) classes.push(styles.Active)

  let sortOrderArrow = <Icon name='caret up' />
  if (props.active && (props.direction === 'descending')) {
      sortOrderArrow = <Icon name='caret down' />
  }

  return (
    <div className={classes.join(' ')}>
      <strong>{props.title}</strong> {sortOrderArrow}
    </div>
  )
}

export default SortingField
