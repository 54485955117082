import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getUserProfileFromFirestore, listenToUserProfile } from '../../store/actions/userActions'
import { updateShoppingLists } from '../../store/actions/orderActions'
import useFirestoreDoc from '../../hooks/useFirestoreDoc'
import { setPageTitle, updateDistributors } from '../../store/actions'
import Order from './Order/Order'
import { Button, Message, Icon, Grid } from 'semantic-ui-react'
import Spinner from '../UI/Spinner/Spinner'
import styles from '../../assets/styles/modules/orders/OrderList.module.scss'
import { getUserId, getUserIdToken } from '../../store/actions/authActions'
import { asyncActionStart, asyncActionError } from '../../store/actions/asyncActions'
import { flaskAPIGet } from '../../shared/utility'
import cx from 'classnames'

const Orders = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { t } = useTranslation(['orders', 'common'])

  // Orders per page
  const limit = 10
  const currentCursor = history.location?.state?.cursor || parseInt(localStorage.getItem('cursor'))
  const target = history.location?.state?.target || localStorage.getItem('target')

  const { user } = useSelector((state) => state.user)
  const { selectedLocation } = useSelector((state) => state.location)
  const { shoppingLists, lastLocationId } = useSelector((state) => state.order)
  const { distributors } = useSelector((state) => state.distributor)
  const [orders, setOrders] = React.useState(null)
  const [from, setFrom] = React.useState(0)
  const [to, setTo] = React.useState(0)
  const [disableNextButton, setDisableNextButton] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const { error } = useSelector((state) => state.async)
  const [cursor, setCursor] = React.useState(currentCursor || 0)
  const [firstLoad, setFirstLoad] = React.useState(true)
  const [firstLoadDistributors, setFirstLoadDistributors] = React.useState(true)

  React.useEffect(() => {
    const timer = setTimeout(() => {
      const element = document.getElementById(target)
      if (!element) { return }
      element.scrollIntoView({ block: 'center', behavior: 'smooth' })
      history.replace({state: {}})
      localStorage.removeItem('target')
      localStorage.removeItem('cursor')
    }, 500);

    return () => clearTimeout(timer)
  }, [history, target])

  React.useEffect(() => {
    const loadData = async () => {
      const user_token = await getUserIdToken()
      const userId = await getUserId()
      setLoading(true)
      try {
        dispatch(asyncActionStart())
        const { items, pagination } = await flaskAPIGet({ endpoint: `/v2/frontend/user/${userId}/location/${selectedLocation.id}/orders-shoppinglists` })
        if (items || pagination) {
          dispatch(updateShoppingLists(items, selectedLocation.id))
          if (pagination) {
            setFrom((limit * pagination.page) + 1)
            setTo((limit * pagination.page) + items.length)
            setDisableNextButton(pagination.page === pagination.total)
          }
        }
        setLoading(false)
      } catch (error) {
        dispatch(asyncActionError(error, ''))
      }
      setFirstLoad(false)
    }

    const loadDistributors = async () => {
      const userId = await getUserId()
      const distributorsData = await flaskAPIGet({ endpoint: `/v2/distributors/user/${userId}?mode=user` })
      dispatch(updateDistributors(distributorsData))
      setFirstLoadDistributors(false)
    }
    
    if (selectedLocation && firstLoad && (lastLocationId !== selectedLocation.id)) {
      loadData()
    } else {
      setLoading(true)
      let slice = shoppingLists.slice(cursor * limit)
      if (slice.length >= limit) {
        slice = shoppingLists.slice(cursor * limit, limit)
      }
      setFrom((limit * cursor) + 1)
      setTo((limit * cursor) + slice.length)
      setOrders(slice)
      setDisableNextButton((limit * (cursor + 1)) >= shoppingLists.length)
      setLoading(false)
    }

    if (firstLoadDistributors && !distributors.length) {
      loadDistributors()
    }
  }, [cursor, selectedLocation, shoppingLists])

  useFirestoreDoc({
    query: () => getUserProfileFromFirestore(),
    data: (user) => dispatch(listenToUserProfile(user)),
    deps: [dispatch],
  })

  React.useEffect(() => {
    dispatch(setPageTitle(t('all_orders', { ns: 'orders' })))
  }, [dispatch])

  const handleFetchNextItems = () => {
    setCursor(cursor + 1)
  }

  const handleFetchPrevItems = () => {
    setCursor(cursor - 1)
  }

  if (!selectedLocation) {
    return <Redirect to={{ pathname: "/locations", state: { previousPage: 'orders' }}} />
  }

  if (loading || !user) return <Spinner content={t('please_wait', { ns: 'common' })} />
  
  return (
    <>
      { selectedLocation ?
        <>
          {orders && orders.length > 0 && (
            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  {t('from_to', { from, to, ns: 'orders' })}
                </Grid.Column>
                <Grid.Column width={8} textAlign="right">
                  {t('page_number', { page_number: cursor + 1, ns: 'orders' })}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
          <div className={styles.OrderList}>
            {!orders && (
              <p className="align-center">
                {t('nothing_here', { ns: 'common' })}
                <br />
                {t('create_your_first_order', { ns: 'orders' })}
              </p>
            )}
            {!user.isPremium && (
              <Message align="center">
                <Message.Content>
                  <p className="large">{t('upgrade_to_premium', { ns: 'orders' })}</p>
                  <a className="link" href="/settings">
                    {t('upgrade_now', { ns: 'orders' })}
                  </a>
                </Message.Content>
              </Message>
            )}

            {disableNextButton && <Message content={t('no_more_orders', { ns: 'orders' })} />}
            {error && <Message warning content={t('something_wrong', { ns: 'common' , error })} />}

            {orders && orders.map((order) => (
              <Order key={order.id} order={order} cursor={cursor} />
            ))}

            <div className={styles.ButtonsContainer}>
              <span className={styles.ButtonWrapper}>
                <Button
                  className={cx(styles.Button, styles.Green)}
                  basic
                  color="green"
                  disabled={cursor === 0}
                  onClick={handleFetchPrevItems}
                  icon
                >
                  <Icon name="left angle" />
                  {t('previous', { ns: 'orders' })}
                </Button>
                <Button
                  className={cx(styles.Button, styles.Green)}
                  basic
                  color="green"
                  disabled={disableNextButton}
                  onClick={handleFetchNextItems}
                  icon
                >
                  {t('next', { ns: 'orders' })}
                  <Icon name="right angle" />
                </Button>
              </span>
            </div>

            {user.isPremium && user.hasLocations && (
              <div className={cx(styles.ButtonsContainerFixed, 'btn-container bottom fixed floating full-width')}>
                <span className={styles.ButtonWrapperCentered}>
                  <Button className={cx(styles.Button, styles.Green)} color="green" size="big" onClick={() => history.push('/shopping-list/first-step')} disabled={!selectedLocation}>
                    {t('create_shopping_list', { ns: 'orders' })}
                  </Button>
                </span>
              </div>
            )}
          </div>
        </>
        :
        <Message className={styles.Message}>
          <Message.Content className="align-center">{t('select_a_location', { ns: 'orders' })}</Message.Content>
        </Message>
      }
    </>
  )
}

export default Orders