import React from 'react'
import { Grid, Icon } from 'semantic-ui-react'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'
import SelectInput from '../../UI/Form/SelectInput'
import TextInput from '../../UI/Form/TextInput'
import styles from '../../../assets/styles/modules/menu/AddMenuItem.module.scss'

const SubMenuItem = ({ index, items, fieldValueSetter, values, fieldArrayHelper, buttonRef, toggleEnterRef }) => {
  const { t } = useTranslation(['menu'])

  return (
    <Grid.Row className={cx(styles.Row, styles.SubMenuItem)} style={{ paddingTop: '0px' }}>
      <Grid.Column mobile={14} tablet={10} computer={10} style={{ paddingRight: '0px' }}>
        <SelectInput
          key={`submenuItem-${index}`}
          className={styles.DropDown + ' large'}
          placeholder={t('choose_item', { ns: 'menu' })}
          name={`subrecipes.${index}.id`}
          compact
          options={items}
          fieldValueSetter={fieldValueSetter}
          fieldValueToSet={`subrecipes.${index}.name`}
          buttonRef={buttonRef}
          toggleEnterRef={toggleEnterRef}
          buttonRefKind="add_sub_menu"
        />
      </Grid.Column>
      <Grid.Column mobile={14} tablet={5} computer={5} className={styles.Servings}>
        <TextInput name={`subrecipes.${index}.servings`} placeholder={t('servings', { ns: 'menu' })} type="text" fullHeight={true} />
      </Grid.Column>
      <Grid.Column width={1} className={styles.Controls}>
        <Icon
          className="pointer"
          name="trash"
          color="grey"
          alt="Remove"
          title="Remove"
          onClick={() => {
            if (values[index]['id']) {
              const name = values[index].name?.text || values[index].name
              if (window.confirm(`Remove "${name}"?`)) {
                fieldArrayHelper.remove(index)
              }
            } else {
              fieldArrayHelper.remove(index)
            }
          }}
        />
      </Grid.Column>
    </Grid.Row>
  )
}

export default SubMenuItem
