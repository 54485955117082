import React from 'react'
import {
    ModalContent,
    Button,
    Header,
    Modal,
    ModalActions
} from 'semantic-ui-react'
import styles from '../../../assets/styles/modules/Home.module.scss'
import { driver } from 'driver.js'
import 'driver.js/dist/driver.css'

const ButtonsModal = ({ open, setOpen, viewportWidth }) => {
    return (
        <Modal
            basic
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            size='small'
        >
            <Header>
                <p style={{ fontSize: '1.2em', textAlign: 'center' }}>Choose the topic you need help with</p>
            </Header>
            <ModalContent>
                <div className={styles.OptionButtonContainer}>
                    <Button
                        className={viewportWidth >= 768 ? '' : styles.Hide}
                        size='big'
                        style={{ minWidth: '70%', color: '#FFF', backgroundColor: '#26A658' }}
                        onClick={() => {
                            setOpen(false)
                            const ShoppingListDesktop = driver({
                                popoverClass: styles.Desktop,
                                showButtons: ['close'],
                                doneBtnText: 'X',
                                steps: [
                                    { popover: { title: '<p>Building Your Own Shopping List</p>', description: `<img width=\'700\' src=\'https:\/\/firebasestorage.googleapis.com\/v0\/b\/root-frontend.appspot.com\/o\/onboarding%2FSLDesktop.gif?alt=media&token=104a7376-227b-48cf-8205-02dcb4231df9&a=${Math.random()}\' />` } }
                                ],
                            })
                            ShoppingListDesktop.drive()
                        }}
                    >
                        Building Your Own Shopping List
                    </Button>
                    <Button
                        className={viewportWidth >= 768 ? styles.Hide : ''}
                        size='big'
                        style={{ minWidth: '70%', color: '#FFF', backgroundColor: '#26A658' }}
                        onClick={() => {
                            setOpen(false)
                            const ShoppingListWebmobile = driver({
                                popoverClass: styles.Webmobile,
                                showButtons: ['close'],
                                doneBtnText: 'X',
                                steps: [
                                    { popover: { title: '<p>Building Your Own Shopping List</p>', description: `<img src=\'https:\/\/firebasestorage.googleapis.com\/v0\/b\/root-frontend.appspot.com\/o\/onboarding%2FSLWebmobile.gif?alt=media&token=c11a8a0d-8848-4720-b51e-12efa64a725c&a=${Math.random()}\' />` } }
                                ]
                            })
                            ShoppingListWebmobile.drive()
                        }}
                    >
                        Building Your Own Shopping List
                    </Button>
                </div>
                <div className={styles.OptionButtonContainer}>
                    <Button
                        className={viewportWidth >= 768 ? '' : styles.Hide}
                        size='big'
                        style={{ minWidth: '70%', color: '#FFF', backgroundColor: '#26A658' }}
                        onClick={() => {
                            setOpen(false)
                            const NewDistributorDesktop = driver({
                                popoverClass: styles.Desktop,
                                showButtons: ['close'],
                                doneBtnText: 'X',
                                steps: [
                                    { popover: { title: '<p>Adding a New Distributor</p>', description: `<img width=\'700\' src=\'https:\/\/firebasestorage.googleapis.com\/v0\/b\/root-frontend.appspot.com\/o\/onboarding%2FNDDesktop.gif?alt=media&token=7cd4d692-e28d-4674-b895-f2ef37754319&a=${Math.random()}\' />` } }
                                ],
                            })
                            NewDistributorDesktop.drive()
                        }}
                    >
                        Adding a New Distributor
                    </Button>
                    <Button
                        className={viewportWidth >= 768 ? styles.Hide : ''}
                        size='big'
                        style={{ minWidth: '70%', color: '#FFF', backgroundColor: '#26A658' }}
                        onClick={() => {
                            setOpen(false)
                            const NewDistributorWebmobile = driver({
                                popoverClass: styles.Webmobile,
                                showButtons: ['close'],
                                doneBtnText: 'X',
                                steps: [
                                    { popover: { title: '<p>Adding a New Distributor</p>', description: `<img src=\'https:\/\/firebasestorage.googleapis.com\/v0\/b\/root-frontend.appspot.com\/o\/onboarding%2FNDWebmobile.gif?alt=media&token=88f41728-481d-4c31-93f0-1c4e3bb8e5e6&a=${Math.random()}\' />` } }
                                ]
                            })
                            NewDistributorWebmobile.drive()
                        }}
                    >
                        Adding a New Distributor
                    </Button>
                </div>
                <div className={styles.OptionButtonContainer}>
                    <Button
                        className={viewportWidth >= 768 ? '' : styles.Hide}
                        size='big'
                        style={{ minWidth: '70%', color: '#FFF', backgroundColor: '#26A658' }}
                        onClick={() => {
                            setOpen(false)
                            const SideExtraDesktop = driver({
                                popoverClass: styles.Desktop,
                                showButtons: ['close'],
                                doneBtnText: 'X',
                                steps: [
                                    { popover: { title: '<p>Creating a Side/Extra Menu Item</p>', description: `<img width=\'700\' src=\'https:\/\/firebasestorage.googleapis.com\/v0\/b\/root-frontend.appspot.com\/o\/onboarding%2FSEDesktop.gif?alt=media&token=de1c352b-e716-492a-9dca-fc9dfd572127&a=${Math.random()}\' />` } }
                                ],
                            })
                            SideExtraDesktop.drive()
                        }}
                    >
                        Creating a Side/Extra Menu Item
                    </Button>
                    <Button
                        className={viewportWidth >= 768 ? styles.Hide : ''}
                        size='big'
                        style={{ minWidth: '70%', color: '#FFF', backgroundColor: '#26A658' }}
                        onClick={() => {
                            setOpen(false)
                            const SideExtraWebmobile = driver({
                                popoverClass: styles.Webmobile,
                                showButtons: ['close'],
                                doneBtnText: 'X',
                                steps: [
                                    { popover: { title: '<p>Creating a Side/Extra Menu Item</p>', description: `<img src=\'https:\/\/firebasestorage.googleapis.com\/v0\/b\/root-frontend.appspot.com\/o\/onboarding%2FSEWebmobile.gif?alt=media&token=507e63e3-8e84-4f2a-b30d-a960fb99a958&a=${Math.random()}\' />` } }
                                ]
                            })
                            SideExtraWebmobile.drive()
                        }}
                    >
                        Creating a Side/Extra Menu Item
                    </Button>
                </div>
                <div className={styles.OptionButtonContainer}>
                    <Button
                        className={viewportWidth >= 768 ? '' : styles.Hide}
                        size='big'
                        style={{ minWidth: '70%', color: '#FFF', backgroundColor: '#26A658' }}
                        onClick={() => {
                            setOpen(false)
                            const SingleMenuItemDesktop = driver({
                                popoverClass: styles.Desktop,
                                showButtons: ['close'],
                                doneBtnText: 'X',
                                steps: [
                                    { popover: { title: '<p>Creating a Single Menu Item</p>', description: `<img width=\'700\' src=\'https:\/\/firebasestorage.googleapis.com\/v0\/b\/root-frontend.appspot.com\/o\/onboarding%2FSMIDesktop.gif?alt=media&token=7726f3bf-f6a0-4892-8cc5-a2565f5048f9&a=${Math.random()}\' />` } }
                                ],
                            })
                            SingleMenuItemDesktop.drive()
                        }}
                    >
                        Creating a Single Menu Item
                    </Button>
                    <Button
                        className={viewportWidth >= 768 ? styles.Hide : ''}
                        size='big'
                        style={{ minWidth: '70%', color: '#FFF', backgroundColor: '#26A658' }}
                        onClick={() => {
                            setOpen(false)
                            const SingleMenuItemWebmobile = driver({
                                popoverClass: styles.Webmobile,
                                showButtons: ['close'],
                                doneBtnText: 'X',
                                steps: [
                                    { popover: { title: '<p>Creating a Single Menu Item</p>', description: `<img src=\'https:\/\/firebasestorage.googleapis.com\/v0\/b\/root-frontend.appspot.com\/o\/onboarding%2FSMIWebmobile.gif?alt=media&token=d2cda864-7b07-4743-85df-df563de36b42&a=${Math.random()}\' />` } }
                                ]
                            })
                            SingleMenuItemWebmobile.drive()
                        }}
                    >
                        Creating a Single Menu Item
                    </Button>
                </div>
                <div className={styles.OptionButtonContainer}>
                    <Button
                        className={viewportWidth >= 768 ? '' : styles.Hide}
                        size='big'
                        style={{ minWidth: '70%', color: '#FFF', backgroundColor: '#26A658' }}
                        onClick={() => {
                            setOpen(false)
                            const AddStockItemDesktop = driver({
                                popoverClass: styles.Desktop,
                                showButtons: ['close'],
                                doneBtnText: 'X',
                                steps: [
                                    { popover: { title: '<p>Adding a Stock Item</p>', description: `<img width=\'700\' src=\'https:\/\/firebasestorage.googleapis.com\/v0\/b\/root-frontend.appspot.com\/o\/onboarding%2FASDesktop.gif?alt=media&token=db447545-643b-4091-be9e-35932297d559&a=${Math.random()}\' />` } }
                                ],
                            })
                            AddStockItemDesktop.drive()
                        }}
                    >
                        Adding a Stock Item
                    </Button>
                    <Button
                        className={viewportWidth >= 768 ? styles.Hide : ''}
                        size='big'
                        style={{ minWidth: '70%', color: '#FFF', backgroundColor: '#26A658' }}
                        onClick={() => {
                            setOpen(false)
                            const AddStockItemWebmobile = driver({
                                popoverClass: styles.Webmobile,
                                showButtons: ['close'],
                                doneBtnText: 'X',
                                steps: [
                                    { popover: { title: '<p>Adding a Stock Item</p>', description: `<img src=\'https:\/\/firebasestorage.googleapis.com\/v0\/b\/root-frontend.appspot.com\/o\/onboarding%2FASWebmobile.gif?alt=media&token=5b37ab43-700a-4e10-8e4a-4b5204140f3e&a=${Math.random()}\' />` } }
                                ]
                            })
                            AddStockItemWebmobile.drive()
                        }}
                    >
                        Adding a Stock Item
                    </Button>
                </div>
                <div className={styles.OptionButtonContainer}>
                    <Button
                        className={viewportWidth >= 768 ? '' : styles.Hide}
                        size='big'
                        style={{ minWidth: '70%', color: '#FFF', backgroundColor: '#26A658' }}
                        onClick={() => {
                            setOpen(false)
                            const AddStockItemDesktop = driver({
                                popoverClass: styles.Desktop,
                                showButtons: ['close'],
                                doneBtnText: 'X',
                                steps: [
                                    { popover: { title: '<p>Deleting, Archiving or Editing Stock Items</p>', description: `<img width=\'700\' src=\'https:\/\/firebasestorage.googleapis.com\/v0\/b\/root-frontend.appspot.com\/o\/onboarding%2FESDesktop.gif?alt=media&token=17cd53c3-cd28-48c8-8589-e2a1b225489c&a=${Math.random()}\' />` } }
                                ],
                            })
                            AddStockItemDesktop.drive()
                        }}
                    >
                        Deleting, Archiving or Editing Stock Items
                    </Button>
                    <Button
                        className={viewportWidth >= 768 ? styles.Hide : ''}
                        size='big'
                        style={{ minWidth: '70%', color: '#FFF', backgroundColor: '#26A658' }}
                        onClick={() => {
                            setOpen(false)
                            const AddStockItemWebmobile = driver({
                                popoverClass: styles.Webmobile,
                                showButtons: ['close'],
                                doneBtnText: 'X',
                                steps: [
                                    { popover: { title: '<p>Deleting, Archiving or Editing Stock Items</p>', description: `<img src=\'https:\/\/firebasestorage.googleapis.com\/v0\/b\/root-frontend.appspot.com\/o\/onboarding%2FESWebmobile.gif?alt=media&token=4950ff77-9949-4900-9f9c-3bb582614df0&a=${Math.random()}\' />` } }
                                ]
                            })
                            AddStockItemWebmobile.drive()
                        }}
                    >
                        Deleting, Archiving or Editing Stock Items
                    </Button>
                </div>
                <div className={styles.OptionButtonContainer}>
                    <Button
                        className={viewportWidth >= 768 ? '' : styles.Hide}
                        size='big'
                        style={{ minWidth: '70%', color: '#FFF', backgroundColor: '#26A658' }}
                        onClick={() => {
                            setOpen(false)
                            const AddStockItemDesktop = driver({
                                popoverClass: styles.Desktop,
                                showButtons: ['close'],
                                doneBtnText: 'X',
                                steps: [
                                    { popover: { title: '<p>Updating Stock Amounts</p>', description: `<img width=\'700\' src=\'https:\/\/firebasestorage.googleapis.com\/v0\/b\/root-frontend.appspot.com\/o\/onboarding%2FUSDesktop.gif?alt=media&token=b815d1c5-8a9a-4e62-b3d8-d6b76c9dcdf5&a=${Math.random()}\' />` } }
                                ],
                            })
                            AddStockItemDesktop.drive()
                        }}
                    >
                        Updating Stock Amounts
                    </Button>
                    <Button
                        className={viewportWidth >= 768 ? styles.Hide : ''}
                        size='big'
                        style={{ minWidth: '70%', color: '#FFF', backgroundColor: '#26A658' }}
                        onClick={() => {
                            setOpen(false)
                            const AddStockItemWebmobile = driver({
                                popoverClass: styles.Webmobile,
                                showButtons: ['close'],
                                doneBtnText: 'X',
                                steps: [
                                    { popover: { title: '<p>Updating Stock Amounts</p>', description: `<img src=\'https:\/\/firebasestorage.googleapis.com\/v0\/b\/root-frontend.appspot.com\/o\/onboarding%2FUSWebmobile.gif?alt=media&token=4bb362b5-a8b6-49ac-9438-dcbbf45c5dd5&a=${Math.random()}\' />` } }
                                ]
                            })
                            AddStockItemWebmobile.drive()
                        }}
                    >
                        Updating Stock Amounts
                    </Button>
                </div>
                <div className={styles.OptionButtonContainer}>
                    <Button
                        className={viewportWidth >= 768 ? '' : styles.Hide}
                        size='big'
                        style={{ minWidth: '70%', color: '#FFF', backgroundColor: '#26A658' }}
                        onClick={() => {
                            setOpen(false)
                            const EditDistributorDesktop = driver({
                                popoverClass: styles.Desktop,
                                showButtons: ['close'],
                                doneBtnText: 'X',
                                steps: [
                                    { popover: { title: '<p>Editing Distributors</p>', description: `<img width=\'700\' src=\'https:\/\/firebasestorage.googleapis.com\/v0\/b\/root-frontend.appspot.com\/o\/onboarding%2FEDDesktop.gif?alt=media&token=1d3939d9-fc14-4493-a7cc-3252592fc98e&a=${Math.random()}\' />` } }
                                ],
                            })
                            EditDistributorDesktop.drive()
                        }}
                    >
                        Editing Distributors
                    </Button>
                    <Button
                        className={viewportWidth >= 768 ? styles.Hide : ''}
                        size='big'
                        style={{ minWidth: '70%', color: '#FFF', backgroundColor: '#26A658' }}
                        onClick={() => {
                            setOpen(false)
                            const EditDistributorWebmobile = driver({
                                popoverClass: styles.Webmobile,
                                showButtons: ['close'],
                                doneBtnText: 'X',
                                steps: [
                                    { popover: { title: '<p>Editing Distributors</p>', description: `<img src=\'https:\/\/firebasestorage.googleapis.com\/v0\/b\/root-frontend.appspot.com\/o\/onboarding%2FEDWebmobile.gif?alt=media&token=eae99c13-914b-4c23-80f3-368d43a5b61b&a=${Math.random()}\' />` } }
                                ]
                            })
                            EditDistributorWebmobile.drive()
                        }}
                    >
                        Editing Distributors
                    </Button>
                </div>
                <div className={styles.OptionButtonContainer}>
                    <Button
                        className={viewportWidth >= 768 ? '' : styles.Hide}
                        size='big'
                        style={{ minWidth: '70%', color: '#FFF', backgroundColor: '#26A658' }}
                        onClick={() => {
                            setOpen(false)
                            const SmartListDesktop = driver({
                                popoverClass: styles.Desktop,
                                showButtons: ['close'],
                                doneBtnText: 'X',
                                steps: [
                                    { popover: { title: '<p>Using the Smart Shopping List Generator</p>', description: `<img width=\'700\' src=\'https:\/\/firebasestorage.googleapis.com\/v0\/b\/root-frontend.appspot.com\/o\/onboarding%2FSLGDesktop.gif?alt=media&token=78916e41-8493-477d-b0e2-3e2e14c28b28&a=${Math.random()}\' />` } }
                                ],
                            })
                            SmartListDesktop.drive()
                        }}
                    >
                        Using the Smart Shopping List Generator
                    </Button>
                    <Button
                        className={viewportWidth >= 768 ? styles.Hide : ''}
                        size='big'
                        style={{ minWidth: '70%', color: '#FFF', backgroundColor: '#26A658' }}
                        onClick={() => {
                            setOpen(false)
                            const SmartListWebmobile = driver({
                                popoverClass: styles.Webmobile,
                                showButtons: ['close'],
                                doneBtnText: 'X',
                                steps: [
                                    { popover: { title: '<p>Using the Smart Shopping List Generator</p>', description: `<img src=\'https:\/\/firebasestorage.googleapis.com\/v0\/b\/root-frontend.appspot.com\/o\/onboarding%2FSLGWebmobile.gif?alt=media&token=776414e1-79e1-42f9-96ad-30e2d52d27f2&a=${Math.random()}\' />` } }
                                ]
                            })
                            SmartListWebmobile.drive()
                        }}
                    >
                        Using the Smart Shopping List Generator
                    </Button>
                </div>
                <div className={styles.OptionButtonContainer}>
                    <Button
                        className={viewportWidth >= 768 ? '' : styles.Hide}
                        size='big'
                        style={{ minWidth: '70%', color: '#FFF', backgroundColor: '#26A658' }}
                        onClick={() => {
                            setOpen(false)
                            const NewLocationDesktop = driver({
                                popoverClass: styles.Desktop,
                                showButtons: ['close'],
                                doneBtnText: 'X',
                                steps: [
                                    { popover: { title: '<p>Adding New Location</p>', description: `<img width=\'700\' src=\'https:\/\/firebasestorage.googleapis.com\/v0\/b\/root-frontend.appspot.com\/o\/onboarding%2FALDesktop.gif?alt=media&token=58708a96-e1f9-4cab-a2a0-e2b1ac851fe2&a=${Math.random()}\' />` } }
                                ],
                            })
                            NewLocationDesktop.drive()
                        }}
                    >
                        Adding New Location
                    </Button>
                    <Button
                        className={viewportWidth >= 768 ? styles.Hide : ''}
                        size='big'
                        style={{ minWidth: '70%', color: '#FFF', backgroundColor: '#26A658' }}
                        onClick={() => {
                            setOpen(false)
                            const NewLocationWebmobile = driver({
                                popoverClass: styles.Webmobile,
                                showButtons: ['close'],
                                doneBtnText: 'X',
                                steps: [
                                    { popover: { title: '<p>Adding New Location</p>', description: `<img src=\'https:\/\/firebasestorage.googleapis.com\/v0\/b\/root-frontend.appspot.com\/o\/onboarding%2FALWebmobile.gif?alt=media&token=67560a8d-6ca8-4568-acb8-fbf210d1c11a&a=${Math.random()}\' />` } }
                                ]
                            })
                            NewLocationWebmobile.drive()
                        }}
                    >
                        Adding New Location
                    </Button>
                </div>
            </ModalContent>
            <ModalActions>
                <div className={styles.CloseButton} onClick={() => setOpen(false)}>X</div>
            </ModalActions>
        </Modal>
    )
}

export default ButtonsModal
