import React from 'react'
import { useDispatch } from 'react-redux'
import ModalWrapper from '../../UI/Modal/ModalWrapper'
import { Button, Label } from 'semantic-ui-react'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import TextInput from '../../UI/Form/TextInput'
import * as Yup from 'yup'

import { changeUserPassword } from '../../../store/actions/userActions'
import btnStyles from '../../../assets/styles/modules/UI/Button.module.scss'
import { asyncActionFinish, closeModal } from '../../../store/actions'

const ChangePassword = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['validationMessages', 'changePassword', 'buttons'])

  const formFields = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  }
  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required(t('current_password', { ns: 'validationMessages' })),
    newPassword: Yup.string()
      .required(t('new_password', { ns: 'validationMessages' }))
      .min(6, t('new_password_characters', { amount: 6, ns: 'validationMessages' })),
    confirmPassword: Yup.string()
      .required()
      .test({
        name: 'Confirm password',
        // eslint-disable-next-line object-shorthand
        test: function (value) {
          // You can add any logic here to generate a dynamic message
          return value !== this.parent.newPassword
            ? this.createError({
                message: t('password_not_match', { ns: 'validationMessages' }),
                path: 'confirmPassword', // Fieldname
              })
            : true
        },
      }),
  })

  return (
    <ModalWrapper size="auto" header={t('change_password', { ns: 'changePassword'})}>
      <Formik
        initialValues={formFields}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            await changeUserPassword(values)
            setSubmitting(false)
            dispatch(asyncActionFinish(t('success', { ns: 'changePassword'})))
            dispatch(closeModal())
          } catch (error) {
            setErrors({
              auth: t('fail', { ns: 'changePassword'}),
            })
            setSubmitting(false)
          }
        }}
      >
        {({ isSubmitting, errors }) => (
          <Form autocomplete="off" className="ui form">
            {errors.auth && <Label basic color="red" content={errors.auth} style={{ marginBottom: 10 }} />}
            <TextInput name="currentPassword" placeholder={t('current_password', { ns: 'changePassword'})} type="password" />
            <TextInput name="newPassword" placeholder={t('new_password', { ns: 'changePassword'})} type="password" />
            <TextInput name="confirmPassword" placeholder={t('confirm_new_password', { ns: 'changePassword'})} type="password" />
            <br />
            <br />
            <div className="btn-container bottom fixed flex space-between full-width gap-16">
              <Button
                disabled={isSubmitting}
                className={btnStyles.Left}
                type="button"
                color="red"
                basic
                size="large"
                onClick={() => dispatch(closeModal())}
              >
                {t('cancel', { ns: 'buttons' })}
              </Button>
              <Button
                loading={isSubmitting}
                className={btnStyles.Right}
                type="submit"
                color="green"
                size="large"
              >
                {t('submit', { ns: 'buttons' })}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  )
}

export default ChangePassword
