import * as React from 'react'
import { Button, Grid, Label, Message, Confirm } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import TextInput from '../../UI/Form/TextInput'
import SelectInput from '../../UI/Form/SelectInput'
import { setPageTitle, receivedPos } from '../../../store/actions'
import ProfileImage from '../../ProfileImage/ProfileImage'
import RadioInput from '../../UI/Form/RadioInput'
import placeholderSrc from '../../../assets/images/locations/placeholder.svg'
import Spinner from '../../UI/Spinner/Spinner'
import { cloudFunctions } from '../../../config/firebase'
import { httpsCallable } from 'firebase/functions'
import useFirestoreCollection from '../../../hooks/useFirestoreCollection'
import { listenToSubscriptionPlans } from '../../../store/actions/subscriptionActions'
import { getSubscriptionsFromFirestore, listenToSubscriptions } from '../../../store/actions/subscriptionActions'
import styles from '../../../assets/styles/modules/locations/Location.module.scss'
import CustomDropdown from '../../UI/Form/CustomDropdown'
import toast, { Toaster } from 'react-hot-toast'
import cx from 'classnames'
import { driver } from "driver.js"
import "driver.js/dist/driver.css"
import { flaskAPIPost, kitchenTypeOptions } from '../../../shared/utility'

const AddLocation = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { user } = useSelector((state) => state.user)
  const { loading } = useSelector((state) => state.async)
  const { locationArray } = useSelector((state) => state.location)
  const [channels, setChannels] = React.useState([])
  const [loadingChannels, setLoadingChannels] = React.useState(false)
  const [duplicated, setDuplicated] = React.useState(false)
  const { t } = useTranslation(['location', 'buttons', 'validationMessages', 'common'])

  useFirestoreCollection({
    query: () => getSubscriptionsFromFirestore(),
    data: (subscriptions) => dispatch(listenToSubscriptions(subscriptions)),
    deps: [dispatch],
  })

  const fetchPlanPrices = async () => {
    const response = await httpsCallable(cloudFunctions, 'getSubscriptionPlansPricesFromFirestore')({})
    if (response.data.error) {
      dispatch(listenToSubscriptionPlans([]))
    } else {
      dispatch(listenToSubscriptionPlans(response.data))
    }
  }

  React.useEffect(() => {
    dispatch(setPageTitle(t('add_location', { ns: 'location' })))
    fetchPlanPrices()
  }, [dispatch])

  React.useEffect(() => {
    let driverObj = driver()
    if (user && history?.location?.search === '?firstTime=true') {
      setTimeout(() => {
        const element = document.getElementById('name')
        element.scrollIntoView({
          behavior: "smooth",
          block: "end"
        })

        driverObj = driver({
          showProgress: false,
          allowClose: false,
          nextBtnText: 'Next',
          prevBtnText: 'Previous',
          steps: [
            { element: '#name', popover: { title: 'Enter your location\'s name', side: "left", align: 'start' }},
            { element: '#street', popover: { title: 'Enter the street address of where your location is located', side: "left", align: 'start' }},
            { element: '#postcode', popover: { title: 'Enter the postal code of where your location is located', side: "left", align: 'start' }},
            { element: '#place', popover: { title: 'Enter the city where your location is located', side: "left", align: 'start' }},
            { element: '#kennitala', popover: { title: 'Enter your kennitala', side: "left", align: 'start' }},
            { element: '#phone', popover: { title: 'Enter your phone number', side: "left", align: 'start' }},
            { element: '#email', popover: { title: 'Enter your email address', side: "left", align: 'start' }},
            { element: '#RadiosContainer', popover: { description: 'If you want to duplicate stock and menu items from another location, select \"I want to duplicate Stock and Menu items from another location.\". Otherwise, skip to the last step', side: "left", align: 'start' }},
            { element: '#RadiosContainer', popover: { description: 'Click on the dropdown menu. Select the location you want to copy the stock and menu items from', side: "left", align: 'start' }},
            { element: '#SaveButton', popover: { title: 'Click "SAVE"', side: "left", align: 'start' }},
          ],
          onPopoverRender: (popover, { config, state }) => {
            const firstButton = document.createElement("button");
            firstButton.innerText = "Skip - Last Step";
            popover.footerButtons.appendChild(firstButton);
            firstButton.addEventListener("click", () => {
              driverObj.drive(9);
            });
          },
        })
    
        driverObj.drive()
      }, 100)
    }

    return () => driverObj.destroy()
  }, [user])

  if (loading || !user) return <Spinner content={t('please_wait', { ns: 'common' })} />

  const initialValues = {
    name: '',
    kitchenType: '',
    street: '',
    postcode: '',
    place: '',
    phone: '',
    email: '',
    kennitala: '',
    isActive: false,
    copyData: 0,
    locationPhotoURL: '',
    channels: [],
    locationData: null
  }

  const validationSchema = (duplicatedLocation) => {

    let schemaAttributes = {
      name: Yup.string().required(t('kitchen_name', { ns: 'validationMessages' })),
      kitchenType: Yup.string().required(t('kitchen_type', { ns: 'validationMessages' })),
      street: Yup.string().required(t('street', { ns: 'validationMessages' })),
      postcode: Yup.string().required(t('postcode', { ns: 'validationMessages' })),
      place: Yup.string().required(t('place', { ns: 'validationMessages' })),
      email: Yup.string().email(t('valid_email', { ns: 'validationMessages' })).required(t('email', { ns: 'validationMessages' })),
      kennitala: Yup.string().required(t('kennitala', { ns: 'validationMessages' })),
      isActive: Yup.bool().required(t('status', { ns: 'validationMessages' }))
    }

    if (duplicatedLocation) {
      schemaAttributes.locationData = Yup.object().nullable(true).test('validate', t('location_required', { ns: 'validationMessages' }), (val) => val !== null)
    }

    return Yup.object().shape(schemaAttributes)
  }

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            const { type, ...rest } = values
            await flaskAPIPost({ endpoint: `/v2/locations/${user.id}/add`, payload: { values: {...rest, sourceLocationId: values.copyData ? values.locationData?.id : null }}})
            setSubmitting(false)
            history.push('/locations')
          } catch (error) {
            setErrors({ location: error.message, errorType: error.details })
            setSubmitting(false)
            toast(t('there_was_an_error', { ns: 'location' }), {
              style: {
                padding: '16px',
                color: 'white',
                backgroundColor: '#F2711C',
                opacity: 1,
                position: 'absolute',
                right: 20,
              },
              duration: 5000,
            })
          }
        }}
        validationSchema={validationSchema(duplicated)}
      >
        {({ isSubmitting, errors, values, setFieldValue, setSubmitting, isValid }) => {

          setDuplicated(values.copyData === 1)
          if (values.copyData === 0) {
            values.locationData = null
            delete errors['locationData']
          }

          return (
            <Form autocomplete="off" className={cx(styles.AddLocationContainer, 'ui form')}>
              {errors.location && <Label basic color="red" content={errors.location} style={{ marginBottom: 10 }} />}
              <Grid stackable={true}>
                <Grid.Row>
                  <Grid.Column width={5} className={styles.ImageUploadContainer} vertical-align="middle">
                    <ProfileImage
                      placeholder={placeholderSrc}
                      currentImage={values.locationPhotoURL}
                      fieldName="locationPhotoURL"
                      fieldValueSetter={setFieldValue}
                      disabled={isSubmitting}
                    />
                  </Grid.Column>
                  <Grid.Column width={11}>
                    <TextInput
                      id="name"
                      name="name"
                      textLabel={t('kitchen_name', { ns: 'location' })}
                      placeholder={t('kitchen_name', { ns: 'location' })}
                      type="text"
                      disabled={isSubmitting}
                    />
                    <p className={styles.KitchenTypeLabel}>{t('kitchen_type', { ns: 'location' })}</p>
                    <SelectInput
                      name={'kitchenType'}
                      placeholder={t('kitchen_type', { ns: 'location' })}
                      options={kitchenTypeOptions}
                      fieldValueSetter={setFieldValue}
                      fieldValueToSet={'type'}
                      disabled={isSubmitting}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <TextInput
                      id="street"
                      name="street"
                      textLabel={t('address', { ns: 'location' })}
                      placeholder={t('street', { ns: 'location' })}
                      type="text"
                      disabled={isSubmitting}
                    />
                    <TextInput
                      id="postcode"
                      name="postcode"
                      placeholder={t('postcode', { ns: 'location' })}
                      type="text"
                      className={styles.PostcodeField}
                      disabled={isSubmitting}
                    />
                    <TextInput
                      id="place"
                      name="place"
                      placeholder={t('place', { ns: 'location' })}
                      type="text"
                      className={styles.PlaceField}
                      disabled={isSubmitting}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <TextInput
                      id="kennitala"
                      name="kennitala"
                      placeholder={t('id_number', { ns: 'location' })}
                      type="kennitala"
                      disabled={isSubmitting}
                      textLabel={t('id_number', { ns: 'location' })}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row verticalAlign="bottom">
                  <Grid.Column width={5}>
                    <TextInput
                      id="phone"
                      className='left-icon-input'
                      name="phone"
                      icon="phone"
                      iconPosition="left"
                      placeholder={t('phone', { ns: 'location' })}
                      type="text"
                      disabled={isSubmitting}
                    />
                  </Grid.Column>
                  <Grid.Column width={11}>
                    <TextInput
                      id="email"
                      className='left-icon-input'
                      name="email"
                      icon="envelope"
                      iconPosition="left"
                      placeholder={t('email', { ns: 'location' })}
                      type="email"
                      disabled={isSubmitting}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row id="RadiosContainer" className={styles.RadiosContainer}>
                  <Grid.Column width={16}>
                    <RadioInput
                      className={styles.RadioInput}
                      type="radio"
                      name="copyData"
                      value={0}
                      label={
                        <label className={styles.Label}>
                          <Trans
                            t={t}
                            ns={'location'}
                            i18nKey={'new_stock'}
                          >
                            I will create <strong>new</strong> Stock and Menu items for this location.
                          </Trans>
                        </label>
                      }
                      disabled={isSubmitting}
                    />
                    <RadioInput
                      className={styles.RadioInput}
                      type="radio"
                      name="copyData"
                      value={1}
                      label={
                        <>
                          <label className={styles.Label}>
                            <Trans
                              t={t}
                              ns={'location'}
                              i18nKey={'duplicate'}
                            >
                              I want to <strong>duplicate</strong> Stock and Menu items from another location.
                            </Trans>
                          </label>
                          <div className={styles.DropdownContainer}>
                            <CustomDropdown
                              name={'locationData'}
                              placeholder={values.locationData?.text ? values.locationData.text : t('select_location', { ns: 'location' })}
                              options={locationArray}
                              fieldValueToSet={'locationData'}
                              fieldValueSetter={setFieldValue}
                              compact
                              disabled={!duplicated}
                              distributorFlow={false}
                            />
                          </div>
                        </>
                      }
                      disabled={isSubmitting}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <div className={cx(styles.ButtonsContainer, 'btn-container bottom fixed floating full-width')}>
                <span className={styles.ButtonWrapper}>
                  <Button
                    id="SaveButton"
                    className={cx(styles.Button, styles.Green)}
                    loading={isSubmitting}
                    disabled={!isValid || values.name === '' || isSubmitting}
                    type="submit"
                    color="green"
                    content={t('save', { ns: 'buttons' })}
                    floated="right"
                    size="big"
                  />
                </span>
              </div>
            </Form>
        )}}
      </Formik>
      <Toaster />
    </div>
  )
}

export default AddLocation
