import React from 'react'
import { useSelector } from 'react-redux'
import ForgotPassword from '../../User/ForgotPassword'
import OrderSummary from '../../Orders/OrderSummary'
import ChangePassword from '../../User/Settings/ChangePassword'
import CompanyDetails from '../../User/Settings/CompanyDetails'
import ChangeEmail from '../../User/Settings/ChangeEmail'
import ChangePreferredCurrency from '../../User/Settings/ChangePreferredCurrency'
import AddSubMenuItem from '../../Menu/SubMenuItem/AddSubMenuItem'
import Terms from '../../Terms/Modal/Terms'
import Privacy from '../../Terms/Modal/Privacy'

const ModalManager = () => {
  const modalLookup = {
    ForgotPassword,
    AddSubMenuItem,
    OrderSummary,
    ChangePassword,
    CompanyDetails,
    ChangeEmail,
    Terms,
    Privacy,
    ChangePreferredCurrency,
  }

  const currentModal = useSelector((state) => state.modal)
  let renderedModal = null

  if (currentModal) {
    const { modalType, modalProps } = currentModal
    const ModalComponent = modalLookup[modalType]
    if (ModalComponent) {
      renderedModal = <ModalComponent {...modalProps} />
    }
  }

  return renderedModal
}

export default ModalManager
