import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styles from '../../../assets/styles/modules/Home.module.scss'
import { Link } from 'react-router-dom'
import distributorIconDarkGreenSrc from '../../../assets/images/home/distributors-dark-green.svg'
import distributorIconGreenSrc from '../../../assets/images/home/distributors-green.svg'
import distributorIconGreySrc from '../../../assets/images/navigation/distributors.svg'
import Spinner from '../../UI/Spinner/Spinner'
import { updateDistributors } from '../../../store/actions'
import { getUserId } from '../../../store/actions/authActions'
import { flaskAPIGet } from '../../../shared/utility'

const Distributors = () => {
  const dispatch = useDispatch()
  const { distributors } = useSelector((state) => state.distributor)
  const { loading } = useSelector((state) => state.async)
  const { t } = useTranslation(['home', 'distributor'])
  const [firstLoad, setFirstLoad] = React.useState(true)

  React.useEffect(() => {
    const loadDistributors = async () => {
      const userId = await getUserId()
      const distributorsData = await flaskAPIGet({ endpoint: `/v2/distributors/user/${userId}?mode=user` })
      dispatch(updateDistributors(distributorsData))
      setFirstLoad(false)
    }

    if (firstLoad && !distributors.length) {
      loadDistributors()
    }
  }, [dispatch])

  const classes = [styles.Task]
  if (distributors.length) classes.push(styles.Done)

  if (loading) return <div className={classes.join(' ')}><Spinner content={t('loading', { ns: 'home' })} /></div>

  return (
    <div className={classes.join(' ')}>
      <Link className={styles.TaskLink} to={`/distributors?firstTime=${distributors.length >= 1}`}>
        <div className={styles.TaskIcon}>
          {!distributors.length ? (
            <img src={distributorIconGreySrc} alt="" border="0" />
          ) : (
            <>
              <img className={styles.TaskIconImg} src={distributorIconGreenSrc} alt="" border="0" />
              <img className={styles.TaskIconImgHover} src={distributorIconDarkGreenSrc} alt="" border="0" />
            </>
          )}
        </div>
        {distributors.length === 0 ? (
          <>
            <strong className="xx-large">1.</strong> <span>{t('add_distributors', { ns: 'distributor' })}</span>
          </>
        ) : (
          t('manage_distributors', { ns: 'home' })
        )}
      </Link>
    </div>
  )
}

export default Distributors
