import React, { useEffect, useState } from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import { Formik, Form, FieldArray } from 'formik'
import { Button, Grid, Icon } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import TextInput from '../UI/Form/TextInput'
import RadioInput from '../UI/Form/RadioInput'
import CustomDropdown from '../UI/Form/CustomDropdown'
import Loading from './Loading'
import { cloudFunctions } from '../../config/firebase'
import { httpsCallable } from 'firebase/functions'
import { setPageTitle } from '../../store/actions'
import { getUserIdToken } from '../../store/actions/authActions'
import cx from 'classnames'
import styles from '../../assets/styles/modules/shoppingList/shoppingList.module.scss'

const ShoppingList = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation(['stock', 'validationMessages', 'orders'])
  const { selectedLocation } = useSelector((state) => state.location)
  const { user } = useSelector((state) => state.user)
  const [allRecipes, setAllRecipes] = useState([])
  const [isLoadingRecipes, setIsLoadingRecipes] = useState(true)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    dispatch(setPageTitle('Shopping list'))
    
    const getRecipes = async () => {
      const response = await httpsCallable(cloudFunctions, 'getRecipesByLocation')({ locationId: selectedLocation ? selectedLocation.id : null })
      const data = response.data.map((el) => {
        const ingredients = []
        if (el.ingredients !== undefined) {
            Object.keys(el.ingredients).forEach((key) => {
                ingredients.push(el.ingredients[key].name)
            })
        }

        const subrecipes = []
        if (el.subrecipes !== undefined) {
            Object.keys(el.subrecipes).forEach((key) => {
                subrecipes.push(el.subrecipes[key].name)
            })
        }

        if ((ingredients.length > 0) && (subrecipes.length > 0)) {
          subrecipes.unshift('')
        }

        return { ...el, value: el.id, text: el.name, description: ingredients.map(e => e && e.split(' - Item Number')[0]).join(', ').concat(subrecipes.map(e => e && e.split(' - Item Number')[0]).join(', ')) }
      })
      setAllRecipes(data.filter(recipe => !recipe.deleted))
      setIsLoadingRecipes(false)
    }

    getRecipes()
  }, [])

  if (loading) return <Loading />

  if (!selectedLocation) {
    return <Redirect to="/locations" />
  }

  const initialValues = {
    recipes: [
      {
        data: null,
        amount: 0,
      },
    ]
  }

  const validationSchema = () => {
    let schemaAttributes = {
      recipes: Yup.array().of(
        Yup.object().shape({
          data: Yup.object().nullable(true).test('validate', t('dish', { ns: 'validationMessages' }), (val) => val !== null),
          amount: Yup.number().typeError(t('invalid_number', { ns: 'validationMessages' })).min(1, t('minimum_quantity', { ns: 'orders', amount: 1 })).required(t('amount', { ns: 'validationMessages' }))
        })
      )
    }
    return Yup.object().shape(schemaAttributes)
  }

  const getIngredients = (ingredients, subrecipes) => {
    const ingr = []
    if (ingredients !== undefined) {
      Object.keys(ingredients).forEach((key) => {
        ingr.push(ingredients[key].name)
      })
    }
    const sub = []
    if (subrecipes !== undefined) {
      Object.keys(subrecipes).forEach((key) => {
        sub.push(subrecipes[key].name)
      })
    }
    if ((ingr.length > 0) && (sub.length > 0)) {
      sub.unshift('')
    }

    return <p className={styles.Ingredients}>{ingr.map(e => e && e.split(' - Item Number')[0]).join(', ').concat(sub.map(e => e && e.split(' - Item Number')[0]).join(', '))}</p>
  }

  return (
    <div>
      <p className={styles.Title}>{t('create_a_shopping_list', { ns: 'orders' })}</p>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          setLoading(true)
          const user_token = await getUserIdToken()
          const recipesData = values.recipes.map((recipe) => ({ name: recipe.data.name, description: recipe.data.description, amount: recipe.amount }))
          const recipes = values.recipes.map((recipe) => ({ recipeId: recipe.data.id, amount: recipe.amount }))
          const res = await httpsCallable(
            cloudFunctions,
            'flaskAPIPostRequest'
          )({
            user_token,
            endpoint: '/v2/calculator/',
            payload: {
              userId: user.id,
              locationId: selectedLocation.id,
              recipes
            }
          })
          setLoading(false)
          history.push(`/shopping-list/create/location/${selectedLocation.id}`, { recipesData, calculatedIngredients: res.data })
        }}
        validationSchema={validationSchema()}
      >
        {({ isSubmitting, errors, setFieldValue, values, handleReset }) => {
          return (
            <Form className={styles.FormContainer}>
              <Grid style={{ padding: '0 !important' }}>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <p className={styles.DishesTitle}>Dishes</p>
                  </Grid.Column>  
                  <Grid.Column width={11} verticalAlign="middle" textAlign="right">
                    <RadioInput
                      className={styles.ItemType}
                      name="type"
                      value="all"
                      label={t('add_all_the_dishes', { ns: 'orders' })}
                      onClick={() => {
                        values.recipes = Array(allRecipes.length).fill().map((_, i) => ( { data: null, amount: 0 }))
                        allRecipes.forEach((recipe, index) => {
                          values.recipes[index].data = recipe
                        })
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
                <FieldArray
                  name="recipes"
                  render={(arrayHelpers) => (
                    <>
                      { values.recipes.length > 0 &&
                        values.recipes.map((_, index) => (
                          <>
                            { values.recipes[index].data && values.recipes[index].data.name ?
                              <Grid.Row className={styles.ListRow}>
                                <Grid.Column mobile={10} tablet={11} computer={11}>
                                  <h3 className={styles.RecipeName}>{values.recipes[index].data.name}</h3>
                                  { getIngredients(values.recipes[index].data.ingredients, values.recipes[index].data.subrecipes) }
                                </Grid.Column>
                                <Grid.Column mobile={4} tablet={3} computer={3} className={styles.InputRow}>
                                  <TextInput className={styles.Amount} name={`recipes.${index}.amount`} type="number" shoppingList={true} />
                                </Grid.Column>
                                <Grid.Column width={1}>
                                  <Icon
                                    className={cx('pointer', styles.Trash)}
                                    name="trash"
                                    color="grey"
                                    alt="Remove"
                                    title="Remove"
                                    onClick={() => { arrayHelpers.remove(index) }}
                                  />
                                </Grid.Column>
                              </Grid.Row>
                              :
                              <Grid.Row>
                                <Grid.Column width={14}>
                                  <CustomDropdown
                                    name={`recipes.${index}.data`}
                                    placeholder={t('type_dish', { ns: 'orders' })}
                                    options={allRecipes}
                                    fieldValueSetter={setFieldValue}
                                    fieldValueToSet={`recipes.${index}.data`}
                                    compact
                                    loading={isLoadingRecipes}
                                    additionsAllowed={false}
                                  />
                                </Grid.Column>
                                <Grid.Column width={1}>
                                  <Icon
                                    className={cx('pointer', styles.Trash)}
                                    name="trash"
                                    color="grey"
                                    alt="Remove"
                                    title="Remove"
                                    onClick={() => { arrayHelpers.remove(index) }}
                                  />
                                </Grid.Column>
                              </Grid.Row>
                            }  
                          </>
                        ))
                      }
                      <Grid.Row>
                        <p className={styles.DesktopButton}>
                          <span className="pointer" onClick={() =>  arrayHelpers.push({ data: null, amount: 0 })}>
                            <Icon color="green" name="plus circle" /> {t('add_dish', { ns: 'orders' })}
                          </span>
                        </p>
                      </Grid.Row>
                      <div className={cx(styles.ButtonsContainer, 'btn-container bottom fixed floating full-width')}>
                        <span className={styles.ButtonWrapper}>
                          <Button
                            className={cx(styles.Button, styles.Orange)}
                            type="button"
                            basic
                            color="orange"
                            size="big"
                            onClick={async () => {
                              await handleReset()
                              history.push('/shopping-list/first-step')
                            }}
                            content={t('back', { ns: 'buttons' })}
                          />
                          <Button
                            className={cx(styles.Button, styles.Green)}
                            loading={isSubmitting}
                            type="submit"
                            color="green"
                            content={t('generate_shopping_list', { ns: 'buttons' })}
                            size="big"
                          />
                        </span>
                      </div>
                    </>
                  )}  
                />
              </Grid>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default ShoppingList