import React from 'react'
import { useTranslation } from 'react-i18next'
import splashStyles from '../../assets/styles/modules/Splash.module.scss'
import splashLogoSrc from '../../assets/images/pictoralmark_purple.svg'
import orangeLogoSrc from '../../assets/images/Orange.svg'

const SplashScreen = () => {
  const { t } = useTranslation(['greenbytes'])

  return (
    <div className={splashStyles.SplashScreen}>
      <div className={splashStyles.Branding}>
        <div className={splashStyles.Logo}>
          <img src={splashLogoSrc} border="0" alt="GreenBytes" />
        </div>
        <div className={splashStyles.Slogan}>{t('slogan', { ns: 'greenbytes' })}</div>
      </div>
        <img className={splashStyles.BackgroundLogo} src={orangeLogoSrc} border="0" alt="Orange" />
    </div>
  )
}

export default SplashScreen
