import React, { useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cloudFunctions } from '../../../config/firebase'
import { Grid, Button, Table, Message } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { Formik, Form, FieldArray } from 'formik'
import { httpsCallable } from 'firebase/functions'
import TextInput from '../../UI/Form/TextInput'
import { closeModal, asyncActionError } from '../../../store/actions'
import { getStockArray, getUnitArray } from '../../../shared/utility'
import ModalWrapper from '../../UI/Modal/ModalWrapper'
import Ingredient from '../Ingredient/Ingredient'
import AddIngredientButton from '../Form/Button/AddIngredientButton'
import btnStyles from '../../../assets/styles/modules/UI/Button.module.scss'
import buttonStyles from '../../../assets/styles/modules/UI/Button.module.scss'
import styles from '../../../assets/styles/modules/menu/AddMenuItem.module.scss'
import SubMenuItem from './SubMenuItem'
import AddSubMenuItemButton from '../Form/Button/AddSubMenuItemButton'
import RadioInput from '../../UI/Form/RadioInput'
import { updateMenuItems } from '../../../store/actions'
import { flaskAPIGet } from '../../../shared/utility'
import { getUserId } from '../../../store/actions/authActions'

const AddSubMenuItem = (props) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['validationMessages', 'menu', 'buttons'])
  const buttonRef = useRef(null)
  const toggleEnterRef = useRef(false)
  const focusedRef = useRef(true)
  const { menuItems } = useSelector((state) => state.menu)
  const { selectedLocation } = useSelector((state) => state.location)

  useEffect(() => {
    return () => {
      buttonRef.current = null;
      toggleEnterRef.current = null;
    }
  }, [])

  const formFields = {
    type: 'side',
    name: '',
    ingredients: [
      {
        id: '',
        name: '',
        amount: '',
        unit: '',
      },
    ],
    subrecipes: [],
    notes: '',
  }

  const validationSchema = Yup.object({
    name: Yup.string().required(t('item_name', { ns: 'validationMessages' })),
    ingredients: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().required(t('ingredient', { ns: 'validationMessages' })),
          amount: Yup.number().typeError(t('number', { ns: 'validationMessages' })).min(1, t('too_low', { ns: 'validationMessages' })).required(t('amount', { ns: 'validationMessages' })),
          unit: Yup.string().required(t('unit', { ns: 'validationMessages' })),
        })
      )
      .required(t('add_ingredient', { ns: 'validationMessages' })),
    subrecipes: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().required(t('item', { ns: 'validationMessages' })),
        servings: Yup.number().typeError(t('number', { ns: 'validationMessages' })).min(1, t('too_low', { ns: 'validationMessages' })).required(t('servings', { ns: 'validationMessages' })),
      })
    ),
  })

  const stockArr = getStockArray(props.stock) // CHECK: AddSubMenuItem is not used anymore
  const unitArr = getUnitArray()

  return (
    <ModalWrapper size="small" header={`Add ${props.title}`} scrollable={false}>
      <Formik
        initialValues={formFields}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          if (values.ingredients.length > 0) {
            try {
              const userId = await getUserId()
              const res = await httpsCallable(cloudFunctions, 'addMenuItem')({ values })
              let newRecipe = []
              newRecipe.push(res.data)
              dispatch(updateMenuItems(newRecipe.concat(menuItems)))
              setSubmitting(false)
              setTimeout(async () => {
                try {
                  const location = selectedLocation.id ? selectedLocation.id : selectedLocation
                  const menuItems = await flaskAPIGet({ endpoint: `/v2/menu/user/${userId}/location/${location}/recipes` })
                  dispatch(updateMenuItems(menuItems, location))
                } catch (error) {
                  dispatch(asyncActionError(error, t('could_not_be_updated', { ns: 'menu' })))
                }
              }, 30000)
              dispatch(closeModal())
            } catch (error) {
              setErrors({ recipes: error.message })
              setSubmitting(false)
            }
          } else {
            setErrors({ recipes: t('add_ingredient', { ns: 'validationMessages' })})
            setSubmitting(false)
          }
        }}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, values, errors, setFieldValue }) => (
          <Form autocomplete="off" className="ui form">
            {errors.recipes && <Message error content={errors.recipes} />}
            <Grid style={{ marginBottom: '30px' }}>
              {props.menuItems && props.menuItems.length > 0 && (
                <Grid.Row>
                  <Grid.Column width={8}>
                    <RadioInput
                      className={styles.ItemType}
                      name="type"
                      value="single"
                      label={t('single_item', { ns: 'menu' })}
                      onClick={() => {
                        setFieldValue('subrecipes', [])
                        setFieldValue('ingredients', [
                          {
                            id: '',
                            name: '',
                            amount: '',
                            unit: '',
                          },
                        ])
                      }}
                    />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <RadioInput
                      className={styles.ItemType}
                      name="type"
                      value="side"
                      label={t('side_extra', { ns: 'menu' })}
                      onClick={() => {
                        setFieldValue('ingredients', [
                          {
                            id: '',
                            name: '',
                            amount: '',
                            unit: '',
                          },
                        ])
                        setFieldValue('subrecipes', [])
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
              )}
              <Grid.Row>
                <Grid.Column width={16}>
                  <TextInput name="name" placeholder={t('item_name', { ns: 'menu' })} type="text" />
                </Grid.Column>
              </Grid.Row>
              <FieldArray
                name="ingredients"
                render={(arrayHelpers) => (
                  <>
                    <Grid.Row>
                      <Grid.Column width={16}>
                        {values.ingredients.length > 0 && (
                          <Table striped compact className="menu-item-table ingredient">
                            <Table.Body>
                              {values.ingredients.map((ingredient, i) => (
                                <Ingredient
                                  key={i}
                                  index={i}
                                  stockArr={stockArr}
                                  unitArr={unitArr}
                                  fieldArrayHelper={arrayHelpers}
                                  fieldValueSetter={setFieldValue}
                                  buttonRef={buttonRef}
                                  toggleEnterRef={toggleEnterRef}
                                />
                              ))}
                            </Table.Body>
                          </Table>
                        )}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{ paddingTop: '0px' }}>
                      <Grid.Column width={16}>
                        <AddIngredientButton fieldArrayHelper={arrayHelpers} buttonRef={buttonRef} toggleEnterRef={toggleEnterRef} focusedRef={focusedRef} />
                      </Grid.Column>
                    </Grid.Row>
                  </>
                )}
              />
              {props.menuItems && props.menuItems.length > 0 && values.type !== 'side' && (
                <FieldArray
                  name="subrecipes"
                  render={(arrayHelpers) => (
                    <>
                      <Grid.Row style={{ paddingTop: '0px' }}>
                        <Grid.Column width={16}>
                          <p>
                            <strong>{t('sides_extras', { ns: 'menu' })}</strong>
                          </p>
                          {values.subrecipes.length > 0 && (
                            <Table striped compact className="menu-item-table subrecipe">
                              <Table.Body>
                                {values.subrecipes.map((subrecipe, i) => (
                                  <SubMenuItem
                                    key={i}
                                    index={i}
                                    items={props.menuItems}
                                    fieldArrayHelper={arrayHelpers}
                                    fieldValueSetter={setFieldValue}
                                    buttonRef={buttonRef}
                                    toggleEnterRef={toggleEnterRef}
                                  />
                                ))}
                              </Table.Body>
                            </Table>
                          )}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row style={{ paddingTop: '0px' }}>
                        <Grid.Column width={16}>
                          <AddSubMenuItemButton label={t('add_side_extra', { ns: 'menu' })} fieldArrayHelper={arrayHelpers} buttonRef={buttonRef} toggleEnterRef={toggleEnterRef} focusedRef={focusedRef} />
                        </Grid.Column>
                      </Grid.Row>
                    </>
                  )}
                />
              )}
            </Grid>
            <div className="btn-container bottom fitted full-width">
              <Button
                className={buttonStyles.Left}
                floated="left"
                type="button"
                basic
                color="green"
                size="large"
                onClick={() => dispatch(closeModal())}
                content={t('cancel', { ns: 'buttons' })}
              />
              <Button
                loading={isSubmitting}
                type="submit"
                color="green"
                content={t('save', { ns: 'buttons' })}
                floated="right"
                className={btnStyles.Right}
                size="large"
              />
            </div>
          </Form>
        )}
      </Formik>
    </ModalWrapper>
  )
}

export default AddSubMenuItem
