import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { asyncActionError } from '../store/actions'
import { updateUserFlags } from '../shared/utility'

function getUserHasChannels(locations) {
  return locations.filter((loc) => loc.channels?.length > 0).length > 0
}

function getUserHasMenu(menu) {
  return menu.length > 0
}

export const useUserFlags = () => {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.user)
  const { locations } = useSelector((state) => state.location)
  const { menuItems } = useSelector((state) => state.menu)

  useEffect(async () => {
    if (!user) return
    if (!user.hasChannels || !user.hasMenu) {
      const hasChannels = user.hasChannels ? user.hasChannels : getUserHasChannels(locations)
      const hasMenu = user.hasMenu ? user.hasMenu : getUserHasMenu(menuItems)

      const { error } = await updateUserFlags({
        hasChannels,
        hasMenu,
      })
      if (error) dispatch(asyncActionError(error))
    }
  }, [user])

  return {
    useUserFlags,
  }
}
