import * as React from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import useFirestoreDoc from '../../../../hooks/useFirestoreDoc'
import { Button, Grid, Icon, Segment, Modal, Popup } from 'semantic-ui-react'
import { cloudFunctions } from '../../../../config/firebase'
import { httpsCallable } from 'firebase/functions'
import {
  setPageTitle,
  updateMenuItem,
  getUserProfileFromFirestore,
  listenToUserProfile,
  asyncActionStart,
  asyncActionFinish,
  asyncActionError,
  updateMenuItems
} from '../../../../store/actions'
import styles from '../../../../assets/styles/modules/menu/MenuItem.module.scss'
import Spinner from '../../../UI/Spinner/Spinner'
import GreenbytesModal from '../../../UI/GreenbytesModal/GreenbytesModal'
import { getUserId } from '../../../../store/actions/authActions'
import { flaskAPIGet, filterAllergens } from '../../../../shared/utility'
import cx from 'classnames'
import AccordionViewMenuItem from './AccordionViewMenuItem'

const ViewMenuItem = ({ match }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { menuItem, menuItems } = useSelector((state) => state.menu)
  const { user } = useSelector((state) => state.user)
  const { selectedLocation } = useSelector((state) => state.location)
  const { t } = useTranslation(['menu', 'common'])

  const { id, location } = match.params

  const [confirmArchiveOpen, setConfirmArchiveOpen] = React.useState(false)
  const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [openModal, setOpenModal] = React.useState(null)
  const timeoutRef = React.useRef(null)

  useFirestoreDoc({
    query: () => getUserProfileFromFirestore(),
    data: (user) => dispatch(listenToUserProfile(user)),
    deps: [dispatch],
  })

  React.useEffect(() => {
    const loadMenuItem = async () => {
      const userId = await getUserId()
      const recipeData = await flaskAPIGet({ endpoint: `/v2/menu/user/${userId}/location/${location}/recipes/${id}` })
      dispatch(updateMenuItem(recipeData))
      dispatch(setPageTitle(recipeData.name))
      setLoading(false)
    } 
    loadMenuItem()
  }, [])

  if (!id || (!!location !== false && (!selectedLocation || selectedLocation.id !== location))) {
    return <Redirect to="/menu" />
  }

  if (loading || !menuItem) return <Spinner content={t('please_wait', { ns: 'common' })} />

  const subRecipes = !!menuItem.subrecipeIds?.length && (
    <>
      <Grid.Row className={styles.Heading} style={{ padding: '11px 0px 2px' }}>
        <Grid.Column width={16} style={{ padding: '2px 0px 0px' }}>
          {t('sides_extras', { ns: 'menu' })}
        </Grid.Column>
      </Grid.Row>
      {Object.values(menuItem.subrecipes).map(({ id, name, servings }) => (
        <Grid.Row key={id} style={{ padding: '6px 0px 0px' }}>
          <Grid.Column width={10} style={{ padding: '0px' }}>
            {name}
          </Grid.Column>
          <Grid.Column width={6} textAlign="right" style={{ padding: '0px' }}>
            {servings} {t('srv', { ns: 'menu' })}
          </Grid.Column>
        </Grid.Row>
      ))}
    </>
  )

  const batchItem = menuItem.isBatchItem && (
    <p className={styles.BatchItem}>
      <span>{t('this_is_batch_item', { ns: 'menu' })}</span>
      <span>{menuItem.servings === '1' ? `${t('serving', { amount: 1, ns: 'menu' })}` : `${t('servings_amount', { amount: menuItem.servings, ns: 'menu' })}`}</span>
    </p>
  )

  const notes = !!menuItem.notes?.length && (
    <Grid.Row style={{ paddingTop: 20 }}>
      <Grid.Column style={{ padding: '0px' }} width={16}>
        <span className="hint small">{t('notes', { ns: 'menu' })}</span>
        { menuItem.notes.split('\n').map((note) => 
            <p className={styles.NoteItem}>{note}</p>
          )
        }
      </Grid.Column>
    </Grid.Row>
  )

  const handleArchive = async () => {
    setConfirmArchiveOpen(false)
    setLoading(true)
    try {
      dispatch(asyncActionStart)
      const res = await httpsCallable(cloudFunctions, 'archiveMenuItem')({ id, location: [location] })
      let archivedMenuItem = []
      archivedMenuItem.push(res.data)
      const newMenu = menuItems.map(obj => archivedMenuItem.find(o => o.id === obj.id) || obj)
      dispatch(updateMenuItems(newMenu, selectedLocation.id ? selectedLocation.id : selectedLocation))
      dispatch(asyncActionFinish(t('item_was_archived', { ns: 'menu' })))
      setLoading(false)
      setOpenModal({ header: t('was_archived', { ns: 'menu' }) , description: t('successfully_archived', { item: menuItem.name, ns: 'menu' })})
    } catch (error) {
      dispatch(asyncActionError(error, t('item_could_not_be_archived', { ns: 'menu' })))
      setLoading(false)
      setOpenModal({ header: 'Error' , description: `${t('could_not_be_archived', { item: menuItem.name, ns: 'menu' })} ${JSON.stringify(error)}`})
    }
    timeoutRef.current = setTimeout(() => {
      setOpenModal(null)
      history.push('/menu')
    }, 5000)
  }

  const handleDelete = async () => {
    setConfirmDeleteOpen(false)
    setLoading(true)
    try {
      dispatch(asyncActionStart)
      const res = await httpsCallable(cloudFunctions, 'deleteMenuItem')({ id, location: [location] })
      let deletedMenuItem = []
      deletedMenuItem.push(res.data)
      const newMenu = menuItems.map(obj => deletedMenuItem.find(o => o.id === obj.id) || obj)
      dispatch(updateMenuItems(newMenu, selectedLocation.id ? selectedLocation.id : selectedLocation))
      dispatch(asyncActionFinish(t('item_was_deleted', { ns: 'menu' })))
      setLoading(false)
      setOpenModal({ header: t('was_deleted', { ns: 'menu' }), description: t('successfully_deleted', { item: menuItem.name, ns: 'menu' })})
    } catch (error) {
      dispatch(asyncActionError(error, t('item_could_not_be_deleted', { ns: 'menu' })))
      setLoading(false)
      setOpenModal({ header: 'Error' , description: `${t('could_not_be_archived', { item: menuItem.name, ns: 'menu' })} ${JSON.stringify(error)}` })
    }
    timeoutRef.current = setTimeout(() => {
      setOpenModal(null)
      history.push('/menu')
    }, 5000)
  }

  return (
    <>
      <Segment raised className={styles.RecipeDetail}>
        {batchItem}
        <Grid relaxed stackable={false} style={{ maxWidth: '85%' }}>
          <Grid.Row className={styles.Heading} style={{ padding: '11px 0px 2px' }}>
            <Grid.Column width={10} style={{ paddingLeft: '0px' }}>
              {t('ingredient', { ns: 'menu' })}
            </Grid.Column>
            <Grid.Column width={6} textAlign="right" style={{ padding: '0px' }}>
              {t('amount', { ns: 'menu' })}
            </Grid.Column>
          </Grid.Row>
          {Object.values(menuItem.ingredients).map(({ amount, id, name, unit }) => (
            <Grid.Row key={id} style={{ padding: '6px 0px 0px' }}>
              <Grid.Column width={10} style={{ paddingLeft: '0px' }}>
                {name.split(' - Item Number')[0]}
              </Grid.Column>
              <Grid.Column width={6} textAlign="right" style={{ padding: '0px' }}>
                {amount}
                {unit}
              </Grid.Column>
            </Grid.Row>
          ))}
          {subRecipes}
          <Grid.Row style={{ paddingBottom: '0px' }}>
            <Grid.Column style={{ padding: '0px' }} width={16}>
              <hr style={{ margin: '0px -25px 17px -25px' }} />
              { menuItem?.co2Info &&
                <Grid.Row>
                  <Grid.Column width={16} style={{ padding: 0 }}>
                    <AccordionViewMenuItem {...menuItem.co2Info} openable={menuItem?.co2Info?.items.length} />
                  </Grid.Column>
                </Grid.Row>
              }
            </Grid.Column>  
          </Grid.Row>
          { menuItem?.allergens && filterAllergens(menuItem?.allergens).length > 0 &&
            <Grid.Row>
              <Grid.Column width={16} style={{ padding: 0 }}>
                <p className={styles.AllergensTitle}>Allergens</p>
                <div className={styles.PillsContainer}>
                  { filterAllergens(menuItem.allergens).map((allergen) => <span className={styles.Pill}>{allergen}</span> ) }
                  <Popup
                    className={styles.AllergensPopup}
                    position="bottom right"
                    trigger={ <Icon className={styles.AllergensTrigger} color="grey" name="info circle" /> }
                  >
                    <p style={{ marginBottom: '15px' }}>The ingredients listed in this dish may contain common allergens. Please familiarize yourself with potential allergens and consider cross-contamination with other allergens.</p>
                    <p>This allergen information was generated using AI technology and is intended to provide a general guideline. However, the results may not be 100% accurate.</p>
                  </Popup>
                </div>
              </Grid.Column>
            </Grid.Row>
          } 
          {notes}
          { menuItem?.nutritionalInfo &&
            <Grid.Row>
              <Grid.Column width={16} style={{ padding: 0 }}>
                <AccordionViewMenuItem {...menuItem.nutritionalInfo} openable={menuItem?.nutritionalInfo?.items.length} showHint={true} rearrange={true} />
              </Grid.Column>
            </Grid.Row>
          }
          { menuItem?.costing &&
            <Grid.Row>
              <Grid.Column width={16} style={{ padding: 0 }}>
                <AccordionViewMenuItem {...menuItem.costing} openable={menuItem?.costing?.items.length} currencySign={user?.preferredCurrency} />
              </Grid.Column>
            </Grid.Row>
          }
        </Grid>
        <div className={location ? styles.ButtonsContainer : styles.ButtonsContainerCentered}>
          {location && !menuItem.isArchived && (
            <Button
              className={cx(styles.Button, styles.Green)}
              content={t('edit', { ns: 'buttons' })}
              color="green"
              size="large"
              basic
              onClick={() => {
                if (!location) {
                  history.push(`/menu/edit/${id}/third-step`, { menuItem })
                } else {
                  history.push(`/menu/edit/${id}/location/${location}/third-step`, { menuItem })
                }
              }}
            />
          )}
          {location && menuItem.isArchived && (
            <Button
              className={cx(styles.Button, styles.Green)}
              type="button"
              floated="left"
              loading={loading}
              color="green"
              basic
              content={t('delete', { ns: 'buttons' })}
              size="big"
              onClick={() => {
                setConfirmDeleteOpen(true)
              }}
            />
          )}
          <Button
            className={cx(styles.Button, styles.Green, styles.CostingButton)}
            content="Costing Calculator"
            color="green"
            size="large"
            basic
            onClick={() => {
              history.push(`/costing-calculator/${id}/location/${location}`)
            }}
          />
        </div>
      </Segment>
      <GreenbytesModal
        confirmDeleteOpen={confirmArchiveOpen}
        setConfirmDeleteOpen={setConfirmArchiveOpen}
        text={t('archive_menu_item', { ns: 'menu' })}
        confirmButtonText={'archive'}
        cancelButtonText={'cancel'}
        handleConfirmClick={handleArchive}
      />
      <GreenbytesModal
        confirmDeleteOpen={confirmDeleteOpen}
        setConfirmDeleteOpen={setConfirmDeleteOpen}
        text={t('delete_menu_item', { ns: 'menu' })}
        confirmButtonText={'delete'}
        cancelButtonText={'cancel'}
        handleConfirmClick={handleDelete}
      />
      <Modal
        open={openModal !== null}
        closeOnDimmerClick={openModal && !openModal.header.includes('deleted')}
        closeIcon={openModal && !openModal.header.includes('deleted')}
        onClose={() => {
          clearTimeout(timeoutRef.current)
          setOpenModal(null)
        }}
      >
        <Modal.Header className={styles['Modal-Header']}>
          {openModal && openModal.header}
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p className={styles['Modal-Description']}>
              {openModal && openModal.description}
            </p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button 
            onClick={() => {
              setOpenModal(false)
              history.push('/menu')
            }}
            className={styles['Confirm-Button']}
            positive
          >
            {t('go_to_menu', { ns: 'menu' })}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default ViewMenuItem