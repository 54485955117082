import * as React from 'react'
import { Grid, Label, Popup, Icon } from 'semantic-ui-react'
import styles from '../../assets/styles/modules/costing-calculator/CostingCalculator.module.scss'
import cx from 'classnames'
import TextInput from '../UI/Form/TextInput'
import SelectInput from '../UI/Form/SelectInput'
const { currencySignsBeforeAmount } = require('../../shared/utility')

const IngredientCostingCalculator = ({
  fieldValueSetter,
  ingredient,
  index,
  purchasePriceUnitsArr,
  purchaseUnitsArr,
  totalCosts,
  preferredCurrency = '',
}) => {

  const checkDecimals = (num) => (num % 1 !== 0) ? 2 : 0
  const currencyField = ingredient.currency_field ? ingredient.currency_field.split('/')[0] : ''
  
  return (
    <>
      <Grid.Row className={cx('costing-calculator', styles.CostingCalculatorIngredient)}>
        <Grid.Column width={2} textAlign="left">{ingredient.name}</Grid.Column>
        <Grid.Column width={4} textAlign="center">{ingredient.amount} {ingredient.unit_field}</Grid.Column>
        { ingredient.isSubrecipe ?
          <Grid.Column width={6} className={styles.FixedPadding}>
            { ((totalCosts.recipeCost === 0) || (!totalCosts.recipeCost)) ?
              <Label className={cx(styles.Label, styles.UI, styles.Red)} color="red">
                {`${ingredient.name} is not included in this calculation`}&nbsp;&nbsp;
                <Popup className={styles.Popup} position="bottom right" offset={[14, 4]} content={'This menu item will not include the cost of this recipe until it has been calculated'} trigger={<Icon style={{ fontSize: '14px' }} color="grey" name="question circle" />} />
              </Label>
              :
              <>&nbsp;</>
            }
          </Grid.Column>
        :
          <>
            <Grid.Column width={3} className={cx(styles.FixedPadding, styles.PurchasePrice)}>
              <TextInput name={`ingredients.${index}.purchase_price`} type="number" inputmode="decimal" />
              <span>{preferredCurrency}</span>
            </Grid.Column>
            <Grid.Column width={3} className={styles.FixedPadding}>
              <TextInput name={`ingredients.${index}.purchase_unit_amount`} type="number" inputmode="decimal" />
              <SelectInput
                className={styles.PurchaseUnitField}
                name={`ingredients.${index}.purchase_unit_field`}
                placeholder={ingredient?.purchase_unit}
                options={purchaseUnitsArr}
                fieldValueSetter={fieldValueSetter}
                fieldValueToSet={`ingredients.${index}.purchase_unit`}
              />
            </Grid.Column>
          </>
        }
        <Grid.Column width={2} textAlign="center">
          { totalCosts && totalCosts.unitCost ?
            totalCosts.unitCost.toFixed(checkDecimals(totalCosts.unitCost || 0))
          :
            <>&nbsp;</>
          }
        </Grid.Column>
        <Grid.Column width={2} textAlign="center">
          { ((totalCosts && totalCosts.recipeCost) || (ingredient.isSubrecipe)) ?
            <span className={currencySignsBeforeAmount.includes(preferredCurrency) ? styles.ChangeSignOrder : ''}>
              <span>{`${totalCosts.recipeCost?.toFixed(checkDecimals(totalCosts.recipeCost)) || 0}`}</span>&nbsp;
              <span>{`${currencyField}`}</span>
            </span>
          :
            <>&nbsp;</>
          }
        </Grid.Column>
      </Grid.Row>
    </>
  )
}

export default IngredientCostingCalculator
