import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, useHistory, Redirect } from 'react-router-dom'
import { httpsCallable } from 'firebase/functions'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Button } from 'semantic-ui-react'
import moment from 'moment'
import { getUserId, getUserIdToken } from '../../store/actions/authActions'
import { cloudFunctions } from '../../config/firebase'
import Spinner from '../UI/Spinner/Spinner'
import styles from '../../assets/styles/modules/shoppingList/shoppingListView.module.scss'
import ShoppingListDetails from './ShoppingListDetails'
import { getStatusIcon } from '../../shared/utility'
import { setPageTitle, asyncActionError } from '../../store/actions'
import cx from 'classnames'

const ShoppingListView = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  let { id } = useParams()
  const { selectedLocation } = useSelector((state) => state.location)
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation(['orders', 'buttons', 'common'])

  useEffect(() => {
    dispatch(setPageTitle(t('shopping_list_details', { ns: 'orders' })))
    const loadData = async () => {
      const user_token = await getUserIdToken()
      const userId = await getUserId()
      try {
        const res = await httpsCallable(
          cloudFunctions,
          'flaskAPIGetRequest'
        )({ user_token, endpoint: `/v2/shopping-lists/user/${userId}/location/${selectedLocation.id}/details/${id}` })
        setData(res.data)
        setLoading(false)
      } catch (error) {
        dispatch(asyncActionError(error, ''))
      }
    }
    
    loadData()
  }, [])

  if (!selectedLocation) {
    return <Redirect to="/locations" />
  }

  if (loading || !data) return <Spinner content={t('please_wait', { ns: 'common' })} />

  return (
    <div className={styles.ShoppingListViewDetails}>
      <div className={styles.ShoppingListViewHeading}>
        <h2>{data.assigned}</h2>
        <p>{data.assignedEmail}</p>
        <p>{moment(data.createdAt).format('dddd DD MMMM YYYY')}</p>
      </div>
      { data.details && data.details.map(shoppingList => <ShoppingListDetails shoppingList={shoppingList} />)}
      <span className={styles.StatusIcon}>{getStatusIcon(data.status, 'big')}</span>
      { data.signer && 
        <div className={styles.ShoppingListViewSignature}>
          <strong>{t('order_signed_by', { ns: 'orders' })}</strong>
          <p>{data.signer}</p>
        </div>
      }
      <div className={cx(styles.ButtonsContainer, 'btn-container bottom fixed floating full-width')}>
          <span className={styles.ButtonWrapperCentered}>
            <Button
              className={cx(styles.Button, styles.Orange)}
              color="orange"
              onClick={() => history.push('/orders')}
            >
              {t('back_to_all_orders', { ns: 'orders' })}
            </Button>
          </span>
        </div>
    </div>
  )
}

export default ShoppingListView