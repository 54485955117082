import React, { useEffect, useState } from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { Button } from 'semantic-ui-react'
import ShoppingListDetails from './ShoppingListDetails'
import { getStatusIcon } from '../../shared/utility'
import { setPageTitle } from '../../store/actions/navigationActions'
import Spinner from '../UI/Spinner/Spinner'
import styles from '../../assets/styles/modules/shoppingList/shoppingListSuccess.module.scss'
import cx from 'classnames'

const ShoppingListSuccess = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { selectedLocation } = useSelector((state) => state.location)
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)
  const { t } = useTranslation(['orders', 'buttons', 'common'])
  
  useEffect(() => {
    dispatch(setPageTitle(t('shopping_list_sent', { ns: 'orders' })))
    setData(history.location?.state?.data || {})
    setLoading(false)
    const element = document.getElementById('root')
    if (!element) { return }
    element.scrollIntoView({ block: 'start', behavior: 'smooth' })
  }, [history, dispatch])

  if (!selectedLocation) {
    return <Redirect to="/locations" />
  }

  if (loading || !data) return <Spinner content={t('please_wait', { ns: 'common' })} />

  return (
    <div className={styles.ShoppingListViewDetails}>
      <div className={styles.ShoppingListViewHeading}>
        <h2>{data.assigned}</h2>
        <p>{data.assignedEmail}</p>
        <p>{moment(data.createdAt).format('dddd DD MMMM YYYY')}</p>
      </div>
      { data.details && data.details.map(shoppingList => <ShoppingListDetails shoppingList={shoppingList} />)}
      <span className={styles.StatusIcon}>{getStatusIcon(data.status, 'big')}</span>
      { data.signer && 
        <div className={styles.ShoppingListViewSignature}>
          <strong>{t('order_signed_by', { ns: 'orders' })}</strong>
          <p>{data.signer}</p>
        </div>
      }
      <div className={cx(styles.ButtonsContainer, 'btn-container bottom fixed floating full-width')}>
          <span className={styles.ButtonWrapperCentered}>
            <Button
              className={cx(styles.Button, styles.Orange)}
              color="orange"
              onClick={() => history.push('/ordersList')}
            >
              {t('back_to_all_orders', { ns: 'orders' })}
            </Button>
          </span>
        </div>
    </div>
  )
}

export default ShoppingListSuccess