import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Button, Message } from 'semantic-ui-react'
import Spinner from '../UI/Spinner/Spinner'
import Location from '../../components/Locations/Location/Location'
import { setPageTitle } from '../../store/actions'
import { useMultipleLocations } from '../../hooks/useMultipleLocations'
import useFetchLocation from '../../hooks/useFetchLocation'
import { useUserFlags } from '../../hooks/useUserFlags'
import cx from 'classnames'

import styles from '../../assets/styles/modules/locations/Location.module.scss'

const Locations = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { currentLocation } = useSelector((state) => state.auth)
  const { locations } = useSelector((state) => state.location)
  const { user } = useSelector((state) => state.user)
  const { error } = useSelector((state) => state.async)
  const [noContentMessage, setNoContentMessage] = React.useState(false)
  const { fetchLocations, loading } = useFetchLocation()
  const target = history.location?.state?.target || localStorage.getItem('target')
  const { t } = useTranslation(['location', 'common'])
  const curPath = currentLocation.pathname.slice(1)
  const [showSurvey, setShowSurvey] = React.useState(false)

  React.useEffect(() => {
    if (!locations.length && !loading) setNoContentMessage(true)
    else setNoContentMessage(false)
  }, [loading, locations])

  React.useEffect(() => {
    const timer = setTimeout(() => {
      const element = document.getElementById(target)
      if (!element) { return }
      element.scrollIntoView({ block: 'center', behavior: 'smooth' })
      history.replace({state: {}})
      localStorage.removeItem('target')
    }, 1000);

    return () => clearTimeout(timer)
  }, [history, target])

  React.useEffect(() => {
    if (user) {
      const timestamp = user?.createdAt.getTime()
      const timestampWeekAgo = new Date().getTime() - (7 * 24 * 60 * 60 * 1000)
      setShowSurvey(timestampWeekAgo > timestamp)
    }
  }, [user])

  React.useEffect(() => {
    dispatch(setPageTitle(t('locations', { ns: 'location' })))
    fetchLocations(dispatch)
  }, [dispatch])

  // Update user flags is needed
  useUserFlags()

  // Update user.hasLocations if needed
  useMultipleLocations()

  if (loading) return <Spinner content={t('please_wait', { ns: 'common' })} />

  if (showSurvey && (curPath !== 'locationsList')) {
    return <Redirect to={{ pathname: "/locationsList", state: { previousPage: history?.location?.state?.previousPage }}} />
  } 

  return (
    <>
      <div className={styles.Locations}>
        {error && <Message>{t('something_wrong', { ns: 'common' , error })}</Message>}
        {noContentMessage ? 
          <p className="align-center">
            {t('nothing_here', { ns: 'common' })}
            <br />
            {t('add_location_now', { ns: 'location' })}
          </p>
          :
          <>
            <h2 className={styles.SelectLocationTitle}>{t('select_a_location', { ns: 'location' })}</h2>
            {locations.map((location) => (
              <Location key={location.id} location={location} />
            ))}
          </>
        }
      </div>
      <div className={cx(styles.ButtonsContainer, 'btn-container bottom fixed floating full-width')}>
        <span className={styles.ButtonWrapperCentered}>
          <Button
            className={cx(styles.Button, styles.Green)}
            color="green"
            onClick={() => history.push(`locations/add?firstTime=${locations.length === 1}`)}
          >
            {t('add_location', { ns: 'location' })}
          </Button>
        </span>
      </div>
    </>
  )
}

export default Locations
