import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Table, Button, Popup, Icon } from 'semantic-ui-react'
import styles from '../../../assets/styles/modules/stock/StockItem.module.scss'
import GreenbytesModal from '../../UI/GreenbytesModal/GreenbytesModal'
import { formatNumber, getLocationsFromItem } from '../../../shared/utility'
import useBreakpoint from '../../../hooks/useBreakpoint'
import cx from 'classnames'

const StockItem = ({
  hasLocations,
  item,
  selectedLocation,
}) => {
  const history = useHistory()
  const { t } = useTranslation(['stock', 'buttons'])

  return (
    <>
      <tr id={item.id} key={item.id}>
        <td>
          <span>{item.name}</span>
        </td>
        {(!hasLocations || selectedLocation) && (
          <td className={styles.Center}>
            <span>
              {formatNumber(item.amount, item.unit)} {item.unit}
            </span>
          </td>
        )}
        <td className={cx(styles.Right, styles.Link)}>
          <div onClick={() => { history.push('/stock/detail/item', { item, selectedLocation, target: item.id })}}>
            <Icon name="caret right" />
          </div>
        </td>
      </tr>
    </>
  )
}

export default StockItem
