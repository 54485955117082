import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Button, Icon } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import styles from '../../../../assets/styles/modules/UI/AddIngredientButton.module.scss'

const AddIngredientButton = ({ fieldArrayHelper, clickable, buttonRef, toggleEnterRef, focusedRef, ingredientAddedRef = null }) => {
  const { t } = useTranslation(['menu'])
  const { id, location } = useParams()
  const history = useHistory()

  useEffect(() => {
    const keyDownHandler = event => {
      if ((event.key === 'Enter') && (buttonRef.current === 'add_ingredient') && (focusedRef.current)) {
        event.preventDefault();
        if (toggleEnterRef.current) {
          toggleEnterRef.current = false
          fieldArrayHelper.push({ id: '', name: '', amount: '', unit: '' })
        } else {
          toggleEnterRef.current = true
        }
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  return (
    <>
      <Button
        className={styles.MobileButton}
        type="button"
        basic
        color="green"
        size="big"
        onClick={() => { 
          if (clickable) {
            if (ingredientAddedRef) { ingredientAddedRef.current = true }
            history.push(`/menu/edit/${id}/location/${location}/first-step`, { menuItem: history?.location?.state?.menuItem })
          } else {
            if (ingredientAddedRef) { ingredientAddedRef.current = true }
            fieldArrayHelper.push({ id: '', name: '', amount: '', unit: '' })
          }
        }}
        content={
          <>
            <Icon color="green" name="plus circle" /> {t('add_ingredient', { ns: 'menu' })}
          </>
        }
      />
      <p className={styles.DesktopButton}>
        <span
          className="pointer"
          onClick={() => {
            if (clickable) {
              if (ingredientAddedRef) { ingredientAddedRef.current = true }
              history.push(`/menu/edit/${id}/location/${location}/first-step`, { menuItem: history?.location?.state?.menuItem })
            } else {
              if (ingredientAddedRef) { ingredientAddedRef.current = true }
              buttonRef.current = 'add_ingredient'
              fieldArrayHelper.push({ id: '', name: '', amount: '', unit: '' })
            }
          }}
        >
          <Icon color="green" name="plus circle" /> {t('add_ingredient', { ns: 'menu' })}
        </span>
      </p>
    </>
  )
}

export default AddIngredientButton
