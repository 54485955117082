import React from 'react'
import { Icon } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import cx from 'classnames'
import styles from '../../../assets/styles/modules/UI/Link.module.scss'

const Link = ({ text, url = '/#', disabled = false }) => {
  const history = useHistory()

  return (
    <div className={cx(styles.LinkContainer, disabled ? styles.Disabled : '')} onClick={() => history.push(`${url}`)}>
      {text}
      <Icon name="chevron right" className={styles.ChevronRight} />
    </div>
  )
}

export default Link