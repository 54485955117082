import * as React from 'react'
import { asyncActionStart, asyncActionFinish, updateLocations } from '../store/actions'
import { getUserId } from '../store/actions/authActions'
import { flaskAPIGet } from '../shared/utility'

export default function useFetchLocation() {

  const [loading, setLoading] = React.useState(true)

  const fetchLocations = async (dispatch, onlyActive = false) => {
    dispatch(asyncActionStart())
    const userId = await getUserId()
    const locations = await flaskAPIGet({ endpoint: `/v2/locations/${userId}?onlyActive=${onlyActive}` })
    
    if (locations?.error) {
      dispatch(updateLocations([]))
    } else {
      dispatch(updateLocations(locations))
    }
    dispatch(asyncActionFinish())
    setLoading(false)
  }

  return {
    fetchLocations,
    loading
  }
}
