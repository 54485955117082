import React, { useState, useEffect } from 'react'
import { Grid, Label, Icon } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'
import SelectInput from '../../UI/Form/SelectInput'
import CustomDropdown from '../../UI/Form/CustomDropdown'
import styles from '../../../assets/styles/modules/UI/IngredientWithDropdown.module.scss'

const IngredientWithDropdown = ({
  distributors,
  fieldValueSetter,
  isLoadingDistributors,
  index,
  updateProducts,
  values,
  currentStep = '',
}) => {
  const [distributor, setDistributor] = useState(null)
  const [isDistributorSelected, setIsDistributorSelected] = useState(false)
  const [productOptions, setProductOptions] = useState([])
  const [distributorChanged, setDistributorChanged] = useState(false)
  const { t } = useTranslation(['menu'])
  const [distributorName, setDistributorName] = useState(null)

  useEffect(() => {
    setProductOptions(updateProducts(distributor))
    if (distributorChanged) {
      values[index].id_product_onboarding = ''
      setDistributorChanged(false)
    }
  }, [distributor, updateProducts, distributorChanged])

  useEffect(() => {
    setIsDistributorSelected(!!values[`${index}`].distributor || !!values[`${index}`].id_distributor_onboarding)
    setDistributorName(values[index].generic_name.distributor_name?.replace(new RegExp('##_##', 'g'),'/'))
  }, [index, values])

  const isLoadingProducts = isDistributorSelected && !productOptions.length

  return (
    <>
      <Grid.Row verticalAlign="bottom" className={styles.CustomRow}>
        <Grid.Column></Grid.Column>
        <Grid.Column>
          {values[index].fromStock ? (
            <>
              <h2>
                { values[index].name ? values[index].name : values[index].generic_name.text}
                <span style={{ fontStyle: 'italic', opacity: '40%', fontSize: '1rem', paddingLeft: '5px' }}>
                  <Icon name="check" />
                  {t('chosen_from_stock', { ns: 'menu' })}
                </span>
              </h2>
              {values[index].generic_name.has_multiple_distributors ? (
                <Grid.Row>
                  <Grid.Column></Grid.Column>
                  <Grid.Column>
                    <Label style={{ width: '100%', fontSize: '18px' }}>
                      <Icon name="truck" />
                      <span>{t('multiple_distributors', { ns: 'menu' })}</span>
                    </Label>
                    <div style={{ padding: '5px' }}>
                      <p style={{ marginBottom: '0', fontStyle: 'italic', opacity: '40%', fontSize: '1rem' }}>
                        {t('multiple_distributors_description', { distributor_name: values[index].generic_name.id_distributor_onboarding, product_name: values[index].generic_name.id_product_onboarding.replace(new RegExp('##_##', 'g'),'/'), ns: 'menu' })}
                      </p>
                    </div>
                  </Grid.Column>
                  <Grid.Column></Grid.Column>
                </Grid.Row>
              ) : (
                <>
                  <Grid.Row style={{ marginBottom: '10px' }}>
                    <Grid.Column width={3} mobile={0}></Grid.Column>
                    <Grid.Column width={10} mobile={16}>
                      <Label style={{ width: '100%', fontSize: '18px', borderRadius: '80px' }}>
                        <Icon name="truck" />
                        <span>{ distributorName || values[index].generic_name.id_distributor_onboarding.replace(new RegExp('##_##', 'g'),'/')}</span>
                      </Label>
                    </Grid.Column>
                    <Grid.Column width={3} mobile={0}></Grid.Column>
                  </Grid.Row>
                  { distributorName !== 'No distributor selected' &&
                    <Grid.Row>
                      <Grid.Column></Grid.Column>
                      <Grid.Column>
                        <Label style={{ width: '100%', fontSize: '18px', borderRadius: '80px' }}>
                          <Icon name="box" />
                          { values[index].generic_name.id_product_onboarding ?
                            values[index].generic_name.id_product_onboarding.replace(new RegExp('##_##', 'g'),'/')
                          :
                            values[index]?.generic_name?.typed_product?.split(' - Item Number')[0] || values[index].name || values[index].generic_name?.text
                          }
                        </Label>
                      </Grid.Column>
                      <Grid.Column></Grid.Column>
                    </Grid.Row>
                  }
                  { !values[index].generic_name.id_product_onboarding && distributorName !== 'No distributor selected' &&
                    <Grid.Row>
                      <Grid.Column></Grid.Column>
                      <Grid.Column style={{ textAlign: 'right' }}>
                          <span style={{ fontStyle: 'italic', opacity: '40%', fontSize: '1rem', display: 'inline-block', fontWeight: 'bold', padding: '5px' }}>
                            <Icon name="info circle" />
                            {t('item_verified_with', { distributor: values[index].generic_name.distributor_name || values[index].generic_name.id_distributor_onboarding, ns: 'menu' })}
                          </span>
                      </Grid.Column>
                      <Grid.Column></Grid.Column>
                    </Grid.Row>
                  }
                </>
              )}
            </>
          ) : (
            <h2>{values[index].generic_name}</h2>
          )}
        </Grid.Column>
        <Grid.Column></Grid.Column>
      </Grid.Row>
      {!values[index].fromStock && (
        <>
          <Grid.Row verticalAlign="bottom" className={styles.CustomRow}>
            <Grid.Column></Grid.Column>
            <Grid.Column>
              <CustomDropdown
                name={`ingredients.${index}.distributor`}
                placeholder={t('distributor', { ns: 'menu' })}
                options={distributors}
                fieldValueSetter={fieldValueSetter}
                fieldValueToSet={`ingredients.${index}.id_distributor_onboarding`}
                setDistributor={setDistributor}
                compact
                loading={isLoadingDistributors}
                disabled={isLoadingDistributors}
                customDistributor={`ingredients.${index}.customDistributor`}
                alterText={true}
                setDistributorChanged={setDistributorChanged}
              />
            </Grid.Column>
            <Grid.Column></Grid.Column>
          </Grid.Row>
          <Grid.Row verticalAlign="bottom" className={styles.CustomRow}>
            <Grid.Column></Grid.Column>
            <Grid.Column>
              <CustomDropdown
                name={`ingredients.${index}.product`}
                placeholder={t('product', { ns: 'menu' })}
                disabled={!isDistributorSelected || isLoadingProducts}
                options={productOptions}
                fieldValueSetter={fieldValueSetter}
                fieldValueToSet={`ingredients.${index}.id_product_onboarding`}
                shouldBeChecked={`ingredients.${index}.shouldBeChecked`}
                compact
                loading={isLoadingProducts}
                alterText={true}
                currentStep={currentStep}
                showItemNumber={true}
                fieldItemNumberToSet={`ingredients.${index}.itemNumber`}
                distributorSelected={!!values[`${index}`].id_distributor_onboarding}
              />
            </Grid.Column>
            <Grid.Column width={3}></Grid.Column>
          </Grid.Row>
        </>
      )}
    </>
  )
}

export default IngredientWithDropdown
