import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styles from '../../../assets/styles/modules/Home.module.scss'
import orderIconGreySrc from '../../../assets/images/navigation/orders.svg'
import orderIconGreenSrc from '../../../assets/images/home/orders-green.svg'
import orderIconDarkGreenSrc from '../../../assets/images/home/orders-dark-green.svg'
import { Link } from 'react-router-dom'
import { flaskAPIGet } from '../../../shared/utility'
import { getUserId, getUserIdToken } from '../../../store/actions/authActions'
import { updateShoppingLists } from '../../../store/actions/orderActions'
import { asyncActionStart, asyncActionError } from '../../../store/actions/asyncActions'


const Orders = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['home', 'orders'])
  const { selectedLocation } = useSelector((state) => state.location)
  const { shoppingLists, lastLocationId } = useSelector((state) => state.order)
  const [firstLoad, setFirstLoad] = React.useState(true)

  React.useEffect(() => {
    const loadData = async () => {
      const user_token = await getUserIdToken()
      const userId = await getUserId()
      try {
        dispatch(asyncActionStart())
        const { items, pagination } = await flaskAPIGet({ endpoint: `/v2/frontend/user/${userId}/location/${selectedLocation.id}/orders-shoppinglists` })
        if (items || pagination) {
          dispatch(updateShoppingLists(items, selectedLocation.id))
        }
      } catch (error) {
        dispatch(asyncActionError(error, ''))
      }
      setFirstLoad(false)
    }
    
    if (selectedLocation && firstLoad && (shoppingLists.length === 0 || lastLocationId !== selectedLocation.id)) {
      loadData()
    }
  }, [selectedLocation])

  const classes = [styles.Task]
  if (shoppingLists?.length) classes.push(styles.Done)
  return (
    <div className={classes.join(' ')}>
      <Link className={styles.TaskLink} to="/orders">
        <div className={styles.TaskIcon}>
          {!shoppingLists?.length ? (
            <img src={orderIconGreySrc} alt="" border="0" />
          ) : (
            <>
              <img className={styles.TaskIconImg} src={orderIconGreenSrc} alt="" border="0" />
              <img className={styles.TaskIconImgHover} src={orderIconDarkGreenSrc} alt="" border="0" />
            </>
          )}
        </div>
        {!shoppingLists?.length ? (
          <>
            <strong className="xxx-large">4.</strong> <span>{t('create_an_order', { ns: 'orders' })}</span>
          </>
        ) : (
          t('manage_orders', { ns: 'home' })
        )}
      </Link>
    </div>
  )
}

export default Orders
