import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useHistory, useParams, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ShowForm } from './ShowForm'
import { httpsCallable } from 'firebase/functions'
import { useTranslation } from 'react-i18next'
import { getUserProfileFromFirestore, listenToUserProfile } from '../../../../store/actions/userActions'
import { updateStock } from '../../../../store/actions/stockActions'
import useFirestoreDoc from '../../../../hooks/useFirestoreDoc'
import useFetchLocation from '../../../../hooks/useFetchLocation'
import * as Yup from 'yup'
import { Formik } from 'formik'
import { setPageTitle, updateMenuItems, updateDistributors, asyncActionError } from '../../../../store/actions'
import { getUserId } from '../../../../store/actions/authActions'
import { flaskAPIGet } from '../../../../shared/utility'
import { cloudFunctions } from '../../../../config/firebase'
import Spinner from '../../../UI/Spinner/Spinner'
import { getPayload, INITIAL_VALUES } from './utils'
import { getUserIdToken } from '../../../../store/actions/authActions'


const AddMenuItem = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  let { current_step } = useParams()
  const { t } = useTranslation(['menu', 'validationMessages', 'common'])
  const baseUrl = "/menu/add"

  const { fetchLocations } = useFetchLocation()

  const { user } = useSelector((state) => state.user)
  const { selectedLocation } = useSelector((state) => state.location)
  const { menuItems } = useSelector((state) => state.menu)
  const { loading } = useSelector((state) => state.async)

  const isBatchItem = useRef(null)
  const menuItemName = useRef('')
  const itemAlreadyExists = useRef(false)
  const [isCombo, setIsCombo] = useState(false)
  const [kind, setKind] = useState('single')
  const [currentValues, setCurrentValues] = useState(null)
  const [currentErrors, setCurrentErrors] = useState({})

  useEffect(() => {
    dispatch(setPageTitle(t('add_menu', { ns: 'menu' })))
    fetchLocations(dispatch)
  }, [dispatch])

  useFirestoreDoc({
    query: () => getUserProfileFromFirestore(),
    data: (user) => dispatch(listenToUserProfile(user)),
    deps: [dispatch],
  })

  if (!selectedLocation) {
    return <Redirect to="/menu" />
  }

  if (loading || !user) return <Spinner loading content={t('please_wait', { ns: 'common' })} />

  const initialValues = { ...INITIAL_VALUES(kind, currentValues) }

  const menuItemSchema = (isBatchItem, isCombo) => {
    let schemaAttributes = {
      name: Yup.string().required(t('menu_item', { ns: 'validationMessages' })),
      subrecipes: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().required(t('item', { ns: 'validationMessages' })),
          servings: Yup.number().typeError(t('number', { ns: 'validationMessages' })).min(1, t('too_low', { ns: 'validationMessages' })).required(t('servings', { ns: 'validationMessages' })),
        })
      ),
    }
    if (isBatchItem && !isCombo) {
      schemaAttributes.servings = Yup.number()
          .typeError(t('number', { ns: 'validationMessages' }))
          .min(1, t('too_low', { ns: 'validationMessages' }))
          .required(t('number_of_servings', { ns: 'validationMessages' }))
    }
    if (!isCombo) {
      schemaAttributes.ingredients = Yup.array()
        .of(
          Yup.object().shape({
            amount: Yup.number().typeError(t('number', { ns: 'validationMessages' })).min(0, t('too_low', { ns: 'validationMessages' })).required(t('amount', { ns: 'validationMessages' })),
            unit_field: Yup.string().oneOf(['kg', 'g', 'L', 'mL', 'pcs', 'pc']).required(t('unit', { ns: 'validationMessages' })),
          })
        )
        .required(t('add_ingredient', { ns: 'validationMessages' }))
    }
    // create and return the schema
    return Yup.object().shape(schemaAttributes)
  }

  if (user.hasLocations) {
    initialValues.locationIds = []
    initialValues.locations = {}
  }

  const cleanIdProductOnboarding = (arr, value) =>
    arr.map((el) => (el.id_product_onboarding === value ? { ...el, id_product_onboarding: null } : el))

  const filterFromOnBoarding = (ingredients) => {
    const ingredientsFromStock = Object.values(ingredients).filter((ingredient) => ingredient.fromStock)
    const newIngredientsForStock = Object.values(ingredients).filter((ingredient) => !ingredient.fromStock)

    let result = Object.assign(
      {},
      ...ingredientsFromStock.map((ingredient) => {
        let { generic_name, name, fromStock, unit_field, ...rest } = ingredient
        const newIngredient = {
          name: generic_name,
          ...rest,
        }
        return { [ingredient.id]: newIngredient }
      })
    )

    result.onboarding = newIngredientsForStock.map((ingredient) => {
      let { name, id, fromStock, distributor, product, unit_field, customDistributor, id_distributor_onboarding, id_product_onboarding, shouldBeChecked, ...rest } = ingredient
      return {
        customDistributor: !customDistributor ? false : customDistributor,
        id_distributor_onboarding: !id_distributor_onboarding ? 'none' : id_distributor_onboarding,
        id_product_onboarding: !id_product_onboarding ? null : id_product_onboarding,
        shouldBeChecked: !shouldBeChecked ? false : shouldBeChecked, 
        ...rest
      }
    })

    return result
  }

  const filterSubrecipes = (subrecipes) => {
    return Object.assign(
      {},
      ...subrecipes.map((subrecipe) => {
        let { name, ...rest } = subrecipe
        const newSubrecipe = {
          name: name.text ? name.text : name,
          ...rest,
        }
        return { [subrecipe.id]: newSubrecipe }
      })
    )
  }

  const batchItemValidation = (value) => {
    isBatchItem.current = value
  }

  const menuItemNameExists = (name) => {
    if (!menuItems) return false
    const filteredMenus = menuItems.map(e => {
      if (!e.deleted) return e.name?.toUpperCase()
    }).filter(item => item !== undefined)
    return filteredMenus.includes(name.toUpperCase())
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={current_step === 'third-step' || isCombo ? menuItemSchema(isBatchItem?.current, isCombo) : Yup.object({})}
      onSubmit={async (values, { setSubmitting }) => {
        let ingredientsArrayFormat = cleanIdProductOnboarding(values.ingredients, t('type_your_own_ingredient', { ns: 'menu' }))
        let subrecipesArrayFormat = values.subrecipes

        if (values.ingredients.length > 0 || values.subrecipes.length > 0) {
          if (menuItemNameExists(values.name)) {
            setCurrentErrors({ recipes: t('name_already_exists', { name: values.name, ns: 'menu' }) })
            setSubmitting(false)
          } else {
            let ingredientsDictionary = filterFromOnBoarding(ingredientsArrayFormat)
            values.ingredients = { ...ingredientsDictionary }

            let subrecipesDictionary = filterSubrecipes(subrecipesArrayFormat)
            values.subrecipes = { ...subrecipesDictionary }
            const payload = getPayload(values, selectedLocation)
            try {
              const res = await httpsCallable(cloudFunctions, 'addMenuItem')({ values: payload, userToken: await getUserIdToken()})
              let newRecipe = []
              newRecipe.push(res.data)
              dispatch(updateMenuItems(newRecipe.concat(menuItems)))
              const userId = await getUserId()
              const stock = await flaskAPIGet({ endpoint: `/v2/stock/user/${userId}/location/${selectedLocation.id}/stock` })
              dispatch(updateStock(stock, selectedLocation.id))
              const distributorsData = await flaskAPIGet({ endpoint: `/v2/distributors/user/${userId}?mode=user` })
              dispatch(updateDistributors(distributorsData))
              setSubmitting(false)
              values.ingredients = ingredientsArrayFormat
              values.subrecipes = subrecipesArrayFormat
              setTimeout(async () => {
                try {
                  const location = selectedLocation.id ? selectedLocation.id : selectedLocation
                  const menuItems = await flaskAPIGet({ endpoint: `/v2/menu/user/${userId}/location/${location}/recipes` })
                  dispatch(updateMenuItems(menuItems, location))
                } catch (error) {
                  dispatch(asyncActionError(error, t('could_not_be_updated', { ns: 'menu' })))
                }
              }, 30000)
              localStorage.removeItem('/menu/add')
              history.push(`/menu?showWidget=${menuItems.length === 1}`)
              console.log("No errors while calling addMenuItem")
            } catch (error) {
              console.log("Exception raised while calling addMenuItem")
              setCurrentErrors({ recipes: error.message })
              setSubmitting(false)
              values.ingredients = ingredientsArrayFormat
              values.subrecipes = subrecipesArrayFormat
            }
          }
        } else {
          setCurrentErrors({
            recipes: t('add_at_least_one', { ns: 'menu' }),
          })
          setSubmitting(false)
        }
      }}
    >
      {({ values, setFieldError, setFieldValue, isSubmitting }) => {

        if (menuItemName.current !== values.name) {
          menuItemName.current = values.name
          itemAlreadyExists.current = menuItemNameExists(menuItemName.current)
        }

        return (
          <ShowForm
            location={location}
            initialValues={initialValues}
            values={values}
            errors={currentErrors}
            setFieldError={setFieldError}
            setFieldValue={setFieldValue}
            isSubmitting={isSubmitting}
            batchItemValidation={batchItemValidation}
            setIsCombo={setIsCombo}
            baseUrl={baseUrl}
            itemNameExists={(current_step === 'first-step' || current_step === 'third-step') && itemAlreadyExists.current}
            locationName={selectedLocation.name}
            menuItems={menuItems}
            selectedLocation={selectedLocation}
            setKind={setKind}
            setCurrentValues={setCurrentValues}
          />
        )
      }}
    </Formik>
  )
}

export default AddMenuItem
